import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { configColor } from "configs/constants/configColor";
import styled from "styled-components";

interface IWI {
  className?: string;
  err?: boolean;
  children: any;
}

const WrapInput = ({
  children,
  className,
  err = false,
  ...props
}: IWI & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <Wrap className={`${className} ${err && "wi-err"} `} {...props}>
      {children}
    </Wrap>
  );
};

export default WrapInput;

const Wrap = styled.div`
  border-radius: 4px;
  border: 1px solid #808080;
  background: #ffffff0f;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  height: fit-content;
  &.wi-err {
    border: 1px solid ${configColor.colorRed};
  }
  ${breakpointsMedias.max1199} {
    padding: 0 11px;
    height: 36px;
  }
`;
