import styled from "styled-components";
import { useLockBodyScroll } from "helpers/hooks/useLockBodyScroll";
import { useTranslation } from "react-i18next";
import ModalWrap from "components/core/ModalWrap";
import numeral from "numeral";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { subStringAddress } from "helpers/subStringAddress";
import { configColor } from "configs/constants/configColor";
import iconTrx from "assets/images/trx.png";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useContext, useEffect, useRef, useState } from "react";
import { TronContexts } from "contexts/TronContexts";
import { useApolloClient } from "@apollo/client";
import { GET_ACCOUNT, GET_API_KEY, REVOKE_KEY } from "configs/apolloSchema";
import { formatToken } from "helpers/formatToken";
import iconEnergy from "assets/images/icon-energy.png";
import iconBandwith from "assets/images/icon-bandwith.png";
import { MdAutorenew, MdDone } from "react-icons/md";
import { useWidthScreen } from "helpers/hooks/useWidthScreen";
import useSystemSettings from "helpers/energy/useSystemSettings";
import { useResourceContext } from "contexts/ResourceContext";
import { getBalanceTrx } from "helpers/energy/getBalanceTrx";
import Button from "components/core/Button";
import ModalQr from "./ModalQr";
import CopyToClipboard from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import useSignToken from "helpers/energy/useSignToken";
import { notifyToastify } from "helpers/ultilities/notifyToastify";
import { useGetBalanceTrx } from "helpers/energy/getBalanceTrxAvaiable";
import ModalRevoke from "./ModalRevoke";
import { errorCode } from "helpers/errorCode";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";

dayjs.extend(relativeTime);

interface IModalSuccess {
  onClose: () => void;
}

type DataAccount = {
  min_delegate_amount: number;
  min_energy_price: number;
  payment_address: string;
  payment_frequency: number;
  maintain_undelegate_energy: number;
  permit_operations: string[];
  profit_share: number;
};

const ModalUser = ({ onClose }: IModalSuccess) => {
  useLockBodyScroll();
  const { t } = useTranslation();
  const client = useApolloClient();
  const { address, connected, wallet, signMessage } = useWallet();
  const { tronWeb } = useContext(TronContexts);
  const { onLogin, isSigned, error: signError } = useSignToken();
  const { energy, totalEnergy, bandwidth, totalBandwidth } = useResourceContext();
  const copyRef = useRef<HTMLSpanElement>(null);
  const { balanceTrx } = useGetBalanceTrx(address || "");
  const { buyEnergyPrice } = useSystemSettings();
  // 
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingRevoke, setIsLoadingRevoke] = useState(false)
  const [internalAccount, setInternalAccount] = useState({
    address: "",
    balance: 0,
    apiKey: ""
  })
  const [showQr, setShowQr] = useState(false);
  const [showRevoke, setShowRevoke] = useState(false);

  // 
  const [dataAcc, setDataAcc] = useState<DataAccount | null>(null);
  const getAccount = () => {
    try {
      const query = client.watchQuery({
        query: GET_ACCOUNT,
        variables: {
          address: address,
        },
        fetchPolicy: "no-cache",
      });
      const querySubscribe = query.subscribe(
        ({ data: { rls_permit_user } }) => {
          if (rls_permit_user) {
            setDataAcc({
              min_delegate_amount: formatToken({
                type: "devided",
                value: rls_permit_user.min_delegate_amount || 0,
                length: 6,
              }),
              min_energy_price: Number(rls_permit_user.min_energy_price),
              payment_address: rls_permit_user.payment_address,
              payment_frequency: Number(rls_permit_user.payment_frequency),
              maintain_undelegate_energy: Number(
                rls_permit_user.maintain_undelegate_energy
              ),
              permit_operations: rls_permit_user.permit_operations || [],
              profit_share: Number(rls_permit_user.profit_share),
            });
          }
        },
        (error) => {
          console.log("Get account infor error", error);
        }
      );
      return querySubscribe;
    } catch (error) {
      console.log("Get account err", error);
    }
  };

  const calFrequency = (fq: number) => {
    return fq === 0 ? "IMMEDIATELY" : fq === 3600 ? "HOURLY" : "DAILY";
  };

  useEffect(() => {
    address && getAccount();
  }, [address]);
  //self link when mobile
  const { isMobile } = useWidthScreen();

  const getApiKey = () => {
    if (address && connected) {
      setIsLoading(true)
      try {
        const query = client.watchQuery({
          query: GET_API_KEY,
          fetchPolicy: "no-cache"
        })
        const querySubscribe = query.subscribe(({ data: { rls_user_internal_account } }) => {
          if (rls_user_internal_account) {
            setInternalAccount({
              address: rls_user_internal_account.internal_address,
              balance: rls_user_internal_account.internal_balance,
              apiKey: rls_user_internal_account.api_key
            });
          }
          setIsLoading(false)
        }, (error) => {
          setIsLoading(false);
          notifyToastify({ type: "error", text: t(errorCode[error.message || ""] || error.message) })
          console.log("Get ref infor error", error);
        })
        return querySubscribe
      } catch (error: any) {
        setIsLoading(false)
        notifyToastify({ type: "error", text: t(error?.message || "somethingsError") })
        console.log("Get ref infor err", error);
      }
    } else {
      setIsLoading(false)
    }
  }

  const revokeKey = () => {
    if (address && connected) {
      setIsLoadingRevoke(true);
      try {
        const query = client.mutate({
          mutation: REVOKE_KEY,
          fetchPolicy: "no-cache"
        }).then(({ data: { rls_user_revoke_api_key } }) => {
          if (rls_user_revoke_api_key) {
            setInternalAccount({
              ...internalAccount,
              apiKey: rls_user_revoke_api_key
            });
            setShowRevoke(false)
          }
          setIsLoadingRevoke(false)
        }, (error) => {
          setIsLoadingRevoke(false)
          notifyToastify({ type: "error", text: t(errorCode[error.message || ""] || error.message) })
          console.log("Revoke api_key error", error);
        })
      } catch (error: any) {
        setIsLoadingRevoke(false)
        notifyToastify({ type: "error", text: t(error?.message || "somethingsError") })
        console.log("Revoke api_key err", error);
      }
    } else {
      setIsLoadingRevoke(false)
    }

  }

  const onSignMessage = async () => {
    try {
      const TIME_EXPIRE = 24 * 60 * 60;
      if (connected && wallet?.adapter) {
        setIsLoading(true)
        const time = new Date().getTime()
        let signature = await signMessage(`rls_tronsave_${time}`);
        if (signature) {
          await onLogin({
            address: address || "",
            timestamp: time,
            signature,
            duration_sec: TIME_EXPIRE
          })
        } else {
          setIsLoading(false)
        }
      }
    } catch (error) {
      console.log("ERROR SIGN MESSAGE", error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (signError) {
      setIsLoading(false)
    }
  }, [signError])

  useEffect(() => {
    if (isSigned) {
      getApiKey()
    }
  }, [isSigned])

  return (
    showRevoke ? <ModalRevoke onClose={() => { setShowRevoke(false) }} onRevoke={revokeKey} isLoading={isLoadingRevoke} />
      : showQr ? <ModalQr address={internalAccount.address} onClose={() => { setShowQr(false) }} />
        : <ModalWrap onClose={onClose} showCloseBt={true} clickOutsideToClose={true}>
          <Wrap>
            <div className="modal-title">
              <span className="size-6 color-white">{t("accountInfor")}</span>
            </div>
            <div className="mb-wrap">
              <div className={`modal-body mb-main text-center`}>
                <div className="dpi">
                  <span className="size-21 color-white">{t("address")}</span>
                  <a
                    target={isMobile ? "_self" : "_blank"}
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_SCAN_NETWORK}#/address/${address}`}
                    className="size-22 color-white"
                  >
                    {subStringAddress({ text: address || "--", length: 5 })}
                  </a>
                </div>
                <div className="dpi">
                  <span className="size-21 color-white">{t("balance")}</span>
                  <span className="size-22 color-white dpi-amount">
                    {numeral(balanceTrx).format("0,0.[000]")}
                    <img src={iconTrx} alt="" className="img-trx" />
                  </span>
                </div>
                <div className="dpi">
                  <span className="size-21 color-white">{t("energy")}</span>
                  <span className="size-22 color-white dpi-amount">
                    {numeral(energy).format("0,0.[000]")}&nbsp;/&nbsp;
                    <span className="size-22 color-white">
                      {numeral(totalEnergy).format("0,0.[000]")}
                    </span>
                    <img src={iconEnergy} alt="" className="img-energy" />
                  </span>
                </div>
                <div className="dpi">
                  <span className="size-21 color-white">{t("bandwidth")}</span>
                  <span className="size-22 color-white dpi-amount">
                    {numeral(bandwidth).format("0,0.[000]")}&nbsp;/&nbsp;
                    <span className="size-22 color-white">
                      {numeral(totalBandwidth).format("0,0.[000]")}
                    </span>
                    <img src={iconBandwith} alt="" className="img-bandwith" />
                  </span>
                </div>
              </div>
              {/* apiKey */}
              <div className={`mb-main mb-key ${internalAccount.address && "mb-key-active"}`}>
                <div className={`modal-body`}>
                  <div className="dpi">
                    <span className="size-21 color-white">{t("apiKey")}</span>
                    {(!isSigned || !internalAccount.address) ? <Button text="viewDetail" onClick={() => { onSignMessage() }} isLoading={isLoading} />
                      : <span className="dpi-key size-22 color-white">
                        {subStringAddress({ text: internalAccount.apiKey || "--", length: 7 })}
                        <CopyToClipboard text={internalAccount.apiKey} onCopy={() => {
                          if (copyRef.current) {
                            copyRef.current.style.display = "flex";
                            setTimeout(() => {
                              if (copyRef.current) { copyRef.current.style.display = "none"; }
                            }, 2000)
                          }
                        }}>
                          <div className="ri-ref-bt">
                            <span className='size-1 color-black-2'>
                              <BiCopy size={24} color="#FFF" />
                            </span>
                            <span ref={copyRef} className="size-0 color-white copied" > Copied!</span >
                          </div>
                        </CopyToClipboard>
                        <Button text="revoke" className="bt-revoke" isLoading={isLoadingRevoke} onClick={() => { setShowRevoke(true) }} />
                      </span>}
                  </div>
                  <div className="dpi">
                    <span className="size-21 color-white">{t("balanceInternal")}</span>
                    {(internalAccount.address && isSigned) ? <span className="size-22 color-white dpi-amount">
                      {numeral(formatToken({
                        type: "devided",
                        value: internalAccount.balance ?? 0,
                        length: 6,
                      })).format("0,0.[000]")}
                      <img src={iconTrx} alt="" className="img-trx" />
                      <div className={`bt-reload ${isLoading && "bt-reload-loading"}`} onClick={() => { if (!isLoading) getApiKey() }}>
                        <MdAutorenew size={24} color="#FFF" />
                      </div>
                      <Button text="topUp" className="bt-qr" onClick={() => { setShowQr(true) }} />
                    </span> : <span className="size-21 color-white">--</span>}
                  </div>
                </div>
              </div>
              <span className="size-31 color-white">{t("pool")}</span>
              {dataAcc && dataAcc.payment_address ? (
                <div className={`modal-body mb-pool text-center`}>
                  <div className="dpi">
                    <span className="size-21 color-white">
                      {t("minDelegateAmount")}
                    </span>
                    <span className="size-22 color-white dpi-amount">
                      {numeral(dataAcc.min_delegate_amount).format("0,0.[000]")}
                      <img src={iconTrx} alt="img-trx" className="img-trx" />
                    </span>
                  </div>
                  <div className="dpi">
                    <span className="size-21 color-white">
                      {t("minEnergyPrice")}
                    </span>
                    <span className="size-22 color-white dpi-amount">
                      {dataAcc.min_energy_price >= 420 ? t("off")
                        : numeral(
                          dataAcc.min_energy_price === -1
                            ? buyEnergyPrice
                            : dataAcc.min_energy_price
                        ).format("0,0.[000]")}
                    </span>
                  </div>
                  <div className="dpi">
                    <span className="size-21 color-white">
                      {t("paymentAddress")}
                    </span>
                    <a
                      target={isMobile ? "_self" : "_blank"}
                      rel="noreferrer"
                      href={`${process.env.REACT_APP_SCAN_NETWORK}#/address/${dataAcc.payment_address}`}
                      className="size-22 color-white"
                    >
                      {subStringAddress({
                        text: dataAcc.payment_address || "--",
                        length: 5,
                      })}
                    </a>
                  </div>
                  <div className="dpi">
                    <span className="size-21 color-white">
                      {t("paymentFrequency")}
                    </span>
                    <span className="size-22 color-white dpi-amount">
                      {calFrequency(dataAcc.payment_frequency)}
                    </span>
                  </div>
                  <div className="dpi">
                    <span className="size-21 color-white">
                      {t("maintainUndelegateEnergy")}
                    </span>
                    <span className="size-22 color-white dpi-amount">
                      {numeral(dataAcc.maintain_undelegate_energy).format(
                        "0,0.[000]"
                      )}
                      <img src={iconEnergy} alt="" className="img-energy" />
                    </span>
                  </div>
                  <div className="dpi">
                    <span className="size-21 color-white">{t("profitShare")}</span>
                    <span className="size-22 color-white dpi-amount">
                      {numeral(dataAcc.profit_share).format("0,0.[00]")}%
                    </span>
                  </div>
                </div>
              ) : (
                <div className="modal-body mb-pool">
                  <div className="dpi">
                    <span className="size-22 color-white">{t("notPool")}</span>
                  </div>
                </div>
              )}
              <div className="bar"></div>
              <span className="size-31 color-white">{t("permitOperations")}</span>
              {dataAcc && dataAcc.permit_operations.length > 0 ? (
                <div className={`mb-opr`}>
                  <ul className="dpi-list">
                    {dataAcc.permit_operations.map((item, index) => (
                      <li key={index} className="size-21 color-white">
                        <MdDone size={24} color="#FFFF" />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div className={`mb-opr`}>
                  <div className="dpi">
                    <span className="size-22 color-white">{t("notPermit")}</span>
                  </div>
                </div>
              )}
            </div>
          </Wrap>
        </ModalWrap >
  );
};

export default ModalUser;

const Wrap = styled.div`
  padding: 31px 71px;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 32px);
  max-width: 768px;
  cursor: default;
  .modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    > span {
      text-align: center;
    }
  }
  .mb-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* max-height: calc(100vh - 240px);
        overflow: auto;
        padding-right: 10px; */
    > span {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      gap: 10px;
      > img {
        transition: 0.3s;
      }
      .img-active {
        transform: rotateX(180deg);
      }
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
    .dpi {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > a {
        text-decoration: underline;
        &:hover {
          color: ${configColor.colorBlue};
        }
      }
      .bt-key {
        width: 120px;
        height: 40px;
        margin-left: auto;
      }
      .dpi-net {
        color: ${configColor.colorPrimary};
      }
      .dpi-amount {
        display: flex;
        align-items: center;
        .img-trx {
          width: 20px;
          height: 20px;
          margin-left: 6px;
        }
        .img-energy,
        .img-bandwith {
          width: 24px;
          height: 24px;
          margin-left: 6px;
        }
        .bt-qr {
          margin-left: 16px;
          width: 80px;
          height: 36px;
        }
      }
      > button {
        width: 120px;
        height: 40px;
      }
      .dpi-key {
        display: flex;
        align-items: center;
        gap: 12px;
        .ri-ref-bt {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;
          &:hover {
            svg {
              fill: ${configColor.green};
            }
          }
          > span {
              display: flex;
              align-items: center;
          }
          > .copied {
              position: absolute;
              width: fit-content;
              padding: 5px 10px;
              border-radius: 5px;
              background-color: ${configColor.colorGray};
              right: 0;
              top: 100%;
              display: none;
              z-index: 1;
          }
        }
        .bt-revoke {
          width: 80px;
          height: 36px;
        }
      }
    }
  }
  .bar {
    background: rgba(252, 252, 252, 0.2);
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
  }
  .mb-opr {
    border-radius: 8px;
    background: #303531;
    padding: 16px 24px 4px 24px;
    .dpi-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      list-style-type: none;
      align-items: center;
      > li {
        width: calc(50% - 10px);
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        > svg {
          margin-right: 12px;
        }
      }
    }
    .dpi {
      margin-bottom: 12px;
    }
  }
  .mb-main {
    border-bottom: 1px solid rgba(252, 252, 252, 0.2);
    margin-bottom: 20px;
    .dpi {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 19px;
      }
    }
  }
  .mb-pool {
    border-radius: 8px;
    background: #303531;
    margin-bottom: 19px;
    padding: 16px 24px;
    .dpi {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      > span:first-child {
        text-align: left;
      }
    }
  }
  .mb-key {
    .dpi {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 8px;
      }
      .dpi-amount {
        > img {
          margin-right: 10px;
        }
      }
      .bt-reload {
        border-radius: 4px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #434944;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          background-color: ${configColor.green2};
        }
      }
      .bt-reload-loading {
        > svg {
          animation: roll 2s infinite linear;
        }
          
            @keyframes roll {
              from {
                  -webkit-transform: rotate(0deg);
                  -o-transform: rotate(0deg);
                  transform: rotate(0deg);
              }
              to {
                  -webkit-transform: rotate(360deg);
                  -o-transform: rotate(360deg);
                  transform: rotate(360deg);
              }
            }
        }
    }
  }
  .modal-bt {
    width: 100%;
  }
  ${breakpointsMedias.max1199} {
    padding: 31px 15px;
    display: flex;
    flex-direction: column;
    width: calc(100vw - 32px);
    max-width: 343px;
    .modal-title {
      margin-bottom: 36px;
    }
    .modal-body {
      margin-bottom: 16px;
      .dpi {
        margin-bottom: 16px;
        > button {
          width: 90px;
          height: 32px;
        }
      }
    }
    .bar {
      margin-bottom: 16px;
    }
    .mb-opr {
      padding: 12px 12px 0px 12px;
      .dpi-list {
        > li {
          width: 100%;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 12px;
          }
        }
      }
    }
    .mb-main {
      margin-bottom: 16px;
      .dpi {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 15px;
        }
      }
    }
    .mb-pool {
      margin-bottom: 15px;
      padding: 12px;
      .dpi {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .mb-key {
      .dpi {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 4px;
        }
        .bt-reload {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
`;
