import styled from "styled-components";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import WrapInput from "components/core/WrapInput";
import { NumericFormat } from "react-number-format";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import { useDropzone } from "react-dropzone";
import numeral from "numeral";
import WrapSection from "components/core/WrapSection";
import { configColor } from "configs/constants/configColor";
import { notifyToastify } from "helpers/ultilities/notifyToastify";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { GET_API_KEY } from "configs/apolloSchema";
import useSignToken from "helpers/energy/useSignToken";
import { useApolloClient } from "@apollo/client";
import { errorCode } from "helpers/errorCode";
import TextTooltipRename from "components/core/TextTooltipRename";
import LoadingSpinner from "components/core/LoadingSpinner";
import { MdDeleteForever } from "react-icons/md";
import { CgArrowsExchangeAltV } from "react-icons/cg";
import { IoMdDownload } from "react-icons/io";

const SECOND_OF_ONE_HOUR = 60 * 60;

type DataMultiBuy = {
    isPartFill: boolean;
    duration: number;
    amount: string;
    apiKey: string
};

type DataItem = {
    address: string,
    status: 0 | 1 | 2 | 3 | 4 | 5 | 6,
    message: string
}

const fastDuration = [{
    value: SECOND_OF_ONE_HOUR,
    text: "1h",
}, {
    value: SECOND_OF_ONE_HOUR * 3,
    text: "3h",
}, {
    value: SECOND_OF_ONE_HOUR * 6,
    text: "6h",
}, {
    value: SECOND_OF_ONE_HOUR * 24 * 3,
    text: "3d",
}]
const CHUNK_SIZE_BUY = 6;

const Multibuy = () => {
    const { t } = useTranslation();
    const client = useApolloClient();
    const { address, connected, wallet, signMessage } = useWallet();
    const { onLogin, isSigned, error: signError } = useSignToken();
    const [dataFile, setDataFile] = useState<DataItem[]>([]);
    const [isLoading, setIsLoading] = useState<number | null>(null);

    const [dataBuy, setDataBuy] = useState<DataMultiBuy>({
        isPartFill: true,
        duration: SECOND_OF_ONE_HOUR,
        amount: "32000",
        apiKey: ""
    });

    const uploadCSV = (files: any) => {
        let file = files.length ? files[0] : "";
        if (file) {
            let reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (evt: any) => {
                let data = evt.target.result;
                onHandleFile(data);
            };
        }
    };
    const onDrop = (acceptedFiles: any) => {
        uploadCSV(acceptedFiles);
    };
    const { getRootProps } = useDropzone({
        onDrop,
        accept: {
            "text/csv": [".csv", ".txt"],
        },
    });
    const onHandleFile = (e: any) => {
        // console.log({ e });
        const newConvertData = e.split("\n").map((item: any) => {
            return {
                address: item.toString()
                    .replace(/\r/g, "")
                    .replace(/ /g, "")
                    .replace(/"/g, "")
                    .replace(/'/g, "")
                    .replace(/\t/g, ""),
                status: 3,
                message: ""
            }
        }).filter((item: any) => item.address)
        setDataFile(newConvertData);
    };

    const onEditItem = (value: string, idx: number) => {
        setDataFile((prev) => {
            let newData = [...prev];
            newData[idx].address = value;
            return newData
        })
    }

    const onUpdateItem = (value: 0 | 1 | 2 | 3 | 4 | 5 | 6, idx: number, message?: string) => {
        setDataFile((prev) => {
            let newData = [...prev];
            newData[idx].status = value;
            if (message !== undefined) {
                newData[idx].message = message;
            }
            return newData
        })
    }

    const onDeleteItem = (idx: number) => {
        let newData = [...dataFile];
        newData.splice(idx, 1)
        setDataFile(newData)
    }

    useEffect(() => {
        if (dataFile.length) {
            localStorage.setItem("multibuy", JSON.stringify(dataFile))
        }
    }, [dataFile])

    useEffect(() => {
        let getSave = localStorage.getItem("multibuy");
        if (getSave) {
            setDataFile(JSON.parse(getSave))
        }
    }, [])

    const handleChange = (val: any, key: string) => {
        setDataBuy((prevState) => {
            return {
                ...prevState,
                [key]: val,
            };
        });
    };

    const onReset = () => {
        updateData([])
        localStorage.removeItem("multibuy")
    }

    const onClear = () => {
        let newDataClear = dataFile.map((item) => {
            return {
                ...item,
                status: 3
            }
        }) as DataItem[]
        setDataFile(newDataClear)
    }

    const onExtra = () => {
        if (isLoading === null) {
            updateData([...dataFile, {
                address: "",
                status: 3,
                message: ""
            }])
        }
    }

    const getApiKey = () => {
        if (address && connected) {
            setIsLoading(-1)
            try {
                const query = client.watchQuery({
                    query: GET_API_KEY,
                    fetchPolicy: "no-cache"
                })
                const querySubscribe = query.subscribe(({ data: { rls_user_internal_account } }) => {
                    if (rls_user_internal_account) {
                        handleChange(rls_user_internal_account.api_key, "apiKey");
                        // console.log("key", rls_user_internal_account.api_key)
                    }
                    setIsLoading(null)
                }, (error) => {
                    setIsLoading(null);
                    notifyToastify({ type: "error", text: t(errorCode[error.message || ""] || error.message) })
                    console.log("Get apiKey error", error);
                })
                return querySubscribe
            } catch (error: any) {
                setIsLoading(null)
                notifyToastify({ type: "error", text: t(error?.message || "somethingsError") })
                console.log("Get ref infor err", error);
            }
        } else {
            setIsLoading(null)
        }
    }

    const onSignMessage = async () => {
        try {
            const TIME_EXPIRE = 24 * 60 * 60;
            if (connected && wallet?.adapter) {
                setIsLoading(-1)
                const time = new Date().getTime()
                let signature = await signMessage(`rls_tronsave_${time}`);
                if (signature) {
                    await onLogin({
                        address: address || "",
                        timestamp: time,
                        signature,
                        duration_sec: TIME_EXPIRE
                    })
                } else {
                    setIsLoading(null)
                }
            }
        } catch (error) {
            console.log("ERROR SIGN MESSAGE", error)
            setIsLoading(null)
        }
    }

    useEffect(() => {
        if (signError) {
            setIsLoading(null)
        }
    }, [signError])

    useEffect(() => {
        if (isSigned) {
            // console.log("get key")
            getApiKey()
        }
    }, [isSigned])

    // console.log({ apiKey: dataBuy.apiKey, isSigned })
    // const activeTarget = async (target: string) => {
    //     try {
    //         const txid = await tronWeb.trx.sendTransaction(target, 1, privateKey);
    //         // console.log(txid)
    //         return txid
    //     } catch (error: any) {
    //         return { result: false, message: error?.message || "Active error" }
    //     }
    // }

    const updateData = (newData: DataItem[]) => {
        setDataFile((prev) => newData)
    }

    type ParamCheck = {
        loop?: boolean,
        data: DataItem[]
    }

    const onCheckAll = ({ loop, data }: ParamCheck) => {
        setIsLoading(-2)
        const vars = data.map((item) => item.address)
        fetch(`${process.env.REACT_APP_RESTFUL_API || ""}v0/helper/is-active-address-check`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ addresses: vars }),
        })
            .then(async (res: any) => {
                const result = await res.json();
                // console.log({ result });
                const newDataCheck = result.map((item: number, index: number) => {
                    return {
                        address: data[index].address,
                        status: (
                            data[index].status === 3
                            || data[index].status === 0
                            || (data[index].status === 6 && item === 2)
                        ) ? item : data[index].status,
                        message: item === 4 ? "Address not valid" : data[index].message
                    }
                })
                updateData(newDataCheck)
                const countNeedActive = newDataCheck.filter((item: any) => item.status === 0).length
                if (countNeedActive) {
                    const sleep = (ms: number) => {
                        return new Promise((resolve) => {
                            setTimeout(resolve, ms);
                        });
                    }
                    await sleep(1000);
                    activeAll3(newDataCheck)
                    // activeAll2(newDataCheck)
                } else {
                    if (loop && newDataCheck.filter((item: any) => item.status === 6).length > 0) {
                        const sleep = (ms: number) => {
                            return new Promise((resolve) => {
                                setTimeout(resolve, ms);
                            });
                        }
                        await sleep(10000);
                        onCheckAll({ loop: true, data: newDataCheck })
                    } else {
                        setIsLoading(null)
                    }
                }
            })
            .catch((err) => {
                notifyToastify({ type: "error", text: err?.message || "Check error" })
                setIsLoading(null)
            });
    }

    const activeAll3 = async (data: DataItem[]) => {
        setIsLoading(-2);
        const vars = data.map((item) => item.address)
        fetch(`${process.env.REACT_APP_RESTFUL_API || ""}v0/helper/multi-active-address`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "apikey": dataBuy.apiKey
            },
            body: JSON.stringify({ addresses: vars }),
        })
            .then(async (res: any) => {
                const result = await res.json();
                // console.log({ result });
                if (result.error) {
                    notifyToastify({ type: "error", text: result.message || "Active error" })
                    setIsLoading(null)
                } else {
                    let newDataActived = data.map((item) => {
                        return {
                            ...item,
                            status: item.status === 0 ? 6 : item.status
                        }
                    })
                    onCheckAll({ loop: true, data: newDataActived });
                }
            })
            .catch((err) => {
                notifyToastify({ type: "error", text: err?.message || "Check error" })
                setIsLoading(null)
            });
    }

    const onRemoveFail = () => {
        const newDataNoFail = dataFile.filter((item) => item.status !== 4)
        updateData(newDataNoFail)
    }

    const onConfirmAll = () => {
        sendBuyItem(0)
    }

    const onConfirmPartital = (data: DataItem[]) => {
        let newArrs = [];
        let cloneData = [...data]
        while (cloneData.length > 0) {
            let chunk = cloneData.splice(0, CHUNK_SIZE_BUY)
            newArrs.push(chunk)
        }
        sendBuyChunk(0, newArrs, data)
        // console.log({ newArrs });
    }

    const sendBuyChunk = async (idChunk: number, arrs: any, originData: DataItem[]) => {
        // 
        const createOrder = async (order: DataItem) => {
            // console.log(order);
            // return order
            if (order.status === 2) {
                const res = await fetch(`${process.env.REACT_APP_RESTFUL_API || ""}v0/internal-buy-energy`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "apikey": dataBuy.apiKey
                    },
                    body: JSON.stringify({
                        "resource_type": "ENERGY",
                        "buy_energy_type": "MEDIUM",
                        "amount": dataBuy.amount,
                        "allow_partial_fill": dataBuy.isPartFill,
                        "target_address": order.address,
                        "duration_millisec": dataBuy.duration * 1000, //Optional
                    }),
                })
                    .then(async (res: any) => {
                        const result = await res.json();
                        if (!result.error) {
                            return {
                                ...order,
                                status: 5
                            }
                        } else {
                            // null to stop
                            setIsLoading(null)
                            return {
                                ...order,
                                status: 4,
                                message: result.message || "Buy error"
                            }
                        }
                    }).catch((err: any) => {
                        // null to stop
                        setIsLoading(null)
                        return {
                            ...order,
                            status: 4,
                            message: err.message || "Buy error"
                        }
                    });
                return res
            } else {
                return order
            }
        }
        setIsLoading(idChunk * CHUNK_SIZE_BUY);
        let chunk = arrs[idChunk];
        // console.log(arrs);
        // return
        Promise.all(chunk.map((item: DataItem) => {
            return createOrder(item)
        })).then(async (values: DataItem[]) => {
            // console.log(values)
            const newDataBuyed = [...originData];
            for (let ii = 0; ii < chunk.length; ii++) {
                newDataBuyed[idChunk * CHUNK_SIZE_BUY + ii] = values[ii]
            }
            updateData(newDataBuyed);
            if (idChunk < arrs.length - 1) {
                // check fail stop
                // if (!values.find((item) => item.status === 4)) {
                // 5buy/s
                // const sleep = (ms: number) => {
                //     return new Promise((resolve) => {
                //         setTimeout(resolve, ms);
                //     });
                // }
                // await sleep(1000);
                sendBuyChunk(idChunk + 1, arrs, newDataBuyed)
                // } else {
                //     setIsLoading(null)
                // }
            } else {
                setIsLoading(null)
            }
        })
    };

    const sendBuyItem = async (idTarget: number) => {
        setIsLoading(idTarget)
        if (dataFile[idTarget].status === 2) {
            fetch(`${process.env.REACT_APP_RESTFUL_API || ""}v0/internal-buy-energy`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "apikey": dataBuy.apiKey
                },
                body: JSON.stringify({
                    "resource_type": "ENERGY",
                    "buy_energy_type": "MEDIUM",
                    "amount": dataBuy.amount,
                    "allow_partial_fill": dataBuy.isPartFill,
                    "target_address": dataFile[idTarget].address,
                    "duration_millisec": dataBuy.duration * 1000, //Optional
                }),
            })
                .then(async (res: any) => {
                    const result = await res.json();
                    if (!result.error) {
                        // console.log({ result })
                        onUpdateItem(5, idTarget);
                        if (idTarget < dataFile.length - 1) {
                            // console.log("next");
                            // const sleep = (ms: number) => {
                            //     return new Promise((resolve) => {
                            //         setTimeout(resolve, ms);
                            //     });
                            // }
                            // await sleep(100);
                            sendBuyItem(idTarget + 1);
                        } else {
                            console.log("end")
                            setIsLoading(null);
                        }
                    } else {
                        setIsLoading(null);
                        onUpdateItem(4, idTarget, result.message || "Buy error");
                    }
                })
                .catch((err: any) => {
                    // set err idTarget
                    onUpdateItem(4, idTarget, err.message || "Buy error")
                    setIsLoading(null);
                });
        } else {
            if (idTarget < dataFile.length - 1) {
                // console.log("next");
                sendBuyItem(idTarget + 1);
            } else {
                console.log("end")
                setIsLoading(null);
            }
        }
    };

    const totalCount = useMemo(() => {
        return {
            default: dataFile.filter((item: any) => item.status === 3).length,
            notActive: dataFile.filter((item: any) => item.status === 0).length,
            contract: dataFile.filter((item: any) => item.status === 1).length,
            ready: dataFile.filter((item: any) => item.status === 2).length,
            fail: dataFile.filter((item: any) => item.status === 4).length,
            success: dataFile.filter((item: any) => item.status === 5).length,
            retry: dataFile.filter((item: any) => item.status === 6).length,
        }
    }, [dataFile]);

    const arrangeFail = () => {
        let newDataArrange = [...dataFile];
        const arrSort = newDataArrange.sort((a: DataItem, b: DataItem) => {
            return (b.status === 4 ? -1 : b.status) - (a.status === 4 ? -1 : a.status)
        })
        setDataFile(arrSort)
    }

    const downloadFail = () => {
        let dataDownload = [...dataFile].filter((item) => item.status === 4).map(item => { return [item.address] });
        if (dataDownload.length > 0) {
            let csvContent = "data:text/csv;charset=utf-8,"
                + dataDownload.map(e => e.join(",")).join("\n");
            let encodedUri = encodeURI(csvContent);
            let link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "my_data.csv");
            document.body.appendChild(link); // Required for FF
            link.click();
        } else {
            notifyToastify({ type: "error", text: "There are no failed target" })
        }
    }

    return (
        <Wrap>
            <div className="container">
                <span className="size-6 color-white">Bulk buy energy</span>
                <div className="bb-prepare">
                    <div className="bbp-amount">
                        <span className="size-2 color-white">Buy amount</span>
                        <WrapInput >
                            <NumericFormat
                                inputMode="decimal"
                                value={dataBuy.amount}
                                onValueChange={(values: any) => {
                                    handleChange(values.value, "amount");
                                }}
                                thousandSeparator={true}
                                placeholder={t("amount")}
                                className="size-21 color-white"
                                allowNegative={false}
                                decimalScale={0}
                                disabled={isLoading !== null}
                            />
                        </WrapInput>
                    </div>
                    <div className="bbp-time">
                        <span className="size-2 color-white">Duration</span>
                        <div className="bbpt-list">
                            {fastDuration.map((item, index) => <div
                                key={index}
                                className={`bbptl-item ${item.value === dataBuy.duration ? "bbptl-item-active" : ""} ${isLoading !== null ? "bbptl-item-disable" : ""}`}
                                onClick={() => { isLoading === null && handleChange(item.value, "duration"); }}>
                                <span className="size-1 color-green">{t(item.text)}</span>
                            </div>)}
                        </div>
                    </div>
                    <div className="bbp-reset" >
                        <Button
                            text="Clear status"
                            typeButton={2}
                            onClick={onClear}
                            disabled={isLoading !== null}
                        />
                    </div>
                    <div className="bbp-reset" >
                        <Button
                            text="Delete all"
                            typeButton={2}
                            onClick={onReset}
                            disabled={isLoading !== null}
                        />
                    </div>
                    <div className="bbp-import" {...getRootProps()} >
                        <Button
                            text="Import CSV"
                            typeButton={1}
                            disabled={isLoading !== null} />
                    </div>
                </div>
                <div className="bb-confirm" >
                    {/* <WrapInput className="winput">
                        <input
                            className="size-2 color-white"
                            type="text"
                            value={privateKey} placeholder="Private Key for active target" onChange={(e) => {
                                setPrivateKey(e.target.value)
                            }}
                            disabled={isLoading !== null}
                        />
                    </WrapInput> */}
                    <div className="bb-bts">
                        <Button
                            text={isSigned ? "Check and Active" : "Get API Key"}
                            typeButton={2}
                            onClick={() => {
                                if (isSigned) {
                                    onCheckAll({ loop: true, data: dataFile });
                                } else {
                                    onSignMessage()
                                }
                            }}
                            isLoading={isLoading === -2}
                            disabled={isLoading !== null}
                        />
                        {/* <Button
                            text={"Check all"}
                            typeButton={2}
                            onClick={() => {
                                onCheckAll({ data: dataFile });
                            }}
                            isLoading={isLoading === -1 || (isSigned && !dataBuy.apiKey)}
                        />
                        <Button
                            text={"Active all"}
                            typeButton={2}
                            onClick={() => {
                                activeAll2(dataFile);
                            }}
                            isLoading={isLoading === -1 || (isSigned && !dataBuy.apiKey)}
                        /> */}
                        <Button
                            text={"Remove all fail"}
                            typeButton={2}
                            onClick={() => {
                                onRemoveFail();
                            }}
                            // isLoading={isLoading === -1 || (isSigned && !dataBuy.apiKey)}
                            disabled={isLoading !== null}
                        />
                        <Button
                            text={isSigned ? "Confirm" : "Get API Key"}
                            typeButton={1}
                            onClick={() => {
                                if (isSigned) {
                                    onConfirmPartital(dataFile);
                                } else {
                                    onSignMessage()
                                }
                            }}
                            isLoading={isLoading === -1 || (isSigned && !dataBuy.apiKey)}
                            disabled={isLoading !== null || totalCount.default > 0 || totalCount.retry > 0 || totalCount.notActive > 0}
                        />
                    </div>
                </div>
                <div className="bb-total size-4 color-white">Total: {dataFile.length}</div>
                <div className="bb-count">
                    <div className="bbc-item">
                        <span className="size-2 color-gray">Default:</span>
                        <span className="size-2 color-white">{totalCount.default}</span>
                    </div>
                    <div className="bbc-item">
                        <span className="size-2 color-blue">Ready:</span>
                        <span className="size-2 color-white">{totalCount.ready}</span>
                    </div>
                    <div className="bbc-item">
                        <span className="size-2 color-yellow">Not active:</span>
                        <span className="size-2 color-white">{totalCount.notActive + totalCount.retry}</span>
                    </div>
                    <div className="bbc-item">
                        <span className="size-2 color-purple">Contract:</span>
                        <span className="size-2 color-white">{totalCount.contract}</span>
                    </div>
                    <div className="bbc-item">
                        <span className="size-2 color-red">Fail:</span>
                        <span className="size-2 color-white">{totalCount.fail}<IoMdDownload onClick={downloadFail} /></span>
                    </div>
                    <div className="bbc-item">
                        <span className="size-2 color-green">Success:</span>
                        <span className="size-2 color-white">{totalCount.success}</span>
                    </div>
                </div>
                <WrapSection className="bb-table scrollbar">
                    <table >
                        <thead>
                            <tr>
                                <th className="size-3 color-white">ID</th>
                                <th className="size-3 color-white">Address</th>
                                <th className="size-3 color-white">
                                    <div className="size-3 color-white" onClick={() => {
                                        arrangeFail()
                                    }}>Status <CgArrowsExchangeAltV size={24} color={configColor.green} /></div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataFile.map((item, index) => <tr
                                key={index}
                                className="bbt-item"
                            >
                                <td className="size-2 color-white">
                                    <div>
                                        <span className="size-2 color-white">{numeral(index + 1).format("0,0")}</span>
                                        <MdDeleteForever size={24} onClick={() => { onDeleteItem(index) }} />
                                    </div>
                                </td>
                                <td>
                                    <input
                                        placeholder="..."
                                        disabled={isLoading !== null && isLoading !== -1}
                                        className="size-2 color-white"
                                        type="text"
                                        value={item.address}
                                        onChange={(e) => { onEditItem(e.target.value, index) }}
                                    />
                                </td>
                                <td className="size-2 color-white">{
                                    isLoading === index ? <LoadingSpinner />
                                        : item.status === 0 ? <span className="color-gray">Not active</span>
                                            : item.status === 1 ? <span className="color-purple">Contract</span>
                                                : item.status === 2 ? <span className="color-blue">Ready</span>
                                                    : item.status === 3 ? <span className="color-white">--</span>
                                                        : item.status === 5 ? <span className="color-green">Success</span>
                                                            : item.status === 6 ? <span className="color-yellow">Activing</span>
                                                                : <TextTooltipRename text="Fail" tooltipContent={item.message} colorType="red" />}</td>
                            </tr>)}
                        </tbody>
                        <tfoot onClick={onExtra}>
                            <tr>
                                <td className="color-green size-4">Add +</td>
                            </tr>
                        </tfoot>
                    </table>
                </WrapSection>
            </div>
        </Wrap>
    );
};

export default Multibuy;
const Wrap = styled.div`
  position: relative;
  /* padding-top: 130px; */
  padding-top: 94px;
  
  > .container {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    > span {
        margin-bottom: 50px;
        margin-top: 70px;
    }
    .bb-prepare {
        width: 100%;
        display: flex;
        align-items: flex-end;
        gap: 20px;
        flex-wrap: wrap;
        .bbp-amount {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        button {
            max-width: 160px;
        }
        .bbp-time {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-right: auto;
            .bbpt-list {
                display: flex;
                align-items: center;
                gap: 10px;
                .bbptl-item {
                    cursor: pointer;
                    border: 1px solid ${configColor.green};
                    border-radius: 4px;
                    height: 46px;
                    width: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .bbptl-item-active {
                    background-color: ${configColor.green};
                    > span {
                        color: black;
                    }
                }
                .bbptl-item-disable {
                    opacity: 0.4;
                    cursor: not-allowed;
                }
            }
        }
    }
    .bb-confirm {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        gap: 20px;
        flex-wrap: wrap;
        .winput {
            flex: 1;
            max-width: 500px;
            min-width: 300px;
            > input {
                width: 100%;
            }
        }
        .bb-bts {
            display: flex;
            align-items: center;
            gap: 20px;
            width: fit-content;
            margin-left: auto;
            > button {
                max-width: 160px;
            }
        }
    }
    .bb-total {
        margin-right: auto;
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .bb-count {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        gap: 20px;
        .bbc-item {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 10px;
            > span {
                display: flex;
                align-items: center;
                > svg {
                    padding: 8px;
                    width: 40px;
                    height: 40px;
                    background-color: rgb(68, 74, 70);
                    margin-left: 10px;
                    border-radius: 4px;
                    cursor: pointer;
                    &:hover {
                        background-color: ${configColor.green2};
                    }
                }
            }
        }
    }
    .bb-table {
        width: 100%;
        max-height: 800px;
        overflow: auto;
        position: relative;
        margin-top: 0;
        table {
            width: 100%;
            th {
                background-color: #323732;
                text-align: left;
                padding: 12px 24px;
                position: sticky;
                top: 0;
                &:last-child {
                    text-align: right;
                    > div {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        cursor: pointer;
                        width: fit-content;
                        margin-left: auto;
                        &:hover {
                            color: ${configColor.green};
                        }
                    }
                  
                }
            }
        }
        tr {
            border-bottom: 1px solid ${configColor.gray1};
            td {
                padding: 12px 24px;
                &:first-child {
                    min-width: 60px;
                    width: 10%;
                    > div {
                        display: flex;
                        align-items: center;
                        > svg {
                            display: none;
                        }
                    }
                    &:hover {
                        > div {
                            position: relative;
                            height: 10px;
                            width: 20px;
                            span {
                                display: none;
                            }
                            > svg {
                                display: flex;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                cursor: pointer;
                            }
                        }
                    }
                }
                > input {
                    width: 100%;
                }
                &:last-child {
                    text-align: right;
                    > div {
                        width: fit-content;
                        margin-left: auto;
                        text-align: right;
                    }
                }
            }
        }
        tfoot {
            cursor: pointer;
            tr {
                td {
                    padding: 12px 24px;
                    text-align: left !important;
                    > svg {
                        margin-right: auto;
                    }
                }
            }
        }
    }
  }
  ${breakpointsMedias.max1199} {
    padding-top: 80px;
    > .container {

    }
  }
`;
