import DurationSlider from "components/core/DurationSlider";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useDebounce } from "helpers/hooks/useDebounce";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSystemExtendTeleContext } from "../../../contexts/SystemExtendTeleContext";
const MAX_RANGE_DURARIONS = 30;
const arrayInput = [1, 10, 20, 30];
const ExtendDuration = () => {
  const { onAddDurations, durations } = useSystemExtendTeleContext();
  const { t } = useTranslation();
  const [dayExtend, setDayExtent] = useState<number>(durations);
  const dayExtendDeffered = useDebounce(dayExtend, 400);
  useEffect(() => {
    onAddDurations(dayExtendDeffered);
  }, [dayExtendDeffered]);
  useEffect(() => {
    setDayExtent(durations);
  }, [durations]);
  return (
    <WrapContain>
      <div className="edc-time">
        <p className="size-21 color-white">
          {t("extendDuration")}: ({numeral(dayExtend).format("0,0")}
          &nbsp;{t("days")})
        </p>
      </div>
      <div className="edc-range">
        <DurationSlider
          textSize={"tiny"}
          min={1}
          max={MAX_RANGE_DURARIONS}
          value={dayExtend}
          onChange={(e: { target: { value: string | number } }) => {
            setDayExtent(+e.target.value);
          }}
          arrList={arrayInput}
          onChooseValue={(e: number) => {
            setDayExtent(e);
          }}
        />
      </div>
    </WrapContain>
  );
};

export default ExtendDuration;
const WrapContain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  .edc-range {
    flex-basis: 60%;
  }
  ${breakpointsMedias.max767} {
    flex-flow: column;
    justify-content: start;
    gap: 15px;
    .edc-time {
      width: 100%;
    }
    .edc-range {
      width: 100%;
    }
  }
`;
