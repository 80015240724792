import { createGlobalStyle } from "styled-components";
import { breakpointsMedias } from "./breakpointMedias";
import { configColor } from "./configColor";
export const GlobalStyle = createGlobalStyle`
  .glow {
    animation: glow 2s ease-in-out infinite alternate;
  }
  @keyframes glow {
    from {
      color: ${configColor.green};
      text-shadow: 0 0 5px #00fff280, 0 0 10px #00fff280, 0 0 15px #00fff280, 0 0 20px #00fff280, 0 0 25px #00fff280, 0 0 30px #00fff280, 0 0 35px #00fff280, 0 0 45px #00fff280;
    }
    
    to {
      color: ${configColor.green2};
      text-shadow: 0 0 10px #00fff280, 0 0 15px #00fff280, 0 0 20px #00fff280, 0 0 25px #00fff280, 0 0 30px #00fff280, 0 0 35px #00fff280, 0 0 40px #00fff280, 0 1 45px #00fff280;
    }
  }
  :root{
    font-size: 14px;
  }
  .container{
    width:100%;
    padding: 0 40px;
    margin-left:auto;
    margin-right:auto;
    max-width: 1616px;
      &.full{
        max-width:100vw;
      }
  }
  .bg-animation{
    position:fixed;
  }
  .margin-section-home{
    margin-bottom:60px;
  }
  .cl-primary{
    color: #fff
  }
  .bold{
    font-weight:bold;
  }
  .center{
    text-align:center;
  }
  .page-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 32px;
        border-radius: 4px;
        /* background: linear-gradient(270deg, #FFBEFA 0%, #AF47D3 48.96%, #4E1BC1 100%); */
        .pc-item {
          margin: 0 6px;
          text-align: center;
          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 0;
            min-width: 42px;
            color: ${configColor.colorWhite};
            background-color: #FFFFFF0F;
            border-radius: 4px;
          }
          &:hover {
            > a {
              background-color: #ffffff46;
            }
          } 
        }
        .pc-item-active {
          > a {
              background-color: ${configColor.green2} !important;
          }
        }
        .pc-prev,
        .pc-next {
          color: white;
          text-align: center;
          margin: 0 18px;
          > a {
            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 8px 12px;
              min-width: 42px;
              height: 42px;
              color: ${configColor.colorWhite};
              background-color: #FFFFFF0F;
              border-radius: 4px;
            }
          }
          &.disabled {
            > a {
              > div {
                opacity: 0.5;
                cursor: not-allowed;
              }
            }
          }
        }
        .pc-prev:hover,
        .pc-next:hover {
          > a {
            > div {
              background-color: #ffffff46;
            }
          }
          &.disabled {
            > a {
              > div {
                background-color: #FFFFFF0F;
              }
            }
          }
        }
        .break {
          margin: 0 6px;
          text-align: center;
          > a {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8px 0;
            min-width: 42px;
            height: 42px;
            color: ${configColor.colorWhite};
            background-color: #FFFFFF0F;
            border-radius: 4px;
          }
          &:hover {
            > a {
              background-color: #ffffff46;
            } 
          }
        }
    }
    .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: ${configColor.green} transparent;
        &::-webkit-scrollbar {
            width: 6px !important;
            height: 6px !important;
            margin-top:10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent !important;
            border-radius: 6px !important;
        }
        &::-webkit-scrollbar-thumb {
            background: ${configColor.green} !important;
            border-radius: 6px !important;
        }
  }
  .scrollbar2 {
    scrollbar-width: thin;
    scrollbar-color: ${configColor.gray2} transparent;
    &::-webkit-scrollbar {
            width: 6px !important;
            height: 6px !important;
            margin-top:10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent !important;
            border-radius: 6px !important;
        }
        &::-webkit-scrollbar-thumb {
            background: ${configColor.gray2} !important;
            border-radius: 6px !important;
        }
  }
  .rotate-icon{
    transform: rotate(180deg);
  }
  ${breakpointsMedias.max1199}{
    .container{
      padding: 0 24px;
    }
    .page-container {
        .pc-item {
          > a {
            min-width: 36px;
            height: 36px;
          }
        }
        .pc-prev,
        .pc-next {
          margin: 0 6px;
          > a {
            > div {
              padding: 8px 6px;
              min-width: 36px;
              height: 36px;
            }
          }
        }
        .break {
          > a {
            width: 36px;
            height: 36px;
            min-width: unset;
          }
        }
    }
  }
  ${breakpointsMedias.max767}{
    .container{
      padding: 0 16px;
    }
  }
  ${breakpointsMedias.max490}{
    .container{
      /* padding: 0 14px; */
    }
  }
`;
