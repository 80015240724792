import { useApolloClient } from "@apollo/client";
import Button from "components/core/Button";
import ModalWrap from "components/core/ModalWrap";
import { CANCEL_ORDER } from "configs/apolloSchema";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { notifyToastify } from "helpers/ultilities/notifyToastify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useTeleContext } from "../contexts/TeleContext";
interface ModalSellManualProps {
  onClose: () => void;
  orderID: string;
  refreshData: () => void;
}

const TIME_EXPIRE = 24 * 60 * 60;

const ModalCancel = ({
  onClose,
  orderID,
  refreshData,
}: ModalSellManualProps) => {
  //query list lock

  const { t } = useTranslation();
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //on login
  const { apiKey } = useTeleContext();

  const onCancel = async () => {
    setIsLoading(true);
    try {
      client
        .mutate({
          mutation: CANCEL_ORDER,
          variables: {
            _id: orderID,
          },
          context: {
            headers: {
              "apikey": apiKey
            }
          },
          fetchPolicy: "no-cache",
        })
        .then(
          ({ data: { rls_user_cancel_order_buy_resource } }) => {
            if (rls_user_cancel_order_buy_resource === "Success") {
              notifyToastify({ text: t("cancelSuccess"), type: "success" });
              setIsLoading(false);
              refreshData();
              onClose();
            } else {
              setIsLoading(false);
              notifyToastify({ text: t("cancelError"), type: "error" });
            }
          },
          (error) => {
            setIsLoading(false);
            console.log("Cancel err", error);
            notifyToastify({ text: t("cancelError"), type: "error" });
          }
        );
    } catch (error) {
      setIsLoading(false);
      notifyToastify({ text: t("cancelError"), type: "error" });
    }
  };

  return (
    <ModalWrap onClose={onClose} clickOutsideToClose={false}>
      <Wrap>
        <div className="md-title">
          <p className="size-6 color-white">{t("cancelOrder")}</p>
          <span className="size-21 color-white">
            OrderID: <span className="size-22 color-white">{orderID}</span>
          </span>
          <span className="size-21 color-white">
            {t("sureCancel")}
            <span className="size-1 color-red">5</span>
            {t("sureCancel2")}
          </span>
        </div>
        <div className="md-bt">
          <div className="mdb">
            <Button
              text="yesImSure"
              typeButton={1}
              isLoading={isLoading}
              onClick={onCancel}
            />
          </div>
        </div>
      </Wrap>
    </ModalWrap>
  );
};

export default ModalCancel;
const Wrap = styled.div`
  padding: 31px 71px;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 32px);
  max-width: 500px;
  .md-title {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > p {
      margin-bottom: 30px;
    }
    > span:last-child {
      margin-top: 20px;
      max-width: 360px;
    }
  }
  .md-bt {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .mdb {
      width: 200px;
      display: flex;
      justify-content: center;
    }
  }
  ${breakpointsMedias.max1199} {
    padding: 31px 15px;
    max-width: 343px;
    .md-title {
      text-align: center;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      > p {
        margin-bottom: 30px;
      }
      > span:last-child {
        margin-top: 20px;
        max-width: 360px;
      }
    }
    .md-bt {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .mdb {
        width: 200px;
        display: flex;
        justify-content: center;
      }
    }
  }
`;
