import { useMutation } from "@apollo/client";
import NextStep from "components/core/NextStep";
import { MUTATION_EXTEND_AND_BUY } from "configs/apolloSchema";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { formatToken } from "helpers/formatToken";
import { notifyToastify } from "helpers/ultilities/notifyToastify";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TExtendAndBuyInput } from "types";
import { useSystemExtendTeleContext } from "../../contexts/SystemExtendTeleContext";
import { useTeleContext } from "../../contexts/TeleContext";
import ExtendInfo from "./ExtendInfo";
import TableEnd from "./stepEnd/TableEnd";
const TIME_ONE_DAY = 24 * 60 * 60 * 1000;
const StepEnd = () => {
  const { apiKey, requester, balance } = useTeleContext();
  const { t } = useTranslation();
  const { onNextStep, transactions, receiverAddress, totalPayout } =
    useSystemExtendTeleContext();
  const arrExtendData: TExtendAndBuyInput[] = useMemo(() => {
    if (transactions.length) {
      const dataMapping: TExtendAndBuyInput[] = [...transactions].map(
        (item) => {
          const { address, energyExtended, duration, mode } = item;
          let is_extend = true;
          if (mode === "buyMoreOnly") {
            is_extend = false;
          }
          const extend_to = new Date().getTime() + duration * TIME_ONE_DAY;
          return {
            delegator: address,
            is_extend,
            extra_amount: energyExtended,
            extend_to,
          };
        }
      );
      return dataMapping;
    }
    return [];
  }, [transactions.length]);

  const [handleExtendAndBuy, { data, error, loading }] = useMutation(
    MUTATION_EXTEND_AND_BUY
  );
  if (error && error?.message && !data?.rls_extend_and_buy_request) {
    notifyToastify({ type: "error", text: error.message });
  }
  if (data?.rls_extend_and_buy_request) {
    notifyToastify({ type: "success", text: t("extendAndBuySuccessfully") });
    onNextStep(4);
  }
  //on login
  const [isLoading, setIsLoading] = useState(false);
  //get address transfer
  //get balance trx
  const balanceTrx = balance;

  const onExtendAndBuy = async (amount: string) => {
    if (+amount > formatToken({ type: "multiplied", value: balanceTrx })) {
      notifyToastify({ type: "error", text: t("balanceNotEnough") });
      return;
    }
    try {
      setIsLoading(true);
      if (apiKey) {
        handleExtendAndBuy({
          variables: {
            requester: requester,
            resourceType: "ENERGY",
            receiver: receiverAddress,
            extendData: arrExtendData,
            apiKey,
          },
        });
      } else {
        notifyToastify({ type: "error", text: t("errorSignTransaction") });
      }
    } catch (error) {
      console.log("ERROR EXTEND AND BUY", error);
      notifyToastify({ type: "error", text: `${error}` as string });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Wrap>
      <div className="step-content">
        <div className="layout-step">
          <div>
            <TableEnd />
          </div>
        </div>
        <div className="sec-info">
          <ExtendInfo />
        </div>
        <div className="sec-event button-step">
          <NextStep
            typeStep="border"
            text="back"
            onClick={() => {
              onNextStep(2);
            }}
          />
          {data?.rls_extend_and_buy_request ? (
            <NextStep text="Continue" onClick={() => onNextStep(4)} />
          ) : (
            <NextStep
              text="extend"
              onClick={() => onExtendAndBuy(totalPayout)}
              isLoading={isLoading || loading}
              disabled={data?.rls_extend_and_buy_request ? true : false}
            />
          )}
        </div>
      </div>
    </Wrap>
  );
};

export default StepEnd;
const Wrap = styled.div`
  .sec-event {
    display: flex;
    justify-content: center;
    gap: 50px;
  }
  .sec-info {
    margin-top: 24px;
    padding: 12px 48px;
  }
  ${breakpointsMedias.max1024} {
    .sec-info {
      margin-top: 12px;
      padding: 12px 16px;
    }
  }
  ${breakpointsMedias.max767} {
    .sec-info {
      margin-top: 12px;
      padding: 12px 16px;
    }
  }
  ${breakpointsMedias.max667} {
    .sec-event {
      gap: 30px;
    }
  }
`;
