import React from "react";
import styled from "styled-components";
import { TListSelectItem } from "./TableSelect";
import { subStringAddress } from "helpers/subStringAddress";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
type TableSelectItemProps = {
  isCurrent?: boolean;
};
const TableSelectItem = ({
  address,
  lastOrder,
  isCurrent = false,
  ...props
}: TListSelectItem &
  TableSelectItemProps &
  React.HTMLAttributes<HTMLTableRowElement>) => {
  const { t } = useTranslation();
  return (
    <Wrap {...props}>
      <td>
        <p className="size-1 color-white">
          {subStringAddress({ text: address, length: 7 })}{" "}
          {isCurrent && <span className="size-0">({t("yourAddress")})</span>}
        </p>
      </td>
      <td>
        <p className="size-1 color-white tsi-break">
          {lastOrder === -1
            ? "--"
            : dayjs(lastOrder).format("YYYY-MM-DD HH:mm")}
        </p>
      </td>
    </Wrap>
  );
};

export default TableSelectItem;
const Wrap = styled.tr`
  cursor: pointer;
  .tsi-break {
    white-space: nowrap;
  }
`;
