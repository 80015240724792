import React from "react";
import styled from "styled-components";
import EnergyInfo from "./EnergyInfo";
import ExtendInfo from "../ExtendInfo";
import QuickOptions from "./QuickOptions";
import NextStep from "components/core/NextStep";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useSystemExtendTeleContext } from "../../../contexts/SystemExtendTeleContext";

const StepExtendQuick = () => {
  const { onNextStep, onReset, totalPayout, isLoading } =
    useSystemExtendTeleContext();
  return (
    <WrapContain>
      <Wrap>
        <div className="seq-energy">
          <EnergyInfo />
        </div>
        <WrapMode>
          <QuickOptions />
        </WrapMode>
      </Wrap>
      <WrapInfo>
        <ExtendInfo />
      </WrapInfo>
      <div className="seq-event">
        <div>
          <NextStep
            typeStep="border"
            text="back"
            onClick={() => {
              onNextStep(1);
              onReset();
            }}
          />
        </div>
        <div>
          <NextStep
            text="continue"
            onClick={() => onNextStep(3)}
            disabled={totalPayout === "" || isLoading}
            isLoading={isLoading}
          />
        </div>
      </div>
    </WrapContain>
  );
};

export default StepExtendQuick;
const WrapMode = styled.div`
  padding: 12px 24px;
  background-color: var(--black1);
  border-radius: 8px;
  ${breakpointsMedias.max991} {
    padding: 12px 16px;
  }
  ${breakpointsMedias.max767} {
    margin: 0 -16px;
  }
`;
const WrapInfo = styled.div`
  padding: 12px 24px;
  ${breakpointsMedias.max991} {
    padding: 12px 16px;
  }
`;
const WrapContain = styled.div`
  .seq-event {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 5px;
  }
`;
const Wrap = styled.div`
  background-color: var(--secondary-surface-path, #303531);
  padding: 12px 24px;
  .seq-energy {
    margin-bottom: 10px;
  }
  ${breakpointsMedias.max991} {
    padding: 12px 16px;
  }
`;
