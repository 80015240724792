import iconDiscount2 from "assets/images/icon-discount-2.png";
import iconDiscount from "assets/images/icon-discount.png";
import Button from "components/core/Button";
import CheckBox from "components/core/CheckBox";
import TextError from "components/core/TextError";
import TextToolTipRename from "components/core/TextTooltipRename";
import WrapInput from "components/core/WrapInput";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { configColor } from "configs/constants/configColor";
import { colorLoading } from "helpers/colorLoad";
import usePrice from "helpers/energy/usePrice";
import useSystemSettings from "helpers/energy/useSystemSettings";
import { formatToken } from "helpers/formatToken";
import numeral from "numeral";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import styled from "styled-components";
import TronWeb from "tronweb";
import InputPriceMarket from "pages/energy/leftBuy/InputPriceMarket";
import ModalRules from "pages/energy/leftBuy/ModalRules";
import { errorCode } from "helpers/errorCode";
import { BsQrCodeScan } from "react-icons/bs";
import { i18n } from "languages";

type DataMarketBuy = {
  resource: "ENERGY" | "BANDWIDTH";
  amount: string;
  price: string;
  isPartFill: boolean;
  target: string;
  checkTx: boolean;
  txid: string;
  duration: number;
  isDay: boolean,
  apiKey: string,
  balance: number,
  requester: string
};

const minBandwhidthPrice = 100;
const minAmount = Number(process.env.REACT_APP_MIN_DELEGATE_ENERGY_BUY);
const SECOND_OF_ONE_DAY = 86400;
const SECOND_OF_ONE_HOUR = 60 * 60;
const DEFAULT_PRICE = Number(process.env.REACT_APP_DEFAULT_PRICE);
const minSetPartialFill = 200000;

const LINK_RESTFUL_API = process.env.REACT_APP_RESTFUL_API || ""

interface IMarketBuy {
  onMarket?: boolean;
  onChangeRule?: (e: boolean) => void;
}

const fastDuration = [{
  value: 1,
  text: "1h",
  isDay: false
}, {
  value: 3,
  text: "3h",
  isDay: false
}, {
  value: 1,
  text: "1d",
  isDay: true
}, {
  value: 3,
  text: "3d",
  isDay: true
}, {
  value: 7,
  text: "7d",
  isDay: true
}]



const FormBuy = ({ onMarket = true, onChangeRule }: IMarketBuy) => {

  const { minBuyEnergyPrice } = useSystemSettings();
  const { t } = useTranslation();
  const [showRule, setShowRule] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errPrice, setErrPrice] = useState<boolean>(false);
  const [errAmount, setErrAmount] = useState<boolean>(false);
  const [errTarget, setErrTarget] = useState<boolean>(false);
  const [fillable, setFillable] = useState<number | null>(null);
  const [minPrice, setMinPrice] = useState<number | null>(null);
  const [isLoadingEstimate, setIsLoadingEstimate] = useState(false);
  const previousPrice = useRef<string>(DEFAULT_PRICE.toString());
  const previousPartial = useRef<boolean>(true);
  const [dataBuy, setDataBuy] = useState<DataMarketBuy>({
    resource: "ENERGY",
    amount: "100000",
    price: DEFAULT_PRICE.toString(),
    isPartFill: true,
    target: "",
    checkTx: false,
    txid: "",
    duration: 1,
    isDay: false,
    apiKey: "",
    requester: "",
    balance: 0
  });

  const { energyPrice } = usePrice(); //TODO remove or change to local tronweb
  const calAmountPay = (duration_sec: number) => {
    return (
      Number(dataBuy.amount) *
      Number(dataBuy.price) *
      Math.ceil(duration_sec / SECOND_OF_ONE_DAY)
    );
  };
  const amountPay = calAmountPay(dataBuy.duration * (dataBuy.isDay ? SECOND_OF_ONE_DAY : SECOND_OF_ONE_HOUR))

  const executeOrder = async () => {
    // Check Addresss
    if (!TronWeb.isAddress(dataBuy.target)) {
      setErrTarget(true);
      return;
    }

    setIsLoading(true);
    if (dataBuy.balance !== null && dataBuy.balance < amountPay) {
      (window as any).Telegram.WebApp.showAlert("❌ " + t("balanceTrxNotEnough") + " ❌")
      setIsLoading(false);
      return;
    } else {
      sendTx();
    }
  };

  useEffect(() => {
    if (
      Number(dataBuy.price) <
      (dataBuy.resource === "ENERGY"
        ? minPrice || minBuyEnergyPrice
        : minBandwhidthPrice)
    ) {
      setErrPrice(true);
    } else {
      errPrice && setErrPrice(false);
    }
    if (Number(dataBuy.amount) < minAmount) {
      setErrAmount(true);
    } else {
      errAmount && setErrAmount(false);
    }
    if (dataBuy.target && !TronWeb.isAddress(dataBuy.target)) {
      setErrTarget(true);
    } else {
      errTarget && setErrTarget(false);
    }
  }, [
    dataBuy.amount,
    dataBuy.price,
    dataBuy.duration,
    dataBuy.isDay,
    dataBuy.target,
    minPrice,
    minBuyEnergyPrice,
  ]);

  const handleChange = (val: any, key: string) => {
    setDataBuy((prevState) => {
      return {
        ...prevState,
        [key]: val,
      };
    });
  };

  const calPrice = () => {
    let originPay =
      Number(dataBuy.amount) *
      Number(energyPrice) *
      (1 + dataBuy.duration * (dataBuy.isDay ? SECOND_OF_ONE_DAY : SECOND_OF_ONE_HOUR) * 1000 / 86400000);
    return (amountPay / originPay) * 100 - 100;
  };

  const sendTx = () => {
    try {
      let vars = {
        resource_type: dataBuy.resource,
        buy_energy_type: Number(dataBuy.price),
        allow_partial_fill:
          Number(dataBuy.amount) < minSetPartialFill
            ? false
            : dataBuy.isPartFill,
        target_address: dataBuy.target,
        amount: Number(dataBuy.amount),
        duration_millisec: dataBuy.duration * (dataBuy.isDay ? SECOND_OF_ONE_DAY : SECOND_OF_ONE_HOUR) * 1000,
      }
      fetch(`${LINK_RESTFUL_API}v0/internal-buy-energy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "apikey": dataBuy.apiKey
        },
        body: JSON.stringify(vars),
      })
        .then(async (res: any) => {
          if (res.status === 200) {
            getApiKey((window as any).Telegram?.WebApp?.initData);
            (window as any).Telegram.WebApp.showAlert("✅ " + t("orderSuccess") + " ✅")
          } else {
            const err = await res.json();
            throw t(errorCode[err.message] || err.message)
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          (window as any).Telegram.WebApp.showAlert("❌ " + t(err?.message || err) + " ❌")
          console.log({ err })
        });
    } catch (error) {
      setIsLoading(false);
      (window as any).Telegram.WebApp.showAlert("❌ " + t("somethingsError") + " ❌")
    }
  };



  const estimateV3 = async (data: any) => {
    fetch(process.env.REACT_APP_API_ESTIMATE || "", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data.data),
    })
      .then(async (res: any) => {
        const result = await res.json();
        const newFill = Math.floor(
          (result.available_energy / result.total_estimate_energy) * 100
        );

        if (
          Number(convertMinPrice(result.min_energy_price)) ===
          Number(dataBuy.price) ||
          data.changePrice
        ) {
          setFillable(newFill);
        }
        // change price
        if (!data.changePrice) {
          handleChange(convertMinPrice(result.min_energy_price).toString(), "price");
        }
        // setMinPrice(result.min_energy_price)
        setMinPrice(convertMinPrice(result.min_energy_price));
        setIsLoadingEstimate(false);
      })
      .catch((err) => {
        setFillable(null);
        setMinPrice(null);
        setIsLoadingEstimate(false);
      });
  };

  const convertMinPrice = (val: any) => {
    return Number((Math.ceil(Number(val) * 4) / 4).toFixed(2));
  };

  useEffect(() => {
    let debounce: any;
    // if (!errAmount && !errPrice && !errTarget && dataBuy.amount && dataBuy.target) {
    if (!errAmount && !errTarget && dataBuy.amount && dataBuy.target) {
      setIsLoadingEstimate(true);
      debounce = setTimeout(() => {
        let changePrice = false;
        if (previousPrice.current !== dataBuy.price) {
          changePrice = true;
          previousPrice.current = dataBuy.price;
        }
        if (previousPartial.current !== dataBuy.isPartFill) {
          changePrice = true;
          previousPartial.current = dataBuy.isPartFill;
        }
        estimateV3({
          data: {
            target_address: dataBuy.target,
            amount: Number(dataBuy.amount),
            price: Number(dataBuy.price),
            is_partial:
              Number(dataBuy.amount) < minSetPartialFill
                ? false
                : dataBuy.isPartFill,
            request_address: dataBuy.requester,
            duration_sec: dataBuy.duration * (dataBuy.isDay ? SECOND_OF_ONE_DAY : SECOND_OF_ONE_HOUR),
          },
          changePrice: changePrice,
        });
      }, 1000);
    } else {
      setIsLoadingEstimate(false);
      setFillable(null);
    }
    return () => {
      debounce && clearTimeout(debounce);
    };
  }, [
    dataBuy.amount,
    dataBuy.price,
    dataBuy.duration,
    dataBuy.isDay,
    dataBuy.target,
    errAmount,
    errPrice,
    errTarget,
    dataBuy.isPartFill,
    dataBuy.apiKey,
  ]);

  useEffect(() => {
    let debounce: any;
    if (
      dataBuy.price &&
      Number(dataBuy.price) !==
      Number((Math.round(Number(dataBuy.price) * 4) / 4).toFixed(2))
    ) {
      debounce = setTimeout(() => {
        setDataBuy({
          ...dataBuy,
          price: Number(
            (Math.ceil(Number(dataBuy.price) * 4) / 4).toFixed(2)
          ).toString(),
        });
      }, 500);
    }
    return () => {
      debounce && clearTimeout(debounce);
    };
  }, [dataBuy.price]);

  useEffect(() => {
    let debounce: any;
    if (Number(dataBuy.duration) > 30 || !Number(dataBuy.duration)) {
      debounce = setTimeout(() => {
        setDataBuy({
          ...dataBuy,
          duration: Number(dataBuy.duration) > 30 ? 30 : 1,
          isDay: true
        });
      }, 500);
    }
    return () => {
      debounce && clearTimeout(debounce);
    };
  }, [dataBuy.duration]);

  useEffect(() => {
    onChangeRule && onChangeRule(showRule);
  }, [showRule]);

  const onChangeDuration = (dur: number, isDay: boolean) => {
    setDataBuy((prevState) => {
      return {
        ...prevState,
        duration: dur,
        isDay: isDay
      };
    })
  }

  const onKeyPressDuration = (keyCode: number) => {
    if (keyCode === 38) {
      setDataBuy((prevState) => {
        return {
          ...prevState,
          duration: prevState.isDay ? (Number(prevState.duration) < 30 ? Number(prevState.duration) + 1 : 30) : (Number(prevState.duration) === 1 ? 3 : 1),
          isDay: (!prevState.isDay && Number(prevState.duration) === 1) ? false : true
        };
      })
    }
    if (keyCode === 40) {
      setDataBuy((prevState) => {
        return {
          ...prevState,
          duration: prevState.isDay ? (Number(prevState.duration) > 1 ? Number(prevState.duration) - 1 : 3) : 1,
          isDay: (prevState.isDay && Number(prevState.duration) > 1) ? true : false
        };
      })
    }
  }

  const waitTele = () => {
    return new Promise((res) => {
      let attempts = 0,
        maxAttempts = 20;
      const checkTele = () => {
        if ((window as any).Telegram?.WebApp?.initData) {
          (window as any).Telegram?.WebApp?.ready();
          res(true);
          return;
        }
        attempts++;
        if (attempts >= maxAttempts) {
          res(false);
          return;
        }
        setTimeout(checkTele, 100);
      };
      checkTele();
    });
  };

  const getApiKey = async (init_data: string) => {

    if (init_data) {
      await fetch(`${LINK_RESTFUL_API}v0/user-telegram-info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ init_data }),
      })
        .then(async (res: any) => {
          if (res.status === 200) {
            const result = await res.json();
            setDataBuy({
              ...dataBuy,
              apiKey: result.api_key,
              requester: result.requester,
              balance: result.balance,
              target: result.target_address || dataBuy.target
            })
            i18n.changeLanguage(result.language_code || "en")
          } else {
            const err = await res.json()
            throw t(errorCode[err.message] || "")
          }
        })
        .catch((err) => {
          console.log({ err })
        });
    }
  }

  const initTele = async () => {
    let teledata = await waitTele();
    if (teledata) {
      (window as any).Telegram?.WebApp?.expand();
      getApiKey((window as any).Telegram?.WebApp?.initData)
    }
  };

  useEffect(() => {
    initTele()
  }, [])

  return (<>
    <Wrap className={!onMarket ? "mb-home" : ""}>
      <div className="eli-title">
        <span className={`size-4 color-white`}>
          {t("buyEnergy")}
        </span>
        <span className={`size-2 color-white`}>
          {t("balance")}: {numeral(dataBuy.balance / 1e6).format("0,0.[00]")}
        </span>
      </div>
      <div className="eli-row">
        <div className="elir-col elir-right elir-right-2">
          <div className="elirc-name">
            <TextToolTipRename
              text={t("resourceTargetAddress")}
              tooltipContent={t("ttResourceTargetAddress")}
              colorType="white"
            />
          </div>
          <WrapInput className="elirc-input">
            <div className="mb-input">
              <input
                value={dataBuy.target}
                onChange={(e: any) => {
                  handleChange(e.target.value, "target");
                }}
                placeholder={t("address") || ""}
                className="size-21 color-white"
              />
            </div>
            <BsQrCodeScan onClick={() => {
              (window as any).Telegram?.WebApp?.showScanQrPopup({ text: t("Scan Target Address") }, (text: any) => {
                handleChange(text.toString(), "target");
                return true
              })
            }} />
          </WrapInput>
          {errTarget && <TextError text={`${t("mustBeAddress")}`} />}
        </div>
      </div>
      <div className="eli-row eli-amount">
        <div className="elir-col elir-right elir-right-2">
          <div className="elirc-name">
            <TextToolTipRename
              text={t("amount")}
              tooltipContent={t("ttAmount")}
            />
          </div>
          <WrapInput className="elirc-input">
            <div className="mb-input">
              <NumericFormat
                inputMode="decimal"
                value={dataBuy.amount}
                onValueChange={(values: any) => {
                  handleChange(values.value, "amount");
                }}
                thousandSeparator={true}
                placeholder={t("amount")}
                className="size-21 color-white"
                allowNegative={false}
                decimalScale={0}
              />
            </div>
          </WrapInput>
        </div>
      </div>
      <div className="eli-row eli-amount">
        {[minAmount, 100000, 1000000, 2000000, 10000000].map((item, index) => <div
          key={index}
          className="elia-item"
          onClick={() => { handleChange(item, "amount") }}>
          <span className="size-1 color-green">{numeral(item).format("0,0a")}</span>
        </div>)}
      </div>
      {errAmount && (
        <TextError
          className="err-amount"
          text={`${t("minAmountErr")} ${numeral(minAmount).format(
            "0,0"
          )}`}
        />
      )}
      <div className="eli-row eli-pri">
        <div className="elir-col elir-left">
          <div className="elirc-name">
            <TextToolTipRename
              text={t("price")}
              tooltipContent={t("ttPrice")}
            />
          </div>
          <div>
            <InputPriceMarket
              durationSec={dataBuy.duration * (dataBuy.isDay ? SECOND_OF_ONE_DAY : SECOND_OF_ONE_HOUR)}
              minDelegate={null}
              errPrice={errPrice}
              minPrice={minPrice}
              minBuyEnergyPrice={minBuyEnergyPrice}
              value={dataBuy.price}
              targetAddress={dataBuy.target}
              onValueChange={(values: any) => {
                handleChange(values.value, "price");
              }}
            />
          </div>
        </div>
        <div className="elir-col">
          <div className="elirc-name">
            <TextToolTipRename
              text={t("duration")}
              tooltipContent={t("ttDuration")}
            />
          </div>
          <WrapInput className="elirc-input">
            <div className="mb-input">
              <NumericFormat
                inputMode="decimal"
                value={dataBuy.duration}
                onValueChange={(values: any) => {
                  onChangeDuration(values.value, true)
                  // handleChange(values.value, "duration");
                }}
                thousandSeparator={true}
                placeholder={t("duration")}
                className="size-21 color-white"
                allowNegative={false}
                decimalScale={0}
                onFocus={(e: any) => {
                  e.target.onkeydown = (e2: any) => {
                    // console.log(e2.keyCode)
                    onKeyPressDuration(e2.keyCode)
                  }
                }}
              />
            </div>
            <span className="size-21 color-white mb-unit">{dataBuy.isDay ? t("days") : t("hours")}</span>
            <div className="bt-up-down">
              <div className="btud-item size-3 color-white" onClick={() => { onKeyPressDuration(40) }}>-</div>
              <div className="btud-item size-3 color-white" onClick={() => { onKeyPressDuration(38) }}>+</div>
            </div>
          </WrapInput>
        </div>
      </div>
      <div className="eli-row eli-amount">
        {fastDuration.map((item, index) => <div
          key={index}
          className="elia-item"
          onClick={() => { onChangeDuration(item.value, item.isDay) }}>
          <span className="size-1 color-green">{t(item.text)}</span>
        </div>)}
      </div>
      {errPrice && (
        <TextError
          className="err-amount"
          text={`${t("minPriceErr")}${minPrice || minBuyEnergyPrice}`}
        />
      )}
      <div
        className={`eli-row eli-check ${Number(dataBuy.amount) < minSetPartialFill && "disable-checkbox"
          }`}
      >
        <CheckBox
          className="partial-checkbox"
          checked={
            Number(dataBuy.amount) < minSetPartialFill
              ? false
              : dataBuy.isPartFill
          }
          onCheck={() => {
            setDataBuy({ ...dataBuy, isPartFill: !dataBuy.isPartFill });
          }}
        />
        &nbsp;&nbsp;
        <TextToolTipRename
          text={t("allowPartialFill")}
          tooltipContent={t("ttAllowPartialFill")}
        />
        {fillable !== null && !errPrice && (
          <span
            className="size-2 color-green eli-fill"
            style={{ color: colorLoading(fillable) }}
          >
            {t("canInstantFill")}: {fillable}%
          </span>
        )}
      </div>
      <div className="elir-col eli-row eli-row-2">
        <div className="elir-left">
          <TextToolTipRename
            text={t("payout")}
            tooltipContent={t("ttTotal")}
          />
        </div>
        <div className="elir-col elir-right elir-right-3 elir-right-4">
          <span className="size-11 color-gray text-total">
            {numeral(
              formatToken({
                type: "devided",
                value:
                  Number(dataBuy.amount) *
                  Number(energyPrice) *
                  // (1 + listDuration[dataBuy.duration].value / 86400000),
                  (1 + dataBuy.duration * (dataBuy.isDay ? SECOND_OF_ONE_DAY : SECOND_OF_ONE_HOUR) * 1000 / 86400000),
                length: 6,
              })
            ).format("0,0.[00]")}{" "}
            TRX
          </span>
          <span className="size-32 color-green">
            {numeral(
              formatToken({
                type: "devided",
                value: amountPay,
                length: 6,
              })
            ).format("0,0.[00]")}{" "}
            TRX
          </span>
        </div>
      </div>
      <div className="mb-bt">
        <Button
          text="createOrder"
          typeButton={1}
          onClick={executeOrder}
          isLoading={isLoading || isLoadingEstimate}
          disabled={
            !dataBuy.apiKey ||
            errAmount ||
            errPrice ||
            errTarget ||
            minBuyEnergyPrice === 0 ||
            isLoading ||
            isLoadingEstimate
          }
        />
        {calPrice() <= 0 && (
          <div className="bt-discount">
            <div>
              <span className="size-1 text-uppercase color-white">
                {t("save")}
              </span>
              <span className="size-41 color-white">
                {numeral(-calPrice()).format("0,0")}%
              </span>
            </div>
          </div>
        )}
      </div>
    </Wrap>
    {showRule && (
      <ModalRules
        onClose={() => {
          setShowRule(false);
        }}
      />
    )}
  </>
  );
};

export default FormBuy;
const Wrap = styled.div`
  width: 100%;
  padding: 31px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;
  .eli-title {
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .eli-row {
    display: flex;
    margin-bottom: 24px;
    /* z-index: 0; */
    .elir-col {
      display: flex;
      flex-direction: column;
      width: calc(50% - 10px);
      ${breakpointsMedias.max1199} {
        width: calc(50% - 8px);
      }
      position: relative;
      .elirc-name {
        margin-bottom: 12px;
      }
      .elirc-input {
        .mb-input {
          flex: 1;
          margin-right: 12px;
          display: flex;
          align-items: center;
          > input {
            width: 100%;
          }
        }
        img {
          width: 24px;
          height: 24px;
        }
        .mb-unit {
          width: fit-content;
          display: block;
        }
        .bt-up-down {
          display: flex;
          align-items: center;
          gap: 3px;
          margin-left: 8px;
          margin-right: -4px;
          .btud-item {
            width: 26px;
            text-align: center;
            background-color: ${configColor.gray2};
            border-radius: 3px;
            cursor: pointer;
            &:hover {
              background-color: ${configColor.green2};
            }
          }
        }
      }
      .mb-pick {
        margin-top: 10px;
      }
      .mb-pick-0 {
        position: relative;
        z-index: 0;
      }
    }
    .elir-left {
      margin-right: 20px;
      ${breakpointsMedias.max1350} {
        margin-right: 8px;
      }
      ${breakpointsMedias.max1199} {
        margin-right: 16px;
      }
    }
    .elir-right {
      margin-left: auto;
      text-align: right;
      .text-total {
        margin-bottom: 2px;
        position: relative;
        width: fit-content;
        margin-left: auto;
        &::after {
          position: absolute;
          top: 50%;
          left: 0;
          content: "";
          width: 100%;
          height: 1px;
          transform: translateY(-50%);
          background-color: #cccccc;
        }
      }
      > span {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        > svg,
        img {
          margin-left: 4px;
          width: 24px;
          height: 24px;
        }
        &:last-child {
          margin-top: 5px;
        }
      }
    }
    .elir-right-2 {
      width: 100%;
      flex: unset;
    }
  }
  .eli-amount {
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
    .elia-item {
      border: 1px solid ${configColor.green};
      min-width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      opacity: 0.8;
      ${breakpointsMedias.max1599} {
        flex: 1;
        min-width: unset;
        max-width: 110px;
      }
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  .err-amount {
    position: unset;
    margin-top: -6px;
    margin-bottom: 10px;
  }
  .eli-pri {
    z-index: 1;
    margin-bottom: 10px;
    .elir-col:nth-child(2) {
      min-width: 160px;
      ${breakpointsMedias.max1350} {
        flex: 1;
      }
    }
  }
  .eli-check {
    margin-bottom: 0;
    padding-top: 8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .eli-fill {
      margin-left: auto;
    }
    &.disable-checkbox {
      .partial-checkbox {
        opacity: 0.4;
        cursor: not-allowed;
      }
      p {
        cursor: not-allowed;
        color: #ffffff7a;
      }
    }
  }
  .mb-bt {
    width: 100%;
    margin-top: 20px;
    > button {
      width: 100%;
    }
    position: relative;
    .bt-discount {
      position: absolute;
      bottom: -8px;
      right: 0;
      width: 120px;
      height: 90px;
      transform: translateX(15%);
      display: flex;
      &::after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        background-image: url(${iconDiscount2});
        background-size: 100% 100%;
        background-position: bottom center;
        width: 98%;
        height: 100%;
        animation: pulse2 1.5s infinite ease-in-out;
      }
      > div {
        animation: pulse 1.5s infinite ease-in-out;
        background-image: url(${iconDiscount});
        background-size: 100% auto;
        background-position: bottom center;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding-right: 10px;
        padding-bottom: 14px;
        position: relative;
        > span:last-child {
          margin-top: -5px;
        }
        > span {
          transform: rotate(11deg);
        }
      }
      @keyframes pulse {
        0% {
          transform: rotate(0);
        }
        30% {
          transform: rotate(0);
        }
        40% {
          transform: rotate(10deg);
        }
        50% {
          transform: rotate(0);
        }
        60% {
          transform: rotate(10deg);
        }
        70% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(0);
        }
      }
      @keyframes pulse2 {
        0% {
          height: 100%;
        }
        30% {
          height: 100%;
        }
        40% {
          height: 90%;
        }
        50% {
          height: 100%;
        }
        60% {
          height: 90%;
        }
        70% {
          height: 100%;
        }
        100% {
          height: 100%;
        }
      }
    }
  }
  .check-text {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 24px;
    &:hover {
      color: ${configColor.green};
    }
  }
  &.mb-home {
    padding: 31px 56px;
  }
  /* &.mb-home { */

  .eli-title {
    margin-bottom: 16px;
  }
  .eli-row {
    .elir-col {
      .elirc-name {
        margin-bottom: 10px;
      }
    }
    .elir-right-3 {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
      > span {
        &:last-child {
          margin-top: 0;
          margin-left: 6px;
        }
      }
    }
    .elir-right-4 {
      > span {
        &:last-child {
          margin-left: 12px;
        }
      }
    }
  }
  .eli-row-2 {
    margin-bottom: 24px;
    .elir-left {
      width: fit-content;
    }
    .elir-right {
      flex: 1;
    }
    .elir-discount {
      max-width: 50%;
      flex-wrap: wrap;
      flex-direction: row;
      > span:first-child {
        margin-right: 12px;
      }
      > span:last-child {
        cursor: pointer;
      }
    }
  }
  .eli-check {
    padding-bottom: 12px;
    margin-bottom: 0;
  }
  /* } */
  ${breakpointsMedias.max1199} {
    padding: 23px 15px;
    &.mb-home {
      padding: 23px;
    }
    .eli-title {
      margin-bottom: 16px;
    }
    .eli-row {
      margin-bottom: 24px;

      .elir-right {
        > span {
          &:last-child {
            margin-top: 4px;
          }
        }
      }
      .elir-right-3 {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        > span {
          &:last-child {
            margin-top: 0;
          }
        }
      }
      .elir-right-4 {
        > span {
          &:last-child {
            font-family: "Avenir-Heavy";
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            line-height: 24px;
          }
          &:first-child {
            font-family: "Avenir-Roman";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-right: 6px;
          }
        }
      }
    }
    .eli-amount {
      margin-bottom: 10px;
      .elia-item {
        min-width: unset;
        flex: 1;
        padding: 2px;
      }
    }
    .eli-pri {
      margin-bottom: 10px;
    }
    .eli-check {
      padding-bottom: 25px;
      padding-top: 6px;
      margin-bottom: 0;
    }
    .mb-bt {
      margin-top: 12px;
      position: relative;
      .bt-discount {
        bottom: -8px;
        width: 95px;
        height: 72px;
        transform: translateX(18%);
        > div {
          padding-bottom: 10px;
          > span:last-child {
            margin-top: -3px;
          }
        }
      }
    }
    .check-text {
      margin-top: 16px;
    }
  }
  ${breakpointsMedias.max767} {
    padding: 12px 15px;
    &.mb-home {
      padding: 12px 15px;
    }
    .eli-title {
      margin-bottom: 12px;
    }
    .eli-row {
      margin-bottom: 24px;
      .elir-col {
        .elirc-name {
          margin-bottom: 8px;
        }
      }
      .elir-right-3,
      .elir-right-4 {
        width: unset;
        flex: 1;
      }
    }
    .eli-amount {
      margin-bottom: 10px;
    }
    .eli-pri {
      margin-bottom: 10px;
    }
    .eli-row-2 {
      margin-bottom: 16px;
    }
    .eli-check {
      padding-bottom: 12px;
      margin-bottom: 0;
    }
    .check-text {
      margin-top: 12px;
    }
    .mb-bt {
      margin-bottom: 12px;
    }
  }
  ${breakpointsMedias.max320} {
    .eli-pri {
      flex-direction: column;
      .elir-col {
        width: 100%;
      }
      .elir-left {
        margin-bottom: 24px;
      }
    }
  }
`;
