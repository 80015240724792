import iconError from 'assets/images/icon-err.png'
import { configColor } from 'configs/constants/configColor'
import { useTranslation } from 'react-i18next'
import { AiOutlineWarning } from 'react-icons/ai'
import styled from 'styled-components'

interface IWI {
    className?: string,
    text: string,
    place?: "right" | "left",
    infor?: boolean,
    isWarning?: boolean,
    onSetExtra?: () => void
}

const TextError = ({ text, className, place = "left", infor, isWarning = false, onSetExtra }: IWI) => {
    const { t } = useTranslation()

    return <Wrap className={`size-0 color-${infor ? "blue" : "red"} ${className} place-${place} ${isWarning && "warning"}`}>
        {infor ? <AiOutlineWarning size={20} color={isWarning ? "goldenrod" : configColor.colorBlue} /> : < img src={iconError} alt="err" />}
        {text}{onSetExtra && <>,&nbsp;<span className={`size-0 ${infor ? (isWarning ? "goldenrod" : "color-blue") : "color-red"} text-extra`} onClick={() => { onSetExtra() }}>{t("reset")}</span></>}
    </Wrap>
}

export default TextError;

const Wrap = styled.div`
    position: absolute;
    bottom: -24px;
    left: 0;
    text-align: left;
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 100%;
    white-space: nowrap;
    text-align: left;
    justify-content: flex-start;
    > img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
    > svg {
        margin-right: 8px;
    }
    &.place-right {
        left: unset;
        right: 0;
        text-align: right;
        justify-content: flex-end;
    }
    &.warning {
        left: unset;
        right: 0;
        text-align: right;
        justify-content: flex-end;
        color: goldenrod
    }
    .text-extra {
        text-decoration: underline;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
    }
`