import styled from 'styled-components'
import iconMenu from 'assets/images/icon-menu.png'
import iconClose from 'assets/images/icon-close-hover.png'
import { MdClose } from 'react-icons/md'
import { BiMenuAltRight } from 'react-icons/bi'
import { configColor } from 'configs/constants/configColor'

interface IMenuButton {
    onClick: () => void,
    show: boolean
}

const MenuButton = ({ onClick, show }: IMenuButton) => {

    return (
        <Wrap onClick={onClick} >
            <div className="menu-icon" >
                {show ? <img src={iconClose} alt="x" /> : <img src={iconMenu} alt="menu" />}
            </div>
        </Wrap>
    )
}

export default MenuButton

const Wrap = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    background:  #444A46;
    transition: 0.3s;
    .menu-icon {
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
        > img {
            width: 24px;
            height: auto;
        }
    }
    /* &:hover {
        background: ${configColor.green2};
    } */
    
`