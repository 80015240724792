import imgSuccessfully from "assets/images/icon-successfully.png";
import NextStep from "components/core/NextStep";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface SystemExtendSuccessfullyProps {
  onGoOrder: () => void
}
const SystemExtendSuccessfully = ({ onGoOrder }: SystemExtendSuccessfullyProps) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <div className="layout-step ssc-step">
        <WrapContent>
          <div className="ssf-image ">
            <img alt="successfully" src={imgSuccessfully} />
          </div>
          <p className="size-4 color-white ssc-title">{t("extendStep.4")}</p>
          {/* <p
            className={`${width > 767 ? "size-1" : "size-25"} ssc-description`}
          >
            {t("textSuccessfully")}
          </p> */}
        </WrapContent>
      </div>

      <div className="button-step">
        <NextStep
          text="gotoOrder"
          onClick={onGoOrder}
        />
      </div>
    </Wrap>
  );
};

export default SystemExtendSuccessfully;
const WrapContent = styled.div``;
const Wrap = styled.div`
  margin-top: -12px;
  .ssc-step {
    text-align: center;
    padding: min(40px, 100px) 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .ssc-title {
      margin-bottom: 16px;
    }
    .ssc-description {
      color: var(--grey-200, #f3f3f3);
    }
  }
  .ssf-image {
    margin-bottom: 10px;
    img {
      width: 160px;
    }
  }
  ${breakpointsMedias.max767} {
    .ssc-step {
      padding: min(40px, 100px) 12px;
      .ssc-title {
        margin-bottom: 8px;
      }
    }
    .ssf-image {
      margin-bottom: 10px;
      img {
        width: 120px;
      }
    }
  }
`;
