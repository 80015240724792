import DurationSlider from "components/core/DurationSlider";
import RatioInput from "components/core/RatioMode";
import ResourceValueType from "components/core/ResourceValueType";
import TextToolTipRename from "components/core/TextTooltipRename";
import { breakpointsMedias } from "configs/constants/breakpointMedias";

import { getTimeline } from "helpers";
import { useDebounce } from "helpers/hooks/useDebounce";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import styled from "styled-components";
import {
  TModeExtend,
  useSystemExtendTeleContext,
} from "../../../../contexts/SystemExtendTeleContext";

type ExtendDetailProps = {
  maxExtendAmount: number;
  id: string;
  initTimeDuration: number;
  disabled: boolean;
  duration: number;
  initMode: TModeExtend;
  initEnergyExtend: number;
};
const MIN_DELEGATE = 100000;
const ONE_MILLION_AMOUNT = 1000000;
const MAX_RANGE_DURARIONS = 30;
const listModeExtend: TModeExtend[] = [
  "extendOnly",
  "buyMoreOnly",
  "extendAndBuy",
];
const ExtendDetail = ({
  maxExtendAmount,
  id,
  initTimeDuration,
  disabled = false,
  duration,
  initMode,
  initEnergyExtend,
}: ExtendDetailProps) => {
  const { t } = useTranslation();
  const {
    modeGlobal,
    durations,
    onUpdateTransaction,
    onUpdateTransactionField,
  } = useSystemExtendTeleContext();
  const [mode, setMode] = useState<TModeExtend>(initMode);
  const [time, setTime] = useState(duration);
  const [amountEnergy, setAmountEnergy] = React.useState<number>(
    initEnergyExtend || MIN_DELEGATE
  );
  const onChangeMode = (e: ChangeEvent<HTMLInputElement>) => {
    setMode(e.target.value as TModeExtend);
  };
  const energyDebounce = useDebounce(amountEnergy, 200);
  const timerDebounce = useDebounce(time, 200);
  useEffect(() => {
    switch (mode) {
      case "extendOnly": {
        onUpdateTransaction(id, 0, timerDebounce, mode);
        break;
      }
      case "buyMoreOnly": {
        onUpdateTransaction(id, energyDebounce, 0, mode);
        break;
      }
      case "extendAndBuy": {
        onUpdateTransaction(id, energyDebounce, timerDebounce, mode);
        break;
      }
      default:
        break;
    }
  }, [energyDebounce, timerDebounce, mode, id]);
  //set new value amount when duration modeGlobal change
  useEffect(() => {
    if (modeGlobal.includes("sameDuration")) {
      setTime(durations);
      if (modeGlobal.includes("sameExtendOnly")) {
        if (initTimeDuration > durations) {
          onUpdateTransactionField(id, { isBlockMode: true });
          return;
        } else {
          onUpdateTransactionField(id, { isBlockMode: false });
        }
      } else {
        onUpdateTransactionField(id, { isBlockMode: false });
      }
    }
  }, [modeGlobal, durations, initTimeDuration, duration]);
  //set transactions mode "extendOnly" when modeGlobal "sameExtendOnly"
  useEffect(() => {
    if (modeGlobal.includes("sameDuration")) {
    } else {
      //when contain init duration
      setTime(initTimeDuration < duration ? duration : initTimeDuration);
    }
  }, [modeGlobal]);
  //set transactions mode "extendOnly" when modeGlobal "sameExtendOnly"
  useEffect(() => {
    if (modeGlobal.includes("sameExtendOnly")) {
      setMode("extendOnly");
      return;
    }
  }, [modeGlobal, durations]);

  //set Time start when mode = "extendOnly"
  useEffect(() => {
    if (mode === "extendOnly" || mode === "extendAndBuy") {
      // setTime(initTimeDuration);
      setTime(initTimeDuration < duration ? duration : initTimeDuration);
    } else {
      setTime(duration);
    }
  }, [mode, initTimeDuration]);
  //disabled input when mode or modeGlobal
  const isDisabledDuration = useMemo(() => {
    if (mode === "buyMoreOnly" || modeGlobal.includes("sameDuration")) {
      return true;
    }
    return false;
  }, [mode, modeGlobal]);
  const isDisabledAmount = useMemo(() => {
    if (maxExtendAmount < MIN_DELEGATE) {
      setAmountEnergy(MIN_DELEGATE);
      return true;
    }
    if (mode === "extendOnly" || modeGlobal.includes("sameExtendOnly")) {
      return true;
    }
    return false;
  }, [mode, modeGlobal]);
  const timeRangeMin =
    mode === "extendOnly" || mode === "extendAndBuy" ? initTimeDuration : 1;
  //list range time duration
  let arrListTimeLine: number[] = [
    timeRangeMin,
    getTimeline(1, timeRangeMin, MAX_RANGE_DURARIONS),
    getTimeline(2, timeRangeMin, MAX_RANGE_DURARIONS),
    MAX_RANGE_DURARIONS,
  ];
  if (MAX_RANGE_DURARIONS - timeRangeMin < MAX_RANGE_DURARIONS - 1) {
    arrListTimeLine = [
      timeRangeMin,
      Math.ceil((MAX_RANGE_DURARIONS - timeRangeMin) / 2) + timeRangeMin - 1,
      MAX_RANGE_DURARIONS,
    ];
  }
  //change amount when mode "buyMoreOnly" or "extendAndBuy"
  return (
    <Wrap>
      <WrapSelect className="imd-select">
        {listModeExtend.map((item, index) => (
          <div key={item + index} className="select-item">
            <RatioInput
              id={item}
              type="radio"
              value={item}
              onChange={onChangeMode}
              checked={mode === item}
              sizeText={0}
              disabled={
                (modeGlobal.includes("sameExtendOnly") &&
                  item !== "extendOnly") ||
                disabled
              }
            />
          </div>
        ))}
      </WrapSelect>
      <WrapExtend>
        <div
          className={`edc-typing edc-extend-item ${
            isDisabledAmount || disabled ? "edc-typing--disabled" : ""
          }`}
        >
          <div className="edc-typing__amount">
            <div>
              <TextToolTipRename
                text="additionalAmount"
                tooltipContent="additionalAmountTooltip"
                sizeText={1}
                disabled={isDisabledAmount || disabled}
              />
            </div>
            {/* <p className="size-1 color-white">{t("additionalAmount")} &nbsp;</p> */}
            {maxExtendAmount < MIN_DELEGATE ? (
              <p className="size-1 color-white">( {t("availableTooSmall")} )</p>
            ) : (
              <div className="edc-minmax size-1 color-white">
                (
                <ResourceValueType
                  size="tiny"
                  value={MIN_DELEGATE}
                  isSpacing={false}
                  typeFormatValue="0,0a"
                />
                <p className="size-1 color-white">-</p>
                <ResourceValueType
                  size="tiny"
                  value={maxExtendAmount}
                  isSpacing={false}
                  typeFormatValue={
                    maxExtendAmount > ONE_MILLION_AMOUNT ? "0,0.00a" : "0,0a"
                  }
                />
                )
              </div>
            )}
          </div>
          <NumericFormat
            value={amountEnergy}
            placeholder={t("amount")}
            thousandSeparator
            inputMode="decimal"
            allowedDecimalSeparators={[",", "."]}
            onValueChange={(values, sourceInfo) => {
              if (values.floatValue) {
                setAmountEnergy(values.floatValue);
              }
            }}
            decimalScale={3}
            allowNegative={false}
            allowLeadingZeros={true}
            aria-invalid={
              energyDebounce > maxExtendAmount || energyDebounce < MIN_DELEGATE
                ? true
                : false
            }
            className="size-1 color-white bold"
            disabled={isDisabledAmount || disabled}
          />
        </div>

        <div
          className={`tei-slider edc-extend-item ${
            isDisabledDuration || disabled ? "tei-slider--disabled" : ""
          }`}
        >
          <div>
            <TextToolTipRename
              text={`${t("extendDuration")} (${time} ${t(
                time > 1 ? "days" : "day"
              )})`}
              tooltipContent="extendDurationTooltip"
              sizeText={1}
              disabled={isDisabledDuration || disabled}
            />
            {/* <p className="size-1 color-white">
              {t("extendDuration")} ({time} {t(time > 1 ? "days" : "day")})
            </p> */}
          </div>
          <div className="tei-slider__input">
            <DurationSlider
              textSize={"tiny"}
              min={timeRangeMin}
              max={MAX_RANGE_DURARIONS}
              value={time}
              onChange={(e: { target: { value: string | number } }) => {
                setTime(+e.target.value);
              }}
              arrList={arrListTimeLine}
              disabled={isDisabledDuration || disabled}
              onChooseValue={(e: number) => {
                setTime(e);
              }}
            />
          </div>
        </div>
      </WrapExtend>
    </Wrap>
  );
};

export default ExtendDetail;
const Wrap = styled.div`
  ${breakpointsMedias.max1024} {
    /* max-width: calc(100vw - 60px); */
  }
  ${breakpointsMedias.max1024} {
  }
`;
const WrapSelect = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 15px;
  .select-item {
    display: flex;
    align-items: center;
  }
  ${breakpointsMedias.max1024} {
    /* flex-wrap: wrap; */
  }
  ${breakpointsMedias.max767} {
    gap: 5px;
  }
`;
const WrapExtend = styled.div`
  &.hidden {
    opacity: 0;
    display: none;
  }
  &.active {
    opacity: 1;
    display: flex;
  }
  .edc-extend-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .edc-typing {
    flex-grow: 0;
    margin-bottom: 10px;
    &--disabled {
      opacity: 0.3;
      cursor: no-drop;
      * {
        cursor: no-drop;
      }
    }
    input {
      border: 1px solid var(--grey-600);
      padding: 5px 10px;
      border-radius: 5px;
      /* max-width: 100px; */
      &[aria-invalid="true"] {
        border-color: var(--red);
      }
    }
    &__amount {
      display: flex;
      align-items: center;
      .edc-minmax {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
  .tei-slider {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    flex: 1;
    margin-bottom: 10px;

    &--disabled {
      opacity: 0.3;
      cursor: no-drop;
    }
    &__range {
    }
    &__input {
      width: 50%;
    }
  }
  ${breakpointsMedias.max667} {
    .edc-extend-item {
      flex-direction: column;
      align-items: start;
      gap: 10px;
    }
    .edc-typing {
      input {
        width: 100%;
      }
    }

    .tei-slider {
      &__input {
        width: 100%;
      }
    }
  }
`;
