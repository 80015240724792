import QuickExtendContextWrap from "contexts/energy/QuickExtendContext";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import StepExtend from "./stepExtend/StepExtend";
import StepExtendQuick from "./stepExtendQuick/StepExtendQuick";
import {
  TModeSelection,
  useSystemExtendTeleContext,
} from "../../contexts/SystemExtendTeleContext";
type TModeItem = {
  id: TModeSelection;
  text: string;
};
const listMode: TModeItem[] = [
  {
    id: "quick",
    text: "quickExtend",
  },
  {
    id: "advance",
    text: "advance",
  },
];
const StepSelection = () => {
  const { t } = useTranslation();
  const { selectionMode, onUpdateFieldExtendEnergy } =
    useSystemExtendTeleContext();
  const onChangeMode = (mode: TModeSelection) => {
    onUpdateFieldExtendEnergy({
      selectionMode: mode,
    });
  };
  return (
    <Wrap>
      <WrapMode>
        {listMode.map(({ id, text }: TModeItem) => (
          <div
            key={id}
            className={`tsc-item ${selectionMode === id && "active"}`}
            onClick={() => onChangeMode(id)}
          >
            <p className="size-1 color-white">{t(text)}</p>
          </div>
        ))}
      </WrapMode>
      <WrapContent>
        {selectionMode === "quick" && (
          <QuickExtendContextWrap>
            <StepExtendQuick />
          </QuickExtendContextWrap>
        )}
        {selectionMode === "advance" && <StepExtend />}
      </WrapContent>
    </Wrap>
  );
};

export default StepSelection;
const WrapContent = styled.div``;
const WrapMode = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  background-color: var(--secondary-bg);
  border-radius: 8px;

  .tsc-item {
    width: 100%;
    text-align: center;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 8px;
    &.active {
      cursor: pointer;
      background-color: var(--grey-600);
    }
  }
`;
const Wrap = styled.div``;
