import TextToolTipRename from "components/core/TextTooltipRename";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useId } from "react";
import { BsCheckLg } from "react-icons/bs";
import styled from "styled-components";
type RatioCheckProps = {
  id: string;
  isChecked?: boolean;
  sizeText?: 0 | 1 | 21 | 2;
  onCheckChange: () => void;
};
const RatioCheck = ({
  id,
  isChecked = false,
  onCheckChange,
  sizeText = 2,
}: RatioCheckProps) => {
  const idRatio = useId();
  return (
    <WrapSelect className="imd-select">
      <div className="select-item">
        <label
          htmlFor={id + idRatio}
          className="color-white size-21"
          onClick={onCheckChange}
        >
          <WrapBox
            className={`tei-check ${isChecked ? "tei-check--checked" : ""}`}
          >
            {isChecked && (
              <span className="tei-check__icon">
                <BsCheckLg color="#fff" />
              </span>
            )}
          </WrapBox>
          <TextToolTipRename
            text={id}
            tooltipContent={`${id}Tooltip`}
            sizeText={sizeText}
          />
        </label>
      </div>
    </WrapSelect>
  );
};

export default RatioCheck;
const WrapBox = styled.div`
  &.tei-check {
    width: 20px;
    height: 20px;
    border: 1px solid var(--grey-400);
    cursor: pointer;
    position: relative;
    border-radius: 2px;
    &--checked {
      border: 1px solid var(--primary-green-400);
      background-color: var(--primary-green-400);
    }
    &__icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      svg {
        display: inline-flex;
      }
    }
  }
`;
const WrapSelect = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  .select-item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    label {
      margin-left: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
    }
  }
  ${breakpointsMedias.max767} {
    gap: 5px;
    .select-item {
      padding: 5px 0;
    }
  }
`;
