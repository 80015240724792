import ResourceValueType from "components/core/ResourceValueType";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import TableDetailItem from "./TableDetailItem";
import NoData from "components/core/NoData";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { TDelegateDetail } from "types";
const theadTitle = ["type", "amount", "from", "to", "price.sun", "trxPaid"];
export type TDetailItem = {
  id: string;
  type: string;
  energy: number;
  timeFrom: number;
  timeTo: number;
  price: number;
  trxPaid: number;
};
type TableDetailProps = {
  delegateDetails: TDelegateDetail[];
};
const TableDetail = ({ delegateDetails }: TableDetailProps) => {
  const { t } = useTranslation();
  const totalPayout = delegateDetails.reduce((arr: number, item) => {
    return arr + item.trxPaid;
  }, 0);
  return (
    <WrapContainer>
      <Wrap
        className={`scrollbar2 ${
          delegateDetails.length === 0 ? "show" : ""
        } relative`}
      >
        <table>
          <thead>
            <tr>
              {theadTitle.map((item, index) => (
                <td key={index}>
                  <p className="size-1 color-white">{t(item)}</p>
                </td>
              ))}
            </tr>
          </thead>
          <tbody className="relative">
            {delegateDetails.length === 0 ? (
              <div className="tsc-status">
                <div className="tsc-status__item">
                  <NoData subText="null" size="tiny" />
                </div>
              </div>
            ) : (
              delegateDetails.map((item, index) => (
                <TableDetailItem key={index} {...item} />
              ))
            )}
          </tbody>
        </table>
      </Wrap>
      <WrapPayout>
        <p className="size-2 color-white">{t("totalPayout")}</p>
        <ResourceValueType value={totalPayout} type="TRX" />
      </WrapPayout>
    </WrapContainer>
  );
};

export default TableDetail;
const WrapContainer = styled.div``;
const Wrap = styled.div`
  max-height: 400px;
  overflow: auto;
  display: block;
  background: var(--secondary-surface-path, #303531);
  padding: 16px 24px;
  border-radius: 8px;
  ${breakpointsMedias.max1024} {
    padding: 0;
  }
  table {
    display: table;
    width: 100%;
    thead {
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: #222722;
    }
    tr {
      /* border-bottom: 1px solid var(--grey-600); */
      border-bottom: 1px solid #383838;
      td {
        padding: 10px 15px;
      }
    }
  }
  ${breakpointsMedias.max767} {
    table {
      thead {
        display: block !important;
      }
      tr {
        display: block !important;
        th {
          padding: 10px 15px !important;
        }
        td {
          padding: 10px 15px !important;
          min-width: 120px !important;
        }
      }
    }
  }
`;
const WrapPayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 40px;
  ${breakpointsMedias.max1024} {
    padding: 16px 30px;
  }
  ${breakpointsMedias.max667} {
    padding: 16px 16px;
  }
`;
