import { useMutation } from '@apollo/client';
import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { MUTATION_LOGIN } from 'configs/apolloSchema';
import { TronContexts } from 'contexts/TronContexts';
import { splitError } from 'helpers/splitError';
import { notifyToastify } from 'helpers/ultilities/notifyToastify';
import React, { useContext, useEffect, useState } from 'react'
import { Cookies, useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
interface IOnLogin {
    address: string,
    timestamp: number,
    signature: string
    duration_sec?: number
}
const useSignToken = () => {
    const { t } = useTranslation()
    const { address } = useWallet()
    const [isLoading, setIsLoading] = useState(false)
    const [cookies, setCookie] = useCookies([`token-${address}`]);
    const [mutateLogin, { data, loading, error }] = useMutation(MUTATION_LOGIN)
    const [isSigned, setIsSigned] = useState(false)
    const onLogin = async ({ address, timestamp, signature, duration_sec = 3600 }: IOnLogin) => {
        if (!address || !signature) {
            return;
        }
        try {
            setIsLoading(true)
            await mutateLogin({
                variables: {
                    address,
                    timestamp,
                    signature,
                    duration_sec
                }
            }).then(({ data: { rls_login } }) => {
                setCookie(`token-${address}`, rls_login, { maxAge: duration_sec })
                setIsSigned(true)
                setIsLoading(false)
            }, (error) => {
                setIsLoading(false)
                notifyToastify({ type: "error", text: t(splitError(error)) })
            })
        } catch (error) {
            console.log("ERROR LOGIN", error)
        }
    }

    useEffect(() => {
        const Cookie = new Cookies()
        const token = Cookie.get(`token-${address}`);
        if (token) {
            setIsSigned(true)
        }
    }, [])
    // console.log('data login', { data, isSigned, isLoading })
    return { onLogin, isSigned, isLoading: isLoading || loading, error }
}

export default useSignToken