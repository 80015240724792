export const getTimeline = (
  timeline: number,
  minTime: number,
  maxTime: number,
  rangeTime: number = 10
) => {
  return (((maxTime - minTime) % rangeTime) + 1) * timeline;
};
const DEFAULT_ADDRESS = "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb";
export const getFullNode = async (currentNode: string, nextNode: string) => {
  try {
    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        address: DEFAULT_ADDRESS,
        visible: true,
      }),
    };
    const res = await fetch(`${currentNode}/wallet/getaccount`, options).then(
      (res) =>
        res.json().then((data) => {
          return data;
        })
    );
    if (res) {
      return { status: 200, node: currentNode };
    }
    return { status: 201, node: currentNode };
  } catch (error) {
    return { status: 400, node: nextNode };
  }
};
