import { memo, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { listLang } from './PickLang'
import { breakpointsMedias } from 'configs/constants/breakpointMedias'

const PickLangMobile = () => {
    const { t, i18n } = useTranslation()
    const [showPicker, setShowPicker] = useState(false);
    const [picked, setPicked] = useState<number>(0);
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (showPicker) {
            if (listRef.current) {
                listRef.current.focus();
                listRef.current.onblur = () => { setShowPicker(false) };
            }
        }
    }, [showPicker])

    useEffect(() => {
        localStorage.setItem("lang", i18n.language)
        let findLang = listLang.findIndex(item => item.lang === i18n.language)
        if (findLang !== -1) {
            setPicked(findLang)
        } else {
            setPicked(0)
        }
    }, [i18n.language])

    const onPick = (e: string) => {
        // setPicked(e)
        i18n.changeLanguage(e)
    }

    return (
        <PK >
            <span className="size-2 color-white">{t("language")}:</span>
            <div className='pick-list'>
                {listLang.map((item, index) => <div key={index} className={`pick-item ${index === picked && "pick-item-active"}`} onClick={() => { onPick(item.lang); setShowPicker(false) }}>
                    {/* <CheckBox checked={i18n.language === item.lang} onCheck={() => { i18n.changeLanguage(item.lang) }} /> */}
                    <span className={`size-21 color-gray`}>{t(item.text)}</span>
                    <img src={item.icon} alt="" />
                </div>)}
            </div>
        </PK >
    )
}
export default memo(PickLangMobile);

const PK = memo(styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 24px;
    > span {
        margin-bottom: 12px;
        text-transform: uppercase;
    }
    .pick-list {
        display: flex;
        align-items: center;
        gap: 24px;
            /* flex-direction: column; */
        .pick-item {
            display: flex;
            cursor: pointer;
            align-items: center;
            border-radius: 4px;
            background: #444a4649;
            padding: 5px 11px;
            > img {
                width: 24px;
                height: 24px;
                margin-left: 12px;
            }
        }
        .pick-item-active {
            background: #444A46;
            > span {
                color: white;
            }
        }
    }
    ${breakpointsMedias.max767} {
        .pick-list {
            gap: 16px;
        }
    }
    ${breakpointsMedias.max490} {
        .pick-list {
            gap: 16px;
            max-width: 300px;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }
    }
`)
