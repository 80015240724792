export const colorLoading = (rate: number) => {
    let x: number;
    let y: number;
    if (rate <= 80) {
        // y = 255 * rate / 80;
        y = 216 * rate / 80;
        x = 255
    } else {
        // y = 255;
        y = 216;
        if (rate < 100) {
            x = 222
        } else {
            // x = 0
            x = 89
        }
    }
    return `rgb(${x}, ${y}, ${122 * rate / 100})`
}