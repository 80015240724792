import React, { createContext, useContext, useEffect, useReducer } from "react";
import { TDelegateDetail } from "types";
export type TModeExtend = "extendOnly" | "buyMoreOnly" | "extendAndBuy";
export type TModeExtendGlobal = "sameDuration" | "sameExtendOnly" | "normal";
export type TModeSelection = "quick" | "advance";
export type TTransactionType = {
  address: string;
  id: string;
  energy: number;
  energyExtended: number;
  unlockAt: number;
  duration: number;
  mode: TModeExtend;
  newPrice: number | null;
  payout: number | null;
  isExtend: boolean;
  isBlockMode: boolean;
  infoTransaction: TDelegateDetail[];
  extraBuyFaildureReason: string[];
  extendFaildureReason: string[];
};

type TFieldTransaction = Partial<TTransactionType>;
interface IInitEnergy {
  step: number;
  isLoading: boolean;
  targetAddress: string;
  receiverAddress: string;
  durations: number;
  transactions: TTransactionType[];
  modeGlobal: TModeExtendGlobal[];
  totalPayout: string;
  totalExtendAmount: number;
  totalExtraBuyAmount: number;
  energy: number | string;
  //quick extend
  maxPrice: number;
  additionalAmount: number;
  extendAmount: number;
  quickDuration: number;
  selectionMode: TModeSelection;
}
type TFieldEnergy = Partial<IInitEnergy>;

const TIME_DURATION = 1;

export const SystemExtendContext = createContext({
  step: 1,
  onNextStep: (e: number) => {},
  onLoadingStep: (e: boolean) => {},
  isLoading: false,
  transactions: [] as TTransactionType[],
  receiverAddress: "",
  durations: TIME_DURATION,
  modeGlobal: [] as TModeExtendGlobal[],
  totalPayout: "",
  energy: "" as string | number,
  totalExtendAmount: 0,
  totalExtraBuyAmount: 0,
  onAddTransaction: (e: TTransactionType) => {},
  onUpdateTransaction: (
    id: string,
    energy: number,
    duration: number,
    mode: TModeExtend
  ) => {},
  onDeleteTransaction: (id: string) => {},
  onAddressReceiver: (e: string) => {},
  onAddDurations: (e: number) => {},
  onReset: () => {},
  onSetModeGlobal: (e: TModeExtendGlobal[]) => {},
  onUpdateTransactionField: (id: string, field: TFieldTransaction) => {},
  onUpdateFieldExtendEnergy: (field: TFieldEnergy) => {},
  onResetTransactionField: () => {},
  onAddTransactionAll: (e: TTransactionType[]) => {},
  onResetTransactions: () => {},
  //quick extend
  maxPrice: 0,
  additionalAmount: 0,
  extendAmount: 0,
  selectionMode: "quick" as TModeSelection,
  quickDuration: 0,
});
const SystemExtendTeleContextWrap = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initReducer: IInitEnergy = {
    step: 1,
    isLoading: false,
    targetAddress: "",
    receiverAddress: "",
    durations: TIME_DURATION,
    transactions: [],
    modeGlobal: [],
    totalPayout: "",
    energy: "",
    totalExtendAmount: 0,
    totalExtraBuyAmount: 0,
    maxPrice: 0,
    additionalAmount: 0,
    extendAmount: 0,
    selectionMode: "quick",
    quickDuration: 0,
  };
  type TActionEnergy =
    | { type: "NEXT_STEP"; step: number }
    | { type: "EXTEND_DURATION"; duration: number }
    | { type: "LOADING_STEP"; isLoading: boolean }
    | { type: "ADD_TRANSACTION"; transaction: TTransactionType }
    | { type: "ADD_TRANSACTIONS_ALL"; transactions: TTransactionType[] }
    | {
        type: "UPDATE_TRANSACTION";
        id: string;
        energyExtend: number;
        duration: number;
        mode: TModeExtend;
      }
    | {
        type: "UPDATE_TRANSACTION_FIELD";
        id: string;
        field: TFieldTransaction;
      }
    | {
        type: "RESET_TRANSACTION_FIELD";
      }
    | {
        type: "UPDATE_EXTEND_ENERGY_FIELD";
        field: TFieldEnergy;
      }
    | { type: "DELETE_TRANSACTION"; id: string }
    | { type: "RESET_TRANSACTIONS" }
    | { type: "ADD_RECEIVER_ADDRESS"; address: string }
    | { type: "SET_MODE_GLOBAL"; modes: TModeExtendGlobal[] }
    | { type: "RESET" }

    //quick extend
    | { type: "ADD_MAX_PRICE"; maxPrice: number };
  const reducer = (state: IInitEnergy, action: TActionEnergy) => {
    switch (action.type) {
      case "NEXT_STEP":
        const { step } = action;
        return {
          ...state,
          step,
        };
      case "LOADING_STEP":
        const { isLoading } = action;
        return {
          ...state,
          isLoading,
        };
      case "EXTEND_DURATION":
        const { duration } = action;
        return {
          ...state,
          durations: duration,
        };
      case "ADD_TRANSACTION": {
        const { transaction } = action;
        const isExist = [...state.transactions].findIndex(
          (item) => item.id === transaction.id
        );
        if (isExist !== -1) {
          return {
            ...state,
            transactions: [...state.transactions],
          };
        }
        return {
          ...state,
          transactions: [...state.transactions, transaction],
        };
      }
      case "ADD_TRANSACTIONS_ALL": {
        const { transactions } = action;
        // let transactionsAdd: TTransactionType[] = [];
        // [...state.transactions].map((item) => {
        //   [...transactions].forEach((transactions) => {
        //     if (item.id !== transactions.id) {
        //       transactionsAdd.push(item);
        //     }
        //   });
        // });
        // console.log({ transactionsAdd });
        return {
          ...state,
          transactions: [...transactions],
          // transactions: [...state.transactions, ...transactionsAdd],
        };
      }
      case "DELETE_TRANSACTION": {
        const { id } = action;
        const newTransaction = [...state.transactions].filter(
          (item) => item.id !== id
        );
        return {
          ...state,
          transactions: newTransaction,
        };
      }
      case "RESET_TRANSACTIONS": {
        return {
          ...state,
          transactions: [],
        };
      }
      case "UPDATE_TRANSACTION": {
        const { energyExtend, duration, id, mode } = action;
        const indexItemUpdate = [...state.transactions].findIndex(
          (item) => item.id === id
        );
        if (indexItemUpdate !== -1) {
          const itemUpdate = [...state.transactions][indexItemUpdate];
          itemUpdate.energyExtended = energyExtend;
          itemUpdate.duration = duration;
          itemUpdate.mode = mode;
          return {
            ...state,
            transactions: [...state.transactions],
          };
        }
        return {
          ...state,
        };
      }
      case "UPDATE_TRANSACTION_FIELD": {
        const { id, field } = action;
        const indexItemUpdate = [...state.transactions].findIndex(
          (item) => item.id === id
        );
        if (indexItemUpdate !== -1) {
          const updatedItem = {
            ...state.transactions[indexItemUpdate],
            ...field,
          };
          const updatedTransactions = [...state.transactions];
          updatedTransactions[indexItemUpdate] = updatedItem;

          return {
            ...state,
            transactions: updatedTransactions,
          };
        }
        return {
          ...state,
        };
      }
      case "RESET_TRANSACTION_FIELD": {
        return {
          ...state,
          transactions: [],
          totalPayout: "",
        };
      }
      case "UPDATE_EXTEND_ENERGY_FIELD": {
        const { field } = action;
        return {
          ...state,
          ...field,
        };
      }

      case "ADD_RECEIVER_ADDRESS": {
        const { address } = action;
        return {
          ...state,
          receiverAddress: address,
          transactions: [],
        };
      }
      case "SET_MODE_GLOBAL": {
        const { modes } = action;
        return {
          ...state,
          modeGlobal: [...modes],
        };
      }
      case "RESET": {
        return { ...initReducer };
      }

      //
      case "ADD_MAX_PRICE": {
        const { maxPrice } = action;
        return { ...state, maxPrice };
      }
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initReducer);
  const onNextStep = (step: number) => {
    dispatch({
      type: "NEXT_STEP",
      step,
    });
  };
  const onLoadingStep = (isLoading: boolean) => {
    dispatch({
      type: "LOADING_STEP",
      isLoading,
    });
  };
  const onAddTransaction = (transaction: TTransactionType) => {
    dispatch({
      type: "ADD_TRANSACTION",
      transaction,
    });
  };
  const onAddTransactionAll = (transactions: TTransactionType[]) => {
    dispatch({
      type: "ADD_TRANSACTIONS_ALL",
      transactions,
    });
  };
  const onUpdateTransaction = (
    id: string,
    energyExtend: number,
    duration: number,
    mode: TModeExtend
  ) => {
    dispatch({
      type: "UPDATE_TRANSACTION",
      id,
      energyExtend,
      duration,
      mode,
    });
  };

  const onUpdateTransactionField = (id: string, field: TFieldTransaction) => {
    dispatch({
      type: "UPDATE_TRANSACTION_FIELD",
      id,
      field,
    });
  };
  const onResetTransactionField = () => {
    dispatch({
      type: "RESET",
    });
  };
  const onUpdateFieldExtendEnergy = (field: TFieldEnergy) => {
    dispatch({
      type: "UPDATE_EXTEND_ENERGY_FIELD",
      field,
    });
  };
  const onDeleteTransaction = (id: string) => {
    dispatch({
      type: "DELETE_TRANSACTION",
      id,
    });
  };
  const onResetTransactions = () => {
    dispatch({
      type: "RESET_TRANSACTIONS",
    });
  };
  const onAddressReceiver = (address: string) => {
    dispatch({
      type: "ADD_RECEIVER_ADDRESS",
      address,
    });
  };
  const onAddDurations = (duration: number) => {
    dispatch({
      type: "EXTEND_DURATION",
      duration,
    });
  };
  const onReset = () => {
    dispatch({
      type: "RESET",
    });
  };
  const onSetModeGlobal = (modes: TModeExtendGlobal[]) => {
    dispatch({
      type: "SET_MODE_GLOBAL",
      modes,
    });
  };

  const {
    step,
    isLoading,
    transactions,
    receiverAddress,
    durations,
    modeGlobal,
    totalPayout,
    energy,
    totalExtendAmount,
    totalExtraBuyAmount,
    maxPrice,
    additionalAmount,
    extendAmount,
    selectionMode,
    quickDuration,
  } = state;
  //when transactions change and transactions length = 0 then reset
  useEffect(() => {
    if (step === 2 && transactions.length === 0) {
      onUpdateFieldExtendEnergy({
        totalPayout: "",
        totalExtendAmount: 0,
        totalExtraBuyAmount: 0,
      });
    }
  }, [transactions.length, step]);
  return (
    <SystemExtendContext.Provider
      value={{
        step,
        isLoading,
        transactions,
        receiverAddress,
        durations,
        modeGlobal,
        totalPayout,
        energy,
        totalExtendAmount,
        totalExtraBuyAmount,
        onNextStep,
        onLoadingStep,
        onAddTransaction,
        onUpdateTransaction,
        onDeleteTransaction,
        onAddressReceiver,
        onAddDurations,
        onReset,
        onSetModeGlobal,
        onUpdateTransactionField,
        onUpdateFieldExtendEnergy,
        onResetTransactionField,
        onAddTransactionAll,
        onResetTransactions,
        //quick extend
        maxPrice,
        additionalAmount,
        extendAmount,
        selectionMode,
        quickDuration,
      }}
    >
      {children}
    </SystemExtendContext.Provider>
  );
};

export default SystemExtendTeleContextWrap;
export const useSystemExtendTeleContext = () => useContext(SystemExtendContext);
