import numeral from "numeral"

const SECOND_PER_DAY = 60 * 60 * 24

export const calDuration = (e: number) => {
  let hours = Math.round(e / (60 * 60) * 10) / 10;
  if (hours >= 24) {
    const days = e / (60 * 60 * 24)
    return { value: numeral(days).format("0,0.[0]"), unit: days <= 1 ? "day" : "days" }
  } else {
    const hours = e / (60 * 60)
    return { value: numeral(hours < 1 ? hours * 60 : hours).format("0,0"), unit: hours < 1 ? "mins" : hours === 1 ? "hour" : "hours" }
  }
}

export const calDuration2 = (e: number) => {
  let hours = Math.round(e / (60 * 60) * 10) / 10;
  if (hours >= 24) {
    const days = e / (60 * 60 * 24)
    return numeral(days).format("0,0.[0]") + "D"
  } else {
    const hours = e / (60 * 60)
    return numeral(hours < 1 ? hours * 60 : hours).format("0,0") + (hours < 1 ? "m" : "H")
  }
}