import { configColor } from "configs/constants/configColor";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import iconSW from "assets/images/icon-savewallet.svg"
import { breakpointsMedias } from "configs/constants/breakpointMedias";

const TrySaveWalet = () => {
    const { t } = useTranslation();

    return (
        <Wrap className="" tabIndex={1}>
            <img src={iconSW} alt="" />
            <a className="color-green size-22" href="https://savewallet.io/" target="_blank" rel="noreferrer">{t("trySaveWallet")}!</a>
        </Wrap>
    );
};

export default TrySaveWalet;

const Wrap = styled.div`
    position: fixed;
    right: 0;
    top: 300px;
    background-color: ${configColor.colorBlack};
    border-radius: 6px 0 0 6px;
    width: fit-content;
    max-width: 50px;
    overflow: hidden;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    border: 2px solid ${configColor.green2};
    border-right: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    padding: 0 4px 0 2px;
    > img {
        width: 40px;
    }
    > a {
        max-width: 0px;
        overflow: hidden;
        /* max-height: 0px; */
        width: fit-content;
        height: fit-content;
        transition: all 0.3s ease-in-out;
        text-align: center;

    }
    &:hover,
    &:focus {
        max-width: 200px;
        > a {
            max-width: 105px;
            ${breakpointsMedias.max1199} {
                max-width: 90px;
            }
            ${breakpointsMedias.max767} {
                max-width: 80px;
            }
            /* max-height: 100px; */
        }
    }
    ${breakpointsMedias.max767} {
        max-width: 46px; 
        height: 40px;
        padding: 0 4px 0 2px;
        > img {
            width: 32px;
        }
    }
`;
