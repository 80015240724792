import DurationSlider from "components/core/DurationSlider";
import styled from "styled-components";
type QuickInputExtendAmountProps = {
  min: number;
  max: number;
  value: number;
  onChange: (e: number) => void;
};
const QuickInputExtendAmount = ({
  min,
  max,
  value,
  onChange,
}: QuickInputExtendAmountProps) => {
  const middleValue =
    (max - min) % 2 === 0 ? (max - min) / 2 + 1 : Math.floor((max - min) / 2);
  let arrListTimeLine: number[] = [];
  switch (true) {
    case max - min > 10: {
      if ((max - min) % 2 === 0) {
        arrListTimeLine = [min, middleValue, max];
      } else {
        arrListTimeLine = [
          min,
          min + Math.floor((max - min) / 3),
          Math.ceil(((max - min) / 3) * 2 + 1),
          max,
        ];
      }
      break;
    }

    case max - min > 4: {
      for (let i = min; i <= max; i++) {
        arrListTimeLine.push(i);
      }
      break;
    }

    default:
      arrListTimeLine = [min, max];
      break;
  }

  return (
    <Wrap>
      <DurationSlider
        textSize={"tiny"}
        min={min}
        max={max}
        value={value}
        onChange={(e: { target: { value: string | number } }) => {
          onChange(+e.target.value);
        }}
        arrList={arrListTimeLine}
        //   disabled={isDisabledDuration || disabled}
        onChooseValue={(e: number) => {
          onChange(e);
        }}
        rangeSize="tiny"
      />
    </Wrap>
  );
};

export default QuickInputExtendAmount;
const Wrap = styled.div`
  width: 100%;
`;
