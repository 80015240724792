import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { configColor } from "configs/constants/configColor";
import React from "react";
import styled from "styled-components";
interface LoadingSprinProps {
  size?: "small" | "normal" | "big";
  color?: "primary" | "white" | "gray";
}
const LIST_COLOR = {
  primary: configColor.colorPrimary,
  gray: configColor.colorGray,
  white: configColor.colorWhite,
};
const LoadingSpinner = ({
  size = "normal",
  color = "white",
}: LoadingSprinProps) => {
  return (
    <Wrap className={`${size}`} color={LIST_COLOR[color]}>
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Wrap>
  );
};

export default LoadingSpinner;
interface WrapProps {
  color: string;
}
const Wrap = styled.div<WrapProps>`
  .lds-spinner {
    color: #fff;
    display: flex;
    position: relative;
    width: 24px;
    height: 24px;
  }

  .lds-spinner div {
    transform-origin: 12px 12px;
    animation: lds-spinner 0.7s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 11px;
    width: 1.6px;
    height: 6px;
    border-radius: 30%;
    background: ${({ color }) => color};
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(45deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(90deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(135deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(225deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(315deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  &.big {
    .lds-spinner {
      width: 30px;
      height: 30px;
    }
    .lds-spinner div {
      transform-origin: 13px 16px;
    }
    .lds-spinner div:after {
      left: 12px;
      height: 8px;
    }
  }
  ${breakpointsMedias.max767} {
    .lds-spinner {
      width: 20px;
      height: 20px;
    }
    .lds-spinner div {
      transform-origin: 10px 10px;
    }
    .lds-spinner div:after {
      left: 9px;
      height: 5px;
    }
    &.small {
      .lds-spinner {
        width: 18px;
        height: 18px;
      }
      .lds-spinner div {
        transform-origin: 9px 9px;
      }
      .lds-spinner div:after {
        left: 8px;
        height: 5px;
      }
    }
  }
`;
