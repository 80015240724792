import iconTrx from "assets/images/trx.png";
import BigNumber from "bignumber.js";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { configColor } from "configs/constants/configColor";
import { useWidthScreen } from "helpers/hooks/useWidthScreen";
import { subStringAddress } from "helpers/subStringAddress";
import numeral from "numeral";
import styled from "styled-components";
import { TxInfo } from "./ActiveOrders";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface ITTLI {
  data: TxInfo;
}

const TableTransactionListItem = ({ data }: ITTLI) => {
  //self link when mobile
  const { t } = useTranslation();
  const { isMobile } = useWidthScreen();
  return (
    <>
      <Wrap className={``}>
        <td>
          <div>
            <a
              target={isMobile ? "_self" : "_blank"}
              rel="noreferrer"
              href={`${process.env.REACT_APP_SCAN_NETWORK}#/transaction/${data.delegate_txid}`}
              className="size-21 color-white"
            >
              {subStringAddress({
                text: data.delegate_txid || "--",
                length: 5,
              })}
            </a>
          </div>
        </td>
        <td className="td-time">
          <div>
            <a
              target={isMobile ? "_self" : "_blank"}
              rel="noreferrer"
              href={`${process.env.REACT_APP_SCAN_NETWORK}#/address/${data.address}`}
              className="size-21 color-white"
            >
              {subStringAddress({ text: data.address || "--", length: 5 })}
            </a>
          </div>
        </td>
        <td className="td-amount td-right">
          <div>
            <span className={`size-2 color-white`}>
              {data.delegate_amount !== null
                ? numeral(
                  new BigNumber(data.delegate_amount || 0).div(1e6).valueOf()
                ).format("0,0.[00]")
                : "--"}
              &nbsp;
              <img src={iconTrx} alt="" />
            </span>
          </div>
        </td>
        {/* <td className="td-amount td-right">
          <div>
            <span className={`size-2 color-white`}>
              {data.payment !== null
                ? numeral(
                  new BigNumber(data.payment || 0).div(1e6).valueOf()
                ).format("0,0.[00]")
                : "--"}
              &nbsp;
              <img src={iconTrx} alt="" />
            </span>
          </div>
        </td> */}
        <td className="td-amount td-right">
          <div>
            <span className={`size-2 color-white`}>
              {data.expired_at
                ? dayjs(new Date(data.expired_at).getTime()).format(
                  "MM/DD/YYYY HH:mm"
                )
                : "--"} {data.is_extended && (t("extended"))}
            </span>
          </div>
        </td>
      </Wrap>
    </>
  );
};

export default TableTransactionListItem;

const Wrap = styled.tr`
  > td {
    background-color: #303631;
    height: 50px;
    vertical-align: bottom;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    > div {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 24px;
      > span {
        display: flex;
        align-items: center;
        text-align: left;
        > img {
          width: 24px;
          height: 24px;
        }
      }
      > a {
        text-align: left;
        text-decoration: underline;
        &:hover {
          color: ${configColor.colorBlue};
        }
      }
    }
    &:nth-child(1) {
      > div {
        text-align: left;
        > span {
          display: flex;
          flex-direction: column;
          text-align: left;
          align-items: flex-start;
          justify-content: center;
        }
      }
    }
    &:nth-child(3),
    &:nth-child(4) {
      > div {
        /* padding-right: 24px; */
        text-align: right;
        align-items: flex-end;
        > span {
          text-align: right;
        }
      }
    }
    &:nth-child(4) {
      > div {
        padding-right: 24px;
        > span {
          max-width: 160px;
        }
      }
    }
  }
  &:last-child {
    td {
      border-bottom: none;
    }
  }
  ${breakpointsMedias.max1199} {
    > td {
      height: 48px;
      > div {
        padding-left: 12px;
      }
      &:nth-child(4) {
        > div {
          padding-right: 12px;
        }
      }
    }
  }
`;
