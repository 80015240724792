import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import React, { ReactNode, useState, useEffect, useContext } from "react";
import TronWeb from "tronweb";
import { TronContexts } from "./TronContexts";

interface IProps {
    children: ReactNode;
}

const TronContext = React.createContext({
    tronData: {
        isconnect: false,
        address: "",
        balance: "",
        balanceWTRX: "",
        balanceToken: "",
        reject: 0
    },
    updateDataTron: (add: string) => { }
})

type TronData = {
    isconnect: boolean,
    address: any,
    balance: any,
    balanceWTRX: any
    balanceToken: any,
    reject: number
}

const WTRX_CONTRACT = "TYsbWxNnyTgsZaTFaue9hqpxkU3Fkco94a";
const TOKEN_CONTRACT = "TNAbUYGtD39WaDocUnWG4VWe45WKEwSJcf"

const TronContainer = ({ children }: IProps) => {
    const { address, connected } = useWallet();
    const { tronWeb } = useContext(TronContexts)
    const [tronData, setTronData] = useState<TronData>({
        isconnect: false,
        address: "",
        balance: "",
        balanceWTRX: "",
        balanceToken: "",
        reject: 0
    })

    useEffect(() => {
        if (address && connected) {
            updateDataTron(address)
        }
    }, [address, connected])

    const updateDataTron = async (tronAddress: string) => {
        let balance = await tronWeb.trx.getBalance(tronAddress);
        let balance2 = await getBalanceWTrx()
        let balanceToken = await getBalanceToken()
        setTronData({
            ...tronData,
            isconnect: true,
            address: tronAddress,
            balance: tronWeb.toBigNumber(balance).div(1e6).valueOf(),
            balanceWTRX: balance2,
            balanceToken: balanceToken,
        })

    }

    const getBalanceWTrx = async () => {
        let instance = await tronWeb.contract().at(WTRX_CONTRACT);
        let res = await instance
            .balanceOf(address)
            .call();
        return tronWeb.toBigNumber(res).div(1e6).valueOf();
    };

    const getBalanceToken = async () => {
        let instance = await tronWeb.contract().at(TOKEN_CONTRACT);
        let res = await instance
            .balanceOf(address)
            .call();
        return tronWeb.toBigNumber(res).div(1e18).valueOf();
    };

    return (
        <TronContext.Provider
            value={{
                tronData,
                updateDataTron
            }}
        >{children}</TronContext.Provider>
    );
};
export default TronContainer
export { TronContext };
