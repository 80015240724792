import { breakpointsMedias } from 'configs/constants/breakpointMedias'
import styled from 'styled-components'

interface IWrapSection {
    mobile?: boolean,
    isWS?: boolean
    children: any,
    className?: string
}

const WrapSection = ({ children, mobile, isWS, className }: IWrapSection) => {

    return <Wrap className={`${mobile ? "ws-mobile" : ""} ${isWS ? "ws-desk" : ""} ${className}`}>
        <div className='ws-bg'><div></div></div>
        <div className='ws-wrap'>
            {children}
        </div>
    </Wrap>
}

export default WrapSection;


const Wrap = styled.span`
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background: rgba(45, 49, 46, 0.50);
    display: flex;
    width: 100%;
    .ws-bg {
        display: none;
    }
    .ws-wrap {
        width: 100%;
        display: flex;
    }
    &.ws-desk {
        border: none;
        padding: 1px;
        position: relative;
        background: none;
        .ws-bg {
            position: absolute;
            background: linear-gradient(-45deg, #8CF5A8 0%, #c1ffd210 50%,  #8CF5A8 100%);
            width: 100%;
            height: 100%;
            border-radius: 12px;
            padding: 1px;
            display: flex;
            opacity: 0.6;
            > div {
                width: 100%;
                height: 100%;
                background: #282D2A;
                border-radius: 12px;
            }
        }
        .ws-wrap {
            background: none;
        }
    }
    ${breakpointsMedias.max1199} {
        &.ws-mobile {
            border: none;
            padding: 1px;
            background: linear-gradient(-45deg, #8CF5A8 0%, #c1ffd210 50%,  #8CF5A8 100%);
            .ws-wrap {
                background: #282D2A;
                border-radius: 11px;
            }
        }
    }
    
`