import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FormBuy from "./FormBuy";
import SystemExtendTeleContextWrap from "./contexts/SystemExtendTeleContext";
import TeleContextWrap from "./contexts/TeleContext";
import ModalSystemExtend from "./systemExtend/ModalSystemExtend";
import MyOrders from "./history/MyOrders";

const TeleApp = () => {
  const { t } = useTranslation();
  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("src", "https://telegram.org/js/telegram-web-app.js");
    document.head.append(script);
  }, []);

  const [tab, setTab] = useState<"buy" | "extend" | "history">("buy");
  return (
    <TeleContextWrap>
      <Tabs className="tabs color-white">
        <div
          className={`tab ${tab === "buy" ? "tab-active" : ""}`}
          onClick={() => setTab("buy")}
        >
          {t("buy")}
        </div>
        <div
          className={`tab ${tab === "extend" ? "tab-active" : ""}`}
          onClick={() => setTab("extend")}
        >
          {t("extend")}
        </div>
        <div
          className={`tab ${tab === "history" ? "tab-active" : ""}`}
          onClick={() => setTab("history")}
        >
          {t("history")}
        </div>
      </Tabs>
      <WrapContainer>
        <Wrap className="color-white size-5">
          {tab === "buy" && <FormBuy />}
          {tab === "extend" && (
            <SystemExtendTeleContextWrap>
              <ModalSystemExtend onGoOrder={() => { setTab("history") }} />
            </SystemExtendTeleContextWrap>
          )}
          {tab === "history" && <MyOrders />}
        </Wrap>
      </WrapContainer>
    </TeleContextWrap>
  );
};

export default TeleApp;
const WrapContainer = styled.div`
  /* max-height: calc(100dvh - 120px); */
  overflow-y: auto;
  /* overflow-x: hidden; */
  z-index: 0;
  position: relative;
  margin-top: 50px;
`;
const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin-bottom: 20px;
  position: fixed;
  top: 0;
  background: #000;
  z-index: 1;
  .tab {
    cursor: pointer;
    padding: 10px 20px;
    text-align: center;
    width: 100%;
    border-right: 1px solid #ccc;
    :last-child {
      border-right: none;
    }
    &.tab-active {
      color: var(--primary-green-400);
    }
  }
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
