import ResourceValueType from "components/core/ResourceValueType";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { formatToken } from "helpers/formatToken";
import { subStringAddress } from "helpers/subStringAddress";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSystemExtendTeleContext } from "../../contexts/SystemExtendTeleContext";
const ExtendInfo = () => {
  const { t } = useTranslation();
  const {
    receiverAddress,
    // transactions,
    totalPayout,
    totalExtendAmount,
    totalExtraBuyAmount,
  } = useSystemExtendTeleContext();

  // const { amount, amountInc } = [...transactions].reduce(
  //   (acc, item) => {
  //     const amount = acc.amount + (item.isExtend ? item.energy : 0);
  //     const amountInc =
  //       acc.amountInc + (item.isExtend ? item.energyExtended ?? 0 : 0);
  //     return {
  //       amount,
  //       amountInc,
  //     };
  //   },
  //   {
  //     amount: 0,
  //     amountInc: 0,
  //   }
  // );
  return (
    <Wrap>
      <div className="epc-item">
        <p className="size-1 color-white">{t("targetAddress")}</p>
        <p className="size-1 color-white">
          {subStringAddress({ text: receiverAddress, length: 8 })}
        </p>
      </div>
      <div className="epc-item">
        <p className="size-1 color-white">{t("totalExtendAmount")}</p>
        <p className="size-1 color-white">
          <ResourceValueType
            value={totalExtendAmount}
            typeFormatValue={"0,0"}
          />
        </p>
      </div>
      {!!totalExtraBuyAmount && (
        <div className="epc-item">
          <p className="size-1 color-white">{t("totalAdditionalAmount")}</p>
          <p className="size-1 color-white">
            <ResourceValueType
              value={totalExtraBuyAmount}
              typeFormatValue={"0,0"}
            />
          </p>
        </div>
      )}
      <div className="epc-item">
        <p className="size-1 color-white">{t("payout")}</p>
        <p className="size-1 color-white">
          <ResourceValueType
            value={formatToken({ type: "devided", value: totalPayout })}
            type="TRX"
          />
        </p>
      </div>
    </Wrap>
  );
};

export default ExtendInfo;
const Wrap = styled.div`
  .epc-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  ${breakpointsMedias.max1024} {
    .epc-item {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ${breakpointsMedias.max767} {
  }
`;
