import BigNumber from 'bignumber.js';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fNumber(number: any) {
    return numeral(number).format();
}

export function formatCustom(number: any, type: string = "0,0.[00]") {
    return numeral(number).format(type, Math.floor);
}
export function formatCeil(number: any, type: string = "0,0.[00]") {
    return numeral(number).format(type, Math.ceil);
}

export function fCurrency(number: any) {
    const format = number ? numeral(number).format('$0,0.[00]') : '';

    return result(format, '.00');
}

export function fPercent(number: any) {
    const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

    return result(format, '.0');
}

export function fShortenNumber(number: any) {
    const format = number ? numeral(number).format('0.00a') : '0';

    return result(format, '.00');
}

export function fData(number: any) {
    const format = number ? numeral(number).format('0.0 b') : '';

    return result(format, '.0');
}
export function formatPrice(number: BigNumber.Value, decimal: number = 1e18) {
    const format = number ? new BigNumber(number).div(decimal).toNumber() : 0;
    return format;
}
export function formatSymbolPrice(number: BigNumber.Value, decimal: number = 1e18) {
    const format = new BigNumber(number).multipliedBy(decimal).toNumber()
    return format;
}

export function formatDecimal6(number: BigNumber.Value, decimal: number = 1e6) {
    const format = number ? new BigNumber(number).div(decimal).toNumber() : 0;
    return format;
}

export function formatSymbol(number: BigNumber.Value, decimal: number = 1e6) {
    const format = new BigNumber(number).multipliedBy(decimal).toNumber()
    return format;
}
export function formatPercent(number: BigNumber.Value) {
    const format = number ? new BigNumber(number).multipliedBy(100).toNumber() : 0;
    return format;
}
function result(format: string, key = '.00') {
    const isInteger = format.includes(key);
    return isInteger ? format.replace(key, '') : format;
}
