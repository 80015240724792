import { useTronContexts } from "contexts/TronContexts";
import { formatToken } from "helpers/formatToken";
import { useEffect, useState } from "react";
export async function getBalanceTrxAvailable(tronWeb: any, address: string) {
  try {
    const result = await tronWeb.trx.getUnconfirmedBalance(address);
    if (!result) {
      return 0;
    }
    return formatToken({
      type: "devided",
      value: result ?? 0,
      length: 6,
    });
  } catch (error) {
    console.log("ERROR GET BALANCE TRX", error);
  }
}
export  function useGetBalanceTrx( address: string,) {
  const {tronWeb}=useTronContexts()
  const [balanceTrx,setBalanceTrx]=useState<number|null>(null)
  const getBalanceTrxAvailable=async( address: string) =>{
    try {
      const balanceUnconfirm = await tronWeb.trx.getUnconfirmedBalance(address);
      if(balanceUnconfirm){
        setBalanceTrx(formatToken({
          type: "devided",
          value: balanceUnconfirm ?? 0,
          length: 6,
        }))
        return;
       }
       setBalanceTrx(0)
    } catch (error) {
      console.log("ERROR GET BALANCE TRX", error);
    }
  }
 useEffect(()=>{
    if(address){
      getBalanceTrxAvailable(address)
    }
 },[address])
 return {balanceTrx,getBalanceTrxAvailable}
}