import TextToolTipRename from "components/core/TextTooltipRename";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import React, { useId } from "react";
import styled from "styled-components";
type RatioInputProps = {
  id: string;
  sizeText?: 0 | 1 | 21 | 2;
  disabled?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;
const RatioInput = ({
  id,
  sizeText = 1,
  disabled = false,
  ...props
}: RatioInputProps) => {
  const idInput = useId();
  return (
    <WrapSelect
      className={`imd-select ${disabled ? "imd-select--disabled" : ""}`}
    >
      <div className="select-item">
        <label
          htmlFor={id + idInput}
          className={` size-21 ${
            props.checked ? "color-white" : "color-light"
          }`}
        >
          <input
            {...props}
            type="radio"
            id={id + idInput}
            disabled={disabled}
          ></input>
          <TextToolTipRename
            text={id}
            tooltipContent={`${id}Tooltip`}
            sizeText={sizeText}
            className="ric-tooltip"
          />
        </label>
      </div>
    </WrapSelect>
  );
};

export default RatioInput;
const WrapSelect = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  &.imd-select {
    &--disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
  }
  .select-item {
    display: flex;
    align-items: center;

    label {
      margin-left: 8px;
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      .ric-tooltip {
        .ttr-img {
          width: 18px;
          height: 18px;
        }
      }
    }

    input {
      -webkit-appearance: none;
      /* Remove most all native input styles */
      appearance: none;
      /* For iOS < 15 */
      background-color: transparent;
      position: relative;
      width: 16px;
      height: 16px;
      margin: 0;
      margin-top: -3px;
      :before {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 999px;
        border: 1px solid var(--grey-400, #ccc);
        background: var(--secondary-input-bg, rgba(255, 255, 255, 0.06));
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      :after {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 999px;
        background: var(--primary-green-400);
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.5s ease;
      }
      :checked {
        :before {
          border: 1px solid var(--primary-green-400);
        }
        :after {
          opacity: 1;
        }
      }
    }
    :hover {
      cursor: pointer;
    }
  }
  ${breakpointsMedias.max1024} {
    gap: 5px;
    .select-item {
      label {
        margin-left: 0;
        gap: 8px;
        cursor: pointer;
        .ric-tooltip {
          .ttr-info-text {
            display: none;
          }
        }
      }
      input {
        width: 12px;
        height: 12px;
      }
    }
  }
`;
