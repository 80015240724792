import styled from "styled-components";
import { useLockBodyScroll } from "helpers/hooks/useLockBodyScroll";
import ModalWrap from "components/core/ModalWrap";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import { useEffect, useState } from "react";
import LoadingSpinner from "components/core/LoadingSpinner";

interface IModalSuccess {
  onClose: () => void;
}

type HoverItem = {
  x: number;
  y: number;
};

type DataRule = {
  headers: string[],
  data: any[],
}

const ModalRules = ({ onClose }: IModalSuccess) => {
  useLockBodyScroll();
  const { t } = useTranslation();
  const [dataRule, setDataRule] = useState<DataRule>({
    headers: [],
    data: []
  })
  const [loading, setLoading] = useState(true)
  const minBuyEnergyPrice2 = 40
  const [hoverItem, setHoverItem] = useState<HoverItem | null>(null);

  const convertMinPrice = (val: any) => {
    return Number((Math.ceil(Number(val) * 4) / 4).toFixed(2));
  };

  useEffect(() => {
    getDataRules()
  }, [])

  const getDataRules = () => {
    fetch("https://docs.google.com/spreadsheets/d/e/2PACX-1vTWykRAo1sOE7NqlioBp9uiTk1LVW09bp1hEWAltHUYskISGkVl9Kqm4O3kV1-1xIqVTuKE4622C2jN/pub?gid=0&single=true&output=csv").then(async (res) => {
      const result = await res.text()
      let resultData = csvFormat(result);
      setDataRule(resultData);
      setLoading(false)
    }).catch((er) => {

    })
  }
  const csvFormat = (csv: string) => {
    let lines = csv.split("\n");
    let result = [];
    let headers = lines[0].split(",") as string[];
    headers.shift()
    for (let i = 1; i < lines.length; i++) {
      let data = lines[i].split(",");
      let obj = {} as any;
      obj.amount = data[0];
      data.shift();
      obj.price = [...data]
      result.push(obj);
    }
    return {
      headers: headers,
      data: result
    } as DataRule
  }


  return (
    <ModalWrap onClose={onClose} showCloseBt={true} clickOutsideToClose={true}>
      <Wrap>
        <div className="modal-title">
          <span className="size-6 color-white">{t("minPrice")}</span>
        </div>
        {loading ? <div className="modal-loading"><LoadingSpinner /></div>
          : <div className="modal-body">
            <div className="scrollbar mb-table">
              <table>
                <thead>
                  <tr>
                    <th>
                      <div>
                        <span className="size-2 color-green th-1">
                          {t("amount")}
                        </span>
                        <span className="size-2 color-green th-2">
                          {t("duration")}
                        </span>
                      </div>
                    </th>
                    {dataRule.headers.map((item, index) => (
                      <th key={index}>
                        <div>
                          <span className="size-2 color-green text-center">
                            {item}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {dataRule.data.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="">
                          <span className="size-2 color-green">
                            {item.amount}
                          </span>
                        </div>
                      </td>
                      {item.price.map((item2: string, index2: number) => (
                        <td
                          key={index2}
                          onMouseEnter={() => {
                            setHoverItem({ x: index, y: index2 });
                          }}
                          onMouseLeave={() => {
                            setHoverItem(null);
                          }}
                          className={`${hoverItem &&
                            (hoverItem.x === index || hoverItem.y === index2) &&
                            "hover-td"
                            }`}
                        >
                          <div>
                            <span className="size-2 color-white">
                              {minBuyEnergyPrice2
                                ? numeral(
                                  // convertMinPrice(
                                  //   (item.priceBonus || 0) +
                                  //   (minBuyEnergyPrice2 * (100 - item2)) / 100
                                  // )
                                  convertMinPrice(item2)
                                ).format("0,0.00")
                                : "--"}
                            </span>
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>}
      </Wrap>
    </ModalWrap>
  );
};

export default ModalRules;

const Wrap = styled.div`
  width: calc(100vw - 80px);
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  z-index: 0;
  padding: 31px 48px;
  .modal-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  .modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
    > span {
      text-align: center;
    }
    > span:first-child {
      margin-bottom: 6px;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .mb-table {
      width: 100%;
      overflow: auto;
      position: relative;
      padding-bottom: 10px;
      > table {
        width: 100%;
        min-width: 660px;
        tr {
          border-bottom: 1px solid #282a28;
        }
        th {
          background: #414742;
          height: 50px;
          &:first-child {
            border-right: 1px solid #282a28;
            position: sticky;
            left: 0;
            z-index: 1;
            > div {
              padding: 0 15px;
              width: 100%;
              justify-content: space-between;
              background-image: linear-gradient(
                to bottom left,
                transparent calc(50% - 1px),
                #282a28,
                transparent calc(50% + 1px)
              );
              > span {
                &.th-1 {
                  top: 20px;
                  left: 15px;
                }
                &.th-2 {
                  bottom: 20px;
                  right: 15px;
                }
                ${breakpointsMedias.max667} {
                  &.th-1 {
                    top: 25px;
                    left: 10px;
                  }
                  &.th-2 {
                    bottom: 25px;
                    right: 10px;
                  }
                }
              }
            }
          }
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            position: relative;
            > span {
              position: absolute;
            }
          }
        }
        tbody {
          tr {
            &:last-child {
              border-bottom: none;
            }
            td {
              > div {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
              }
              &:first-child {
                box-sizing: border-box;
                border-right: 1px solid #282a28;
                background: #414742;
                position: sticky;
                left: 0;
              }
            }
            .hover-td {
              background: #414742;
            }
          }
        }
      }
    }
  }
  ${breakpointsMedias.max1199} {
    width: calc(100vw - 32px);
    padding: 31px 15px;
    .modal-title {
      margin-bottom: 24px;
    }
  }
`;
