import iconBandWidth from "assets/images/icon-bandwidth.svg";
import iconEnergy from "assets/images/icon-energy.svg";
import iconTRX from "assets/images/trx.png";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { formatCustom } from "helpers/formatNumber";
import React from "react";
import styled from "styled-components";
import { EResource } from "types";
type TTypeResource = keyof typeof EResource | "TRX" | "SUN";
type TSize = "tiny" | "normal" | "bold" | "big";
const ICON_RESOURCES: Partial<Record<TTypeResource, string>> = {
  banwidth: iconBandWidth,
  energy: iconEnergy,
  TRX: iconTRX,
};
const FONT_SIZE: Record<TSize, string> = {
  tiny: "size-1",
  normal: "size-21",
  bold: "size-23",
  big: "size-41",
};

interface ResourceValueTypeProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: TTypeResource;
  value: number;
  isSpacing?: boolean;
  size?: TSize;
  typeFormatValue?: string;
}

const ResourceValueType = ({
  type = "energy",
  value,
  isSpacing = true,
  size = "bold",
  typeFormatValue = "0,0.[00]",
  ...props
}: ResourceValueTypeProps) => {
  return (
    <Wrap
      className={`${props.className} ${size} ${isSpacing ? "spacing" : ""}`}
      {...props}
    >
      <span className={`${FONT_SIZE[size]} color-white`}>
        {value === -1 ? "--" : formatCustom(value, typeFormatValue)}
      </span>

      <img alt={type} src={ICON_RESOURCES[type]} />
    </Wrap>
  );
};

export default ResourceValueType;
const Wrap = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
  }
  &.spacing {
    img {
      margin-left: 5px;
    }
  }
  &.tiny {
    img {
      width: 18px;
      height: 18px;
    }
  }
  ${breakpointsMedias.max767} {
    img {
      width: 20px;
      height: 20px;
    }
    &.tiny {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
`;
