import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { breakpointsMedias } from 'configs/constants/breakpointMedias'
import TextToolTipRename from 'components/core/TextTooltipRename'
import { TxInfo } from './ActiveOrders'
import TableTransactionListItem from './TableTransactionListItem'

interface ITTL {
    data: TxInfo[],
}

const tooltipList = ["tx", "address", "delegated", "payment"] as string[]

const TableTransactionList = ({ data }: ITTL) => {
    const { t } = useTranslation();
    const colList = ["tx", "address", "delegated", "timeExpired"]

    return (
        <Wrap>
            <div className="ttl-wrap">
                <div className="ttl-table bg-gray scrollbar">
                    <table className='ttlt'>
                        <thead>
                            <tr>
                                {colList.map((item, index) => <th key={index} className={`col-name`}>
                                    <div className="" >
                                        {tooltipList.includes(item) ? <TextToolTipRename text={item} tooltipContent={t(`tt${item}`)} colorType='white' />
                                            : <span className={`size-21 color-white `}>{t(item)}</span>}
                                    </div>
                                </th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) =>
                                <TableTransactionListItem
                                    key={index} data={item}
                                />)}
                        </tbody>
                    </table>
                    {data.length <= 0 && <span className='size-22 color-white'>{t("noData")}</span>}
                </div>
            </div>
        </Wrap>
    )
}

export default TableTransactionList

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    .ttl-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        z-index: 0;
        height: fit-content;
        overflow: hidden;
        border-radius: 4px;
    } 
    .ttl-table {
        display: flex;
        flex-direction: column;
        /* padding-right: 12px; */
        width: 100%;
        overflow: auto;
        height: fit-content;
        max-height: 500px;
        position: relative;
        min-height: 100px;
        > span {
            margin: auto;
            text-align: center;
            padding: 30px;
        }
    }
    .ttlt {
        width: 100%;
        z-index: 0;
        table-layout: fixed;
        border-radius: 4px;
        min-width: 500px !important;
        th {
            height: 40px;
            text-align: left;
            position: sticky;
            top: 0;
            z-index: 1;
            background: #3A3F3B !important;
            width: 22% !important;
            > div {
                display: flex;
                align-items: center;
                width: fit-content;
                cursor: pointer;
                padding-left: 24px;
                height: 100%;
            }
            &:last-child {
                text-align: right;
                right: unset;
                z-index: 1;
                right: unset;
                > div {
                    margin-left: auto;
                    padding-right: 24px;
                }
            }
            &:first-child {
                position: sticky !important;
                left: 0 !important;
                z-index: 2 !important;
                width: 18% !important;
            }
            
            &:nth-child(4),
            &:nth-child(3),
            &:nth-child(2) {
                width: 18% !important;
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5)  {
                text-align: right;
                > div {
                    margin-left: auto;
                }
            }
            &:last-child {
                width: 25% !important;
                /* position: unset !important; */
                right: unset  !important;
                
            }
            &:nth-child(2) {
                text-align: left !important;
                > div {
                    margin-left: 0;
                    margin-right: auto;
                }
            }
        }
        td {
            &:first-child {
                position: sticky !important;
                left: 0  !important;
                z-index: 0  !important;
            }
            &:last-child {
                position: unset !important;
                right: unset  !important;
            }
        }
    }
    ${breakpointsMedias.max1199} {
        .ttlt {
            th {
                > div {
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    cursor: pointer;
                    padding-left: 12px;
                    height: 100%;
                }
                &:last-child {
                    right: unset;
                    > div {
                        padding-right: 12px;
                    }
                }
                &:nth-child(2) {
                    text-align: left;
                    > div {
                        margin-left: 0;
                        margin-right: auto;
                    }
                }
            }
        }
    }
`