import { useQuery } from "@apollo/client";
import { GET_BOT } from "configs/apolloSchema";
import { useMemo } from "react";

const useSystemSettings = () => {
  const { data, loading } = useQuery(GET_BOT);
  const {
    address,
    buyEnergyPrice,
    sellEnergyPrice,
    maxProfitShare,
    minBuyEnergyPrice,
  } = useMemo(() => {
    if (data?.rls_system_setting) {
      const {
        dynamic_buy_energy_price: buyEnergyPrice,
        dynamic_sell_energy_price: sellEnergyPrice,
        address_recharge_order: address,
        max_profit_share: maxProfitShare,
        dynamic_lowest_energy_price: minBuyEnergyPrice,
      } = data.rls_system_setting;
      return {
        address,
        buyEnergyPrice,
        sellEnergyPrice,
        maxProfitShare,
        minBuyEnergyPrice,
      };
    }
    return {
      address: "",
      buyEnergyPrice: 0,
      sellEnergyPrice: 0,
      maxProfitShare: 75,
      minBuyEnergyPrice: 0,
    };
  }, [data]);
  return {
    address,
    buyEnergyPrice,
    sellEnergyPrice,
    maxProfitShare,
    loading,
    minBuyEnergyPrice,
  };
};

export default useSystemSettings;
