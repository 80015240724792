import RatioCheck from "components/core/RatioCheck";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useWidthScreen } from "helpers/hooks/useWidthScreen";
import styled from "styled-components";
type OptionSortProps = {
  isChecked: boolean;
  onChangeOption: () => void;
};
const OptionsSort = ({ isChecked, onChangeOption }: OptionSortProps) => {
  const { width } = useWidthScreen();
  if (width > 667) {
    return null;
  }
  return (
    <Wrap>
      {/* <div>
        <TextToolTipRename
          text="optionSelectAll"
          tooltipContent="optionSelectAllTooltip"
        />
      </div> */}
      <div className="emc-modes">
        <div className="emc-item">
          <RatioCheck
            id={"selectionAll"}
            onCheckChange={onChangeOption}
            isChecked={isChecked}
            sizeText={1}
          />
        </div>
      </div>
    </Wrap>
  );
};

export default OptionsSort;
const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 40px;
  margin-bottom: 15px;
  .emc-modes {
    display: flex;
    align-items: center;
    gap: 30px;
  }
  ${breakpointsMedias.max1024} {
    gap: 15px;
    grid-template-columns: minmax(170px, 0.3fr) 1fr;
    .emc-modes {
      gap: 10px;
    }
  }
  ${breakpointsMedias.max767} {
    gap: 5px;
    grid-template-columns: minmax(0.4fr, 160px) 1fr;
    .emc-modes {
      gap: 5px;
    }
  }
`;
