export const configColor = {
  colorPrimary: "#fff",
  colorWhite: "#fff",
  colorGray: "#4e4b4b",
  gray1: "#4e4b4b",
  gray2: "#5f5b5b",
  gray3: "#999393",
  gray4: "#f3f3f3",
  purple: "#AF47D3",
  purple2: "#4E1BC1",
  pink: "#FFBEFA",
  green: "#59D87A",
  green2: "#1EAA43",
  green3: "#8CF5A8",
  colorRed: "#EA2B2B",
  colorBlue: "#4B9BF9",
  colorBlack: "#000000",
};
