import { useLazyQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import ResourceValueType from "components/core/ResourceValueType";
import { GET_ESTIMATE_EXTEND } from "configs/apolloSchema";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import dayjs from "dayjs";
import { formatToken } from "helpers/formatToken";
import { subStringAddress } from "helpers/subStringAddress";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCheckLg } from "react-icons/bs";
import styled from "styled-components";
import { TDelegateDetail, TExtendAndBuyInput } from "types";
import { useSystemExtendTeleContext } from "../../../contexts/SystemExtendTeleContext";
import { useTeleContext } from "../../../contexts/TeleContext";
import ModalExtendDetail from "./ModalExtendDetail";
import { TExtendDelegate } from "./TableExtend";
import ExtendDetail from "./showSelection/ExtendDetail";
import SelectionError from "./showSelection/SelectionError";
import SelectionPayout from "./showSelection/SelectionPayout";
import SelectionSkeleton from "./showSelection/SelectionSkeleton";
const TIME_ONE_DAY = 24 * 60 * 60 * 1000;
type TListSelectItem = {
  onCheckChange: () => void;
  isChecked: boolean;
  onChangeDelegatePrice: (id: string, price: number) => void;
};

// type TEstimateExtend = {
//   totalPayout: number;
//   delegateDetails: TDelegateDetail[];
//   extendFaildureReason: string[];
//   extraBuyFaildureReason: string[];
// };
const TableExtendItem = ({
  id,
  address,
  resourceAmount,
  unlockAt,
  price,
  maxExtendAmount,
  onCheckChange,
  isChecked = false,
  availableExtendAt,
  extendMinTimeTo,
  disabled = false,
  onChangeDelegatePrice,
}: TExtendDelegate & TListSelectItem) => {
  const [isLoading, setIsLoading] = useState(false);
  const { requester: ownerAddress } = useTeleContext();
  const { t } = useTranslation();
  const {
    modeGlobal,
    transactions,
    receiverAddress,
    onUpdateTransactionField,
    onUpdateFieldExtendEnergy,
    // totalPayout: totalPayoutGlobal,
    onLoadingStep,
  } = useSystemExtendTeleContext();
  const transactionFilterById = (id: string) => {
    return [...transactions].filter((item) => item.id === id)?.[0] || {};
  };
  const transaction =
    [...transactions].filter((item) => item.id === id)?.[0] ?? {};
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [arrExtendData, setArrExtendData] = useState<TExtendAndBuyInput[]>([]);
  useEffect(() => {
    if (transaction.id) {
      setIsLoading(true);
      const dataMapping: TExtendAndBuyInput[] = [...transactions].map(
        (item) => {
          const { address, energyExtended, duration, mode } = item;
          let is_extend = true;
          if (mode === "buyMoreOnly") {
            is_extend = false;
          }
          const extend_to = new Date().getTime() + duration * TIME_ONE_DAY;
          return {
            delegator: address,
            is_extend,
            extra_amount: energyExtended,
            extend_to,
          };
        }
      );
      const timerDelay = setTimeout(() => {
        setArrExtendData(dataMapping);
      }, 300);
      return () => {
        clearInterval(timerDelay);
      };
    } else {
      setArrExtendData([]);
    }
  }, [
    transaction.duration,
    transaction.mode,
    transaction.energyExtended,
    transactions.length,
    modeGlobal,
  ]);
  const [getEstimateExtend, { data, loading }] =
    useLazyQuery(GET_ESTIMATE_EXTEND);
  useEffect(() => {
    if (!!ownerAddress && !!receiverAddress && arrExtendData.length > 0) {
      const timer = setTimeout(() => {
        getEstimateExtend({
          variables: {
            requester: ownerAddress,
            resourceType: "ENERGY",
            receiver: receiverAddress,
            extendData: arrExtendData,
          },
          fetchPolicy: "no-cache",
        });
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [ownerAddress, receiverAddress, arrExtendData]);
  useEffect(() => {
    if (transactions.length === 0) {
      setIsLoading(false);
    }
    if (data?.rls_estimate_extend_and_buy_payout) {
      const {
        total_estimate_trx: totalPayout,
        total_extend_amount: totalExtendAmount,
        total_extra_buy_amount: totalExtraBuyAmount,
        estimate_data,
      } = data.rls_estimate_extend_and_buy_payout;
      //update total payout
      onUpdateFieldExtendEnergy({
        totalPayout,
        totalExtendAmount,
        totalExtraBuyAmount,
      });
      const { duration: durationTransaction } = transaction;

      //check change all data item when new data

      estimate_data.length > 0 &&
        [...estimate_data].forEach((estimateItem) => {
          if (Object.keys(estimateItem).length > 0) {
            const {
              address: idItem,
              extra_buy_failure_reasons: extraBuyFaildureReason,
              extend_failure_reasons: extendFaildureReason,
              extra_buy_price: price,
              estimate_extend_trx: estimateTRXExtend,
              estimate_extra_buy_trx: estimateTrxBuyMore,
              cal_extend_duration: durationExtend,
              cal_extra_buy_duration: durationExtraBuy,
              extra_buy_price: newPrice,
              extend_amount: extendAmount,
              extra_buy_amount: energyBuyMore,
            } = estimateItem;
            const transactionActiveItem = transactionFilterById(idItem);

            //detail delegate show modal
            const dateNow = new Date().getTime();
            let delegateExtend: TDelegateDetail[] = [];
            // let delegateDetails: TDelegateDetail[] = [];
            let totalPayoutTransaction = 0;

            if (+estimateTRXExtend > 0) {
              delegateExtend.push({
                id: "1",
                type: "extend",
                energy: extendAmount,
                timeFrom:
                  transaction.mode !== "buyMoreOnly"
                    ? transactionActiveItem?.unlockAt
                    : dateNow,
                timeTo: dateNow + durationTransaction * TIME_ONE_DAY,
                price,
                trxPaid: formatToken({
                  type: "devided",
                  value: estimateTRXExtend,
                }),
                duration: durationExtend,
              });
            }
            let delegateBuyMoreEnergy: TDelegateDetail[] = [];
            if (+estimateTrxBuyMore > 0) {
              let timeTo = dateNow + durationTransaction * TIME_ONE_DAY;
              if (transaction.mode === "buyMoreOnly") {
                timeTo = transactionActiveItem?.unlockAt;
              }
              delegateBuyMoreEnergy.push({
                id: "2",
                type: "buyMoreEnergy",
                energy: energyBuyMore,
                timeFrom: dateNow,
                timeTo,
                price,
                trxPaid: formatToken({
                  type: "devided",
                  value: estimateTrxBuyMore,
                }),
                duration: durationExtraBuy,
              });
            } else {
              delegateBuyMoreEnergy = [];
            }
            totalPayoutTransaction = new BigNumber(estimateTRXExtend)
              .plus(estimateTrxBuyMore)
              .toNumber();
            const delegateDetails = [
              ...delegateExtend,
              ...delegateBuyMoreEnergy,
            ];

            let isExtend = false;
            switch (true) {
              case (transaction.mode === "extendOnly" ||
                transaction.mode === "extendAndBuy") &&
                extendFaildureReason.length > 0: {
                isExtend = false;
                break;
              }
              case (transaction.mode === "buyMoreOnly" ||
                transaction.mode === "extendAndBuy") &&
                extraBuyFaildureReason.length > 0: {
                isExtend = false;
                break;
              }
              default:
                isExtend = true;
                break;
            }
            // console.log({ delegateDetails, transaction });
            //udpate transaction when data change
            onUpdateTransactionField(idItem, {
              infoTransaction: [...delegateDetails],
              newPrice,
              extendFaildureReason,
              extraBuyFaildureReason,
              payout: formatToken({
                type: "devided",
                value: totalPayoutTransaction,
              }),
              isExtend,
            });
            //change new price item table when price change

            onChangeDelegatePrice(idItem, newPrice);
          }
        });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [
    data,
    transaction.energyExtended,
    transaction.duration,
    transaction.mode,
    transactions.length,
    id,
  ]);

  //init time duration
  const initTimeDuration =
    Math.ceil(Math.abs((new Date().getTime() - unlockAt) / TIME_ONE_DAY)) || 1;
  const listExtendError = useMemo(() => {
    if (loading) {
      return [];
    }
    const { extendFaildureReason, extraBuyFaildureReason } = transaction;
    switch (true) {
      case (transaction.mode === "extendOnly" ||
        transaction.mode === "extendAndBuy") &&
        extendFaildureReason.length > 0: {
        return extendFaildureReason;
      }
      case (transaction.mode === "buyMoreOnly" ||
        transaction.mode === "extendAndBuy") &&
        extraBuyFaildureReason.length > 0: {
        return extraBuyFaildureReason;
      }
      default:
        return [];
    }
  }, [
    transaction.mode,
    transaction.extendFaildureReason,
    transaction.extraBuyFaildureReason,
    loading,
  ]);
  useEffect(() => {
    if (isLoading || loading) {
      onLoadingStep(true);
    } else {
      const timeout = setTimeout(() => {
        onLoadingStep(false);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [loading, isLoading]);
  return (
    <>
      <Wrap
        className={`${isChecked ? "show" : "hidden"} ${
          disabled ? "tei-disabled" : ""
        }`}
      >
        <td>
          <p className="size-1 color-gray tei-td-title">{t("delegate")}</p>
          <p className="size-1 color-white">
            {subStringAddress({ text: address, length: 5 })}
          </p>
        </td>
        <td>
          <p className="size-1 color-gray tei-td-title">{t("amount")}</p>
          <div className="tei-amount">
            <ResourceValueType value={resourceAmount} typeFormatValue="0,0" />
          </div>
        </td>
        <td>
          <p className="size-1 color-gray tei-td-title">{t("unlockAt")}</p>

          <p className="size-1 color-white nowrap">
            {dayjs(unlockAt).format("YYYY-MM-DD HH:mm")}
          </p>
        </td>
        <td>
          <p className="size-1 color-gray tei-td-title">{t("price.sun")}</p>

          <div className="tei-price">
            <p
              className={`size-1 color-white ${
                transaction.newPrice && transaction.newPrice !== price
                  ? "underline"
                  : ""
              } `}
            >
              {price}
            </p>
            <p className="size-1 color-white">
              {transaction.newPrice &&
                transaction.newPrice !== price &&
                transaction.newPrice}
            </p>
          </div>
        </td>
        <td className="selection">
          <p className="size-1 color-gray tei-td-title">{t("selection")}</p>

          <div
            className={`tei-check ${isChecked ? "tei-check--checked" : ""}`}
            onClick={onCheckChange}
          >
            {isChecked && (
              <span className="tei-check__icon">
                <BsCheckLg color="#fff" />
              </span>
            )}
          </div>
        </td>
        {isChecked && (
          <WrapDropdown className={`tei-dropdown `}>
            <div
              className={`tei-dropdown__amount tei-dropdown__item ${
                transaction.isBlockMode ? "tei-dropdown__disabled" : ""
              }`}
            >
              <ExtendDetail
                maxExtendAmount={maxExtendAmount}
                id={id}
                initTimeDuration={initTimeDuration}
                disabled={transaction.isBlockMode}
                duration={transaction.duration}
                initMode={transaction.mode}
                initEnergyExtend={transaction.energyExtended}
              />
            </div>
            <div className="tei-dropdown__payout tei-dropdown__item">
              {loading || isLoading ? (
                <SelectionSkeleton />
              ) : listExtendError.length > 0 ? (
                <SelectionError
                  errors={listExtendError}
                  availableExtendAt={availableExtendAt}
                  extendMinTimeTo={extendMinTimeTo}
                />
              ) : (
                <SelectionPayout
                  totalPayout={transaction.payout ?? 0}
                  loading={loading}
                  onShowDetail={() => setIsOpenDetail(true)}
                />
              )}
            </div>
          </WrapDropdown>
        )}
      </Wrap>
      {isOpenDetail && (
        <ModalExtendDetail
          delegateDetails={transaction.infoTransaction}
          onClose={() => setIsOpenDetail(false)}
        />
      )}
    </>
  );
};

export default TableExtendItem;
const Wrap = styled.tr`
  position: relative;

  &.tei-disabled {
    opacity: 0.5;
  }
  &.show {
    height: 210px;
  }
  td.selection {
    display: flex;
    justify-content: flex-end;
    ${breakpointsMedias.max667} {
      display: block;
    }
  }
  .tei-check {
    width: 20px;
    height: 20px;
    border: 1px solid var(--grey-400);
    cursor: pointer;
    position: relative;
    border-radius: 2px;
    &--checked {
      border: 1px solid var(--primary-green-400);
      background-color: var(--primary-green-400);
    }
    &__icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      svg {
        display: inline-flex;
      }
    }
  }
  .tei-detail {
    /* max-width: 60px; */
    button {
      min-width: 120px;
      padding: 4px 12px;
    }
  }
  .tei-price,
  .tei-amount {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .underline {
      text-decoration: line-through;
      color: var(--grey-600);
    }
  }
  td {
    &:not(:first-child) {
      text-align: center;
    }
    .tei-td-title {
      display: none;
    }
  }
  ${breakpointsMedias.max667} {
    margin-bottom: 15px;
    padding-bottom: 15px;
    &.hidden {
      /* margin-bottom: 20px; */
    }
    &.show {
      height: auto;
      margin-bottom: 228px;
    }
    &:last-child {
      &.show {
        margin-bottom: 214px;
      }
    }
    .tei-price,
    .tei-amount {
      justify-content: flex-start;
    }
    td {
      &:not(:first-child) {
        text-align: left;
      }
      .tei-td-title {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
  ${breakpointsMedias.max412} {
    &.show {
      height: auto;
      margin-bottom: 246px;
    }
    &:last-child {
      &.show {
        margin-bottom: 246px;
      }
    }
  }
`;

const WrapDropdown = styled.div`
  position: absolute;
  top: 46px;
  left: 0;
  width: 100%;
  background-color: var(--secondary-button-bg-secondary);
  padding: 16px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: minmax(100px, 1fr) minmax(100px, 0.25fr);
  gap: 25px;
  .tei-detail {
    display: flex;
    justify-content: center;
  }

  .tei-dropdown {
    &__disabled {
      opacity: 0.5;
    }
    &__amount {
      border-right: 1px solid var(--grey-600);
      padding-right: 25px;
    }
    &__payout {
    }
  }
  ${breakpointsMedias.max1024} {
    grid-template-columns: 1fr;
    gap: 15px 0;
    padding: 10px 5px;

    .tei-dropdown {
      &__item {
        /* max-width: calc(100vw - 60px); */
      }
      &__amount {
        border-right: 0;
        padding-right: 0;
      }
      &__payout {
      }
    }
  }
  ${breakpointsMedias.max667} {
    grid-template-columns: 1fr;
    gap: 20px 0;
    padding: 10px 10px;
    top: 98px;
    .tei-dropdown {
      &__item {
        max-width: calc(100vw - 60px);
      }
      &__amount {
      }
      &__payout {
        border-top: 1px solid var(--grey-600);
        padding-top: 15px;
      }
    }
  }
`;
