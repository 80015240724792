import RatioCheck from "components/core/RatioCheck";
import TextToolTipRename from "components/core/TextTooltipRename";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useDebounce } from "helpers/hooks/useDebounce";
import { useWidthScreen } from "helpers/hooks/useWidthScreen";
import { useEffect, useState } from "react";
import styled from "styled-components";

import {
  TModeExtendGlobal,
  useSystemExtendTeleContext,
} from "../../../contexts/SystemExtendTeleContext";
const listModeExtend: TModeExtendGlobal[] = ["sameExtendOnly", "sameDuration"];

const ExtendMode = () => {
  const { onSetModeGlobal, modeGlobal } = useSystemExtendTeleContext();
  const [mode, setMode] = useState<TModeExtendGlobal[]>(modeGlobal);
  const onChangeMode = (e: TModeExtendGlobal) => {
    if (mode.includes(e)) {
      setMode(mode.filter((item) => item !== e));
      return;
    }
    setMode([...mode, e]);
  };
  const modeDebounce = useDebounce(mode, 200);
  useEffect(() => {
    onSetModeGlobal(modeDebounce);
  }, [modeDebounce]);
  const { isMobile } = useWidthScreen();
  return (
    <Wrap>
      <div>
        <TextToolTipRename
          text="useSameSettings"
          tooltipContent="useSameSettingsTooltip"
        />
      </div>
      <div className="emc-modes">
        {listModeExtend.map((item, index) => (
          <div key={index} className="emc-item">
            <RatioCheck
              id={item}
              onCheckChange={() => onChangeMode(item)}
              isChecked={mode.includes(item)}
              sizeText={isMobile ? 1 : 2}
            />
          </div>
        ))}
      </div>
    </Wrap>
  );
};

export default ExtendMode;
const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  align-items: center;
  gap: 40px;
  margin-bottom: 15px;
  .emc-modes {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
  }
  ${breakpointsMedias.max1024} {
    gap: 15px;
    grid-template-columns: minmax(170px, 0.3fr) 1fr;
    .emc-modes {
      gap: 10px;
    }
  }
  ${breakpointsMedias.max767} {
    gap: 5px;
    grid-template-columns: 1fr;
    .emc-modes {
      gap: 5px;
    }
  }
`;
