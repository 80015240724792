import styled from "styled-components";
import { useLockBodyScroll } from "helpers/hooks/useLockBodyScroll";
import { useTranslation } from "react-i18next";
import ModalWrap from "components/core/ModalWrap";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { subStringAddress } from "helpers/subStringAddress";
import { configColor } from "configs/constants/configColor";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useWidthScreen } from "helpers/hooks/useWidthScreen";
import { TxInfo } from "pages/energy/right/ActiveOrders";
import TableTransactionList from "pages/energy/right/TableTransactionList";

dayjs.extend(relativeTime);

interface IModalSuccess {
  onClose: () => void;
  data: TxInfo[];
  orderId: string;
  target: string;
}

const ModalTransactionList = ({
  onClose,
  data,
  orderId,
  target,
}: IModalSuccess) => {
  useLockBodyScroll();
  const { t } = useTranslation();
  //self link when mobile
  const { isMobile } = useWidthScreen();

  return (
    <ModalWrap onClose={onClose} showCloseBt={true} clickOutsideToClose={true}>
      <Wrap>
        <div className="modal-title">
          <span className="size-6 color-white">{t("orderDetail")}</span>
          <div className="mt-row">
            <span className="size-21 color-white-2">ID</span>
            <span className="size-22 color-white">#{orderId}</span>
          </div>
          <div className="mt-row">
            <span className="size-21 color-white-2 mtr-target">
              {t("target")}{" "}
            </span>
            <a
              target={isMobile ? "_self" : "_blank"}
              rel="noreferrer"
              href={`${process.env.REACT_APP_SCAN_NETWORK}#/address/${target}`}
              className="size-22 color-white"
            >
              {subStringAddress({ text: target || "--", length: 5 })}
            </a>
          </div>
        </div>
        <div className={`modal-body text-center`}>
          <div className="mb-title">
            <span className="size-31 color-white">
              {t("transactionInfor")}
            </span>
          </div>
          <TableTransactionList data={data} />
        </div>
      </Wrap>
    </ModalWrap>
  );
};

export default ModalTransactionList;

const Wrap = styled.div`
  padding: 31px 71px;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 32px);
  max-width: 968px;
  .modal-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
    justify-content: center;
    border-bottom: 1px solid rgba(252, 252, 252, 0.2);
    padding-bottom: 3px;
    > span {
      text-align: center;
      margin-bottom: 40px;
    }
    .mt-row {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 24px;
      justify-content: space-between;
      a {
        text-decoration: underline;
        &:hover {
          color: ${configColor.colorBlue};
        }
      }
      .mtr-target {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-right: 20px;
        > svg {
          cursor: pointer;
        }
      }
      .mtr-input {
        max-width: 70%;
        input {
          width: 100%;
          text-align: right;
        }
      }
    }
    .mt-bt {
      justify-content: flex-end;
      gap: 16px;
      > button {
        max-width: 100px;
        height: 36px;
      }
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    .mb-title {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > span {
        text-align: left;
      }
      > button {
        width: 150px;
        height: 40px;
      }
    }
  }
  ${breakpointsMedias.max1199} {
    padding: 31px 15px;
    max-width: 700px;
    .modal-title {
      margin-bottom: 16px;
      padding-bottom: 0;
      > span {
        margin-bottom: 24px;
      }
      .mt-row {
        margin-bottom: 16px;
      }
    }
  }
`;
