import imgNoData from "assets/images/img-no-data.png";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
type TSubText = "sell" | "order" | "reward" | "null";
type TSize = "tiny" | "normal";
interface IWI {
  className?: string;
  subText?: TSubText;
  size?: TSize;
}

const textSub: Record<TSubText, string> = {
  sell: "allFilled",
  order: "letStartOrder",
  reward: "letShareRef",
  null: "",
};
const sizeText: Record<TSize, string> = {
  normal: "size-4",
  tiny: "size-2",
};
const NoData = ({ className, subText = "order", size = "normal" }: IWI) => {
  const { t } = useTranslation();
  return (
    <Wrap className={`${className} ${size}`}>
      <div className="ndc-image">
        <img src={imgNoData} alt="" />
      </div>
      <span className={`${sizeText[size]} color-white text-no text-center`}>
        {t("noData")}
      </span>
      <span className="size-21 color-white text-center">
        {subText ? t(textSub[subText]) : ""}
      </span>
    </Wrap>
  );
};

export default NoData;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
  padding: 30px;
  .ndc-image {
    width: 320px;
    height: 320px;
    display: flex;
    margin-bottom: 40px;
    > img {
      width: 100%;
      height: auto;
    }
  }
  .text-no {
    margin-bottom: 16px;
  }
  &.tiny {
    .ndc-image {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
    }
  }
  ${breakpointsMedias.max1199} {
    padding: 24px;
    .ndc-image {
      width: 170px;
      height: 170px;
      display: flex;
      margin-bottom: 32px;
      > img {
        width: 100%;
        height: auto;
      }
    }
    .text-no {
      margin-bottom: 12px;
      text-align: center;
    }
  }
`;
