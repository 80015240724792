import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TTransactionDetail } from "types";
import { useSystemExtendTeleContext } from "../../../contexts/SystemExtendTeleContext";
import TableEndItem from "./TableEndItem";
const TIME_ONE_DAY = 24 * 60 * 60 * 1000;

const theadTitle = [
  "delegate",
  "resourceAmount",
  "price",
  "duration",
  "expiredAt",
  "payout",
];

const TableEnd = () => {
  const { transactions } = useSystemExtendTeleContext();
  const { t } = useTranslation();
  const orders = [...transactions].reduce(
    (acc: TTransactionDetail[], transaction) => {
      const { infoTransaction, id, address, unlockAt, mode, duration } =
        transaction;
      const timeNow = new Date().getTime();
      if (infoTransaction.length > 0) {
        const listDataInfoMapping: TTransactionDetail[] = [
          ...infoTransaction,
        ].map((item) => {
          const {
            energy,
            price,
            trxPaid,
            type,
            duration: durationDelegate,
          } = item;
          let timeUnlock = timeNow + duration * TIME_ONE_DAY;
          if (type === "buyMoreEnergy" && mode === "buyMoreOnly") {
            timeUnlock = unlockAt;
          }
          let durationTime = new BigNumber(durationDelegate * 1000)
            .div(TIME_ONE_DAY)
            .toNumber();
          return {
            id: id,
            address: address,
            amount: energy,
            duration: durationTime,
            unlockAt: timeUnlock,
            price: price,
            payout: trxPaid,
          };
        });
        return [...acc, ...listDataInfoMapping];
      }
      return [...acc];
    },
    []
  );
  return (
    <Wrap className="scrollbar2">
      <table>
        <thead>
          <tr>
            {theadTitle.map((item, index) => (
              <td key={index}>
                <p className="size-1 color-white nowrap">{t(item)}</p>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {orders.length ? (
            orders.map((item, index) => <TableEndItem key={index} {...item} />)
          ) : (
            <tr>
              <td className="size-1 color-white">No data</td>
            </tr>
          )}
        </tbody>
      </table>
    </Wrap>
  );
};

export default TableEnd;
const Wrap = styled.div`
  max-height: 430px;
  overflow: auto;
  display: block;
  table {
    display: table;
    width: 100%;
    thead {
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: #222722;
    }
    tr {
      /* border-bottom: 1px solid var(--grey-600); */
      border-bottom: 1px solid #383838;
      td {
        padding: 10px 15px;
      }
    }
  }
`;
