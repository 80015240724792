import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks';
import { useWalletModal } from '@tronweb3/tronwallet-adapter-react-ui';
import { notifyToastify } from 'helpers/ultilities/notifyToastify';
import { useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';

interface IWalletAction {
    changeShowAction: (e: boolean) => void,
    showAccount: () => void
}

export const WalletAction = ({ changeShowAction, showAccount }: IWalletAction) => {
    const { address, disconnect } = useWallet();
    const { setVisible } = useWalletModal();
    const [copied, setCopied] = useState(false);
    const ref = useRef<HTMLUListElement>(null);

    function copyData(copyText: string) {
        const textArea = document.createElement('textarea');
        textArea.value = copyText;
        document.body.appendChild(textArea);
        textArea.select();
        textArea.style.position = 'fixed';
        textArea.style.top = '-9999px';
        textArea.style.left = '-9999px';
        document.execCommand('copy');
        textArea.blur();
    }

    const copyAddress = () => {
        if (address) {
            copyData(address);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
                // changeShowAction(false);
            }, 400);
        }
    };
    function changeWallet() {
        setVisible(true);
    }

    const handleDisconnect = useCallback(
        async function () {
            try {
                await disconnect();
            } catch (error: any) {
                notifyToastify({ text: error?.message, type: "error" })
            }
            changeShowAction(false);
        },
        [disconnect]
    );

    useEffect(() => {
        const listener = (event: Event) => {
            let idTarget = (event.target as any).id
            const node = ref.current;
            if (!node || node.contains(event.target as Node) || idTarget === "walletButton") return;
            changeShowAction(false);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref]);

    return (
        <div
            className="adapter-dropdown-collapse"
        >
            <ul ref={ref} className="adapter-dropdown-list" role="menu">
                {address && (
                    <li onClick={showAccount} className="adapter-dropdown-list-item" role="menuitem">
                        Account Info
                    </li>
                )}
                {address && (
                    <li onClick={copyAddress} className="adapter-dropdown-list-item" role="menuitem">
                        {copied ? 'Copied' : 'Copy address'}
                    </li>
                )}
                <li onClick={changeWallet} className="adapter-dropdown-list-item" role="menuitem">
                    Change wallet
                </li>
                {address && (
                    <li onClick={handleDisconnect} className="adapter-dropdown-list-item" role="menuitem">
                        Disconnect
                    </li>
                )}
            </ul>
        </div>
    );
};
