import React, { useContext, useReducer, useState } from "react";
const MIN_ADDITIONAL_AMOUNT = 100000;
export type TDelegatesData = {
  id: string;
  address: string;
  isExtendable: boolean;
  price: number;
  amount: number;
  availableEnergy: number;
  unlockAt: number;
  minBuyMorePrice: number;
  maxBuyMorePrice: number;
  isSuggestExtend: boolean;
  suggestBuyMoreAmount: number;
};
export type TDelegateDataAdditional = {
  amountBuyMore?: number;
} & TDelegatesData;
type TQuickExtend = {
  price: string | number;
  duration: string | number;
  amountAdditional: string | number;
  counterExtend: number;
  delegates: TDelegatesData[];
  delegatesAdditional: TDelegateDataAdditional[];
};
type TFieldQuickExtend = Partial<TQuickExtend>;

export const QuickExtendContext = React.createContext({
  delegates: [] as TDelegatesData[],
  amountAdditional: 0 as string | number,
  counterExtend: 1,
  delegatesAdditional: [] as TDelegateDataAdditional[],
  onAddDelegates: (e: TDelegatesData[], amount: number) => {},
  onUpdateExtendField: (e: TFieldQuickExtend) => {},
  onUpdateAdditionalAmount: (e: number) => {},
  onUpdateAdditionalAmountSuggest: (e: number) => {},
});

const QuickExtendContextWrap = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initialReducer: TQuickExtend = {
    price: "",
    duration: "",
    amountAdditional: "",
    counterExtend: 1,
    delegates: [],
    delegatesAdditional: [],
  };
  type TActionExtend =
    | {
        type: "ADD_DELEGATES";
        delegates: TDelegatesData[];
        amount: number;
      }
    | {
        type: "QUICK_CHANGE_FIELD";
        field: TFieldQuickExtend;
      }
    | {
        type: "QUICK_CHANGE_AMOUNT";
        amount: number;
        // delegates: TDelegatesData[];
      }
    | {
        type: "QUICK_CHANGE_AMOUNT_SUGGEST";
        amount: number;
      };
  const reducer = (state: TQuickExtend, action: TActionExtend) => {
    switch (action.type) {
      case "ADD_DELEGATES": {
        const { delegates, amount } = action;
        if (amount === 0) {
          return {
            ...state,
            delegates: [...delegates],
            amountAdditional: 0,
            counterExtend: 1,
            delegatesAdditional: [],
          };
        }
        const newDelegates = [...delegates].filter(
          (item) => item.isSuggestExtend === true
        );
        return {
          ...state,
          amountAdditional: amount,
          delegates: [...delegates],
          delegatesAdditional: [...newDelegates],
          counterExtend: newDelegates.length,
        };
        // return {
        //   ...state,
        //   delegates: [...delegates],
        // };
      }
      case "QUICK_CHANGE_FIELD": {
        return {
          ...state,
          ...action.field,
        };
      }
      case "QUICK_CHANGE_AMOUNT": {
        const { amount } = action;
        //filter delegates have availableEnergy >= MIN_ADDITIONAL_AMOUNT and sort by availableEnergy => match avaiableEnergy from low to big
        const prevDelegates = [...state.delegates]
          .filter(
            (item: TDelegatesData) =>
              item.availableEnergy >= MIN_ADDITIONAL_AMOUNT
          )
          .sort(
            (a: TDelegatesData, b: TDelegatesData) =>
              a.availableEnergy - b.availableEnergy
          );
        if (amount === 0) {
          return {
            ...state,
            amountAdditional: amount,
            counterExtend: 1,
            delegatesAdditional: [],
          };
        }
        let totalAmount = amount;
        //get data match conditions remaining amount
        const newDelegates: TDelegateDataAdditional[] = prevDelegates.reduce(
          (acc: TDelegateDataAdditional[], item: TDelegatesData) => {
            const remainingAmount = totalAmount - item.availableEnergy;
            const additionalAmount = Math.max(
              item.availableEnergy,
              MIN_ADDITIONAL_AMOUNT
            );
            if (totalAmount > 0 && remainingAmount < 0) {
              const usedAmount = Math.min(totalAmount, additionalAmount); // Use the minimum of totalAmount and additionalAmount
              totalAmount -= usedAmount;

              return [
                ...acc,
                {
                  ...item,
                  amountBuyMore: usedAmount,
                },
              ];
            }

            if (remainingAmount > 0) {
              const usedAmount = Math.min(totalAmount, additionalAmount); // Use the minimum of totalAmount and additionalAmount
              totalAmount -= usedAmount;

              return [
                ...acc,
                {
                  ...item,
                  amountBuyMore: usedAmount,
                },
              ];
            }

            if (totalAmount > 0 && remainingAmount < MIN_ADDITIONAL_AMOUNT) {
              const usedAmount = Math.min(totalAmount, additionalAmount); // Use the minimum of totalAmount and additionalAmount

              totalAmount -= usedAmount;

              return [
                ...acc,
                {
                  ...item,
                  amountBuyMore: usedAmount,
                },
              ];
            }

            return [...acc];
          },
          []
        );
        return {
          ...state,
          amountAdditional: amount,
          delegatesAdditional: [...newDelegates],
          counterExtend: newDelegates.length,
        };
      }
      case "QUICK_CHANGE_AMOUNT_SUGGEST": {
        const { amount } = action;
        const prevDelegates = [...state.delegates];
        if (amount === 0) {
          return {
            ...state,
            amountAdditional: 0,
            counterExtend: 1,
            delegatesAdditional: [],
          };
        }
        const newDelegates = prevDelegates.filter(
          (item) => item.isSuggestExtend === true
        );
        return {
          ...state,
          amountAdditional: amount,
          delegatesAdditional: [...newDelegates],
          counterExtend: newDelegates.length,
        };
      }
      default: {
        return { ...state };
      }
    }
  };
  const [state, dispatch] = useReducer(reducer, initialReducer);
  const onAddDelegates = (delegates: TDelegatesData[], amount: number) => {
    dispatch({
      type: "ADD_DELEGATES",
      delegates,
      amount,
    });
  };
  const onUpdateExtendField = (field: TFieldQuickExtend) => {
    dispatch({
      type: "QUICK_CHANGE_FIELD",
      field,
    });
  };
  const onUpdateAdditionalAmount = (amount: number) => {
    dispatch({
      type: "QUICK_CHANGE_AMOUNT",
      amount,
    });
  };
  const onUpdateAdditionalAmountSuggest = (amount: number) => {
    dispatch({
      type: "QUICK_CHANGE_AMOUNT_SUGGEST",
      amount,
    });
  };
  const { delegates, amountAdditional, counterExtend, delegatesAdditional } =
    state;
  return (
    <QuickExtendContext.Provider
      value={{
        delegates,
        amountAdditional,
        counterExtend,
        delegatesAdditional,
        onAddDelegates,
        onUpdateExtendField,
        onUpdateAdditionalAmount,
        onUpdateAdditionalAmountSuggest,
      }}
    >
      {children}
    </QuickExtendContext.Provider>
  );
};

export default QuickExtendContextWrap;
export const useQuickExtendContext = () => useContext(QuickExtendContext);
