import { breakpointsMedias } from 'configs/constants/breakpointMedias'
import { configColor } from 'configs/constants/configColor'
import { MdDone } from 'react-icons/md'
import styled from 'styled-components'

interface ICheckBox {
    className?: string
    checked: boolean,
    onCheck: () => void,
    circle?: boolean
}
const CheckBox = ({ className, checked, onCheck, circle }: ICheckBox) => {

    return (
        <Wrap className={`${circle ? "checkbox-circle" : ""} ${checked ? "checked" : ""} ${className}`} onClick={onCheck}>
            {/* {checked && <div className='cb-checked'></div>} */}
            {checked && <MdDone size={23} color="#FFFF" />}
        </Wrap>
    )
}

export default CheckBox

const Wrap = styled.div`
    border: 2px solid #FFFFFF;
    border-radius: 4px;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 2px;
    &.checkbox-circle {
        border-radius: 50%;
        .cb-checked {
            border-radius: 50%;
        }
    }
    .cb-checked {
        background: #AF47D3;
        border-radius: 2px;
        width: 10px;
        height: 10px;
    }
    &.checked {
        border: none;
        background-color: ${configColor.green2};
    }
`