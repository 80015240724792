import iconLogoMini from "assets/images/logo-mini.png";
import Button from "components/core/Button";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { TronContexts } from "contexts/TronContexts";
import { subStringAddress } from "helpers/subStringAddress";
import styled from "styled-components";
import ModalUser from "./ModalUser";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  WalletActionButton
} from "@tronweb3/tronwallet-adapter-react-ui";
import { WalletAction } from "./WalletAction";
import { configColor } from "configs/constants/configColor";

const fullNode = process.env.REACT_APP_NODE_TRON || "";
const FULLNODE_TESTNETS = [
  "https://api.nileex.io",
  "https://api.shasta.trongrid.io",
  "https://nile.trongrid.io"
];

const WalletButton = () => {
  const { t } = useTranslation();
  const { address, connected, wallet } = useWallet();

  const { isCorrectNode, changeCorrectNode } = useContext(TronContexts);

  const [showAction, setShowAction] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const changeShowAction = (e: boolean) => {
    setShowAction(e);
  };

  const showAccount = () => {
    setShow(true);
  };

  useEffect(() => {
    if (wallet?.adapter) {
      if (wallet.adapter.name !== "WalletConnect") {
        checkNode();
      }
      wallet.adapter.on("chainChanged", (data) => {
        checkNode();
      });
      wallet.adapter.on("stateChanged", (state) => {
        if (wallet.adapter.name === "WalletConnect" && state === "Connected") {
          checkNode();
        }
      });
    }
  }, [wallet]);

  const checkNode = async () => {
    let adapterInfo = await (wallet?.adapter as any).network();
    let node = adapterInfo.fullNode;
    // console.log(node)
    if (!node) {
      return;
    }
    if (FULLNODE_TESTNETS.includes(fullNode)) {
      changeCorrectNode(true);
      return;
    }
    if (FULLNODE_TESTNETS.includes(node)) {
      changeCorrectNode(false);
      return;
    }
    changeCorrectNode(true);
  };

  // console.log(isCorrectNode)

  return (
    <>
      <Wrap className="bt-wallet">
        {connected ? (
          isCorrectNode ? (
            <div
              onClick={() => {
                setShowAction(!showAction);
              }}
              className="btw"
              id="walletButton"
            >
              <div className="btw-img">
                <img src={wallet?.adapter.icon || iconLogoMini} alt="" id="walletButton" />
              </div>
              <span className="size-2 color-white" id="walletButton">
                {subStringAddress({ text: address || "", length: 6 })}
              </span>
              {showAction && (
                <WalletAction
                  changeShowAction={changeShowAction}
                  showAccount={showAccount}
                />
              )}
            </div>
          ) : (
            <div
              onClick={() => {
                setShowAction(!showAction);
              }}
              className="btw"
            >
              <span className="size-2 color-red">{t("wrongNetwork")}</span>
            </div>
          )
        ) : (
          <WalletActionButton className="adapter-bt">
            <Button
              // size="big"
              text={t("connectWallet")}
              icon={"wallet"}
            />
          </WalletActionButton>
        )
        }
        {show && (
          <ModalUser
            onClose={() => {
              setShow(false);
            }}
          />
        )}
      </Wrap>
    </>
  );
};

export default WalletButton;

const Wrap = styled.div`
  margin-left: auto;
  cursor: pointer;
  .btw {
    background: #2d312ecc;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    position: relative;
    .btw-img {
      width: 24px;
      height: 24px;
      display: flex;
      margin-right: 12px;
      > img {
        width: 100%;
        height: auto;
      }
    }
    .adapter-dropdown-collapse {
      > ul {
        margin: 0;
        margin-left: auto;
        border: 1px solid ${configColor.gray1};
      }
    }
  }
  .adapter-bt {
    width: fit-content;
    height: fit-content;
    background: none;
    padding: 0;
    margin: 0;
    > i {
      display: none;
    }
  }
  ${breakpointsMedias.max1199} {
    margin-right: 30px;
    .btw {
      padding: 4px 10px;
      .btw-img {
        width: 32px;
        height: 32px;
      }
    }
  }
  ${breakpointsMedias.max767} {
    margin-right: auto;
  }
  ${breakpointsMedias.min1360} {
  }
`;
