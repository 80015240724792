import BigNumber from "bignumber.js";
interface FormatTokenProps {
  type: "multiplied" | "devided";
  value: any;
  length?: number;
}
export const formatToken = ({
  type = "devided",
  value,
  length = 6,
}: FormatTokenProps) => {
  BigNumber.config({
    ROUNDING_MODE: BigNumber.ROUND_DOWN,
    POW_PRECISION: 100,
    DECIMAL_PLACES: 20,
  });
  let formatNumber = 0;
  // console.log('sss', tronWeb.toBigNumber(value.toString()).toNumber())
  if (value === null || value === undefined) {
    return 0;
  }
  if (type === "multiplied") {
    formatNumber = new BigNumber(value.toString())
      .multipliedBy(new BigNumber(10).pow(length))
      .toNumber();
  } else {
    formatNumber = new BigNumber(value.toString())
      .dividedBy(new BigNumber(10).pow(length))
      .toNumber();
  }
  return formatNumber;
};
