import React from "react";
import styled from "styled-components";
import iconError from "assets/images/icon-err.png";
import iconWarning from "assets/images/icon-warning.svg";
type TWarning = "warning" | "error";
interface WarningAlertProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: "normal";
  type?: TWarning;
  children: React.ReactNode;
}
type TImageType = {
  [T in TWarning]: string;
};
const IMAGE_TYPE: TImageType = { error: iconError, warning: iconWarning };
const WarningAlert = ({
  size = "normal",
  type = "error",
  children,
  ...props
}: WarningAlertProps) => {
  return (
    <Wrap className={`${size} ${props.className}`} {...props}>
      <img src={IMAGE_TYPE[type]} alt="error" />
      <div>{children}</div>
    </Wrap>
  );
};

export default WarningAlert;
const Wrap = styled.div`
  display: flex;
  align-items: start;
  margin-top: 8px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;
