import numeral from "numeral";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { configColor } from "configs/constants/configColor";
import dayjs from "dayjs";
import { OrderItem } from "./MyOrders";
import BigNumber from "bignumber.js";
import iconTrx from "assets/images/trx.png";
import { calDuration, calDuration2 } from "helpers/energy/calDuration";
import { AiOutlineEye } from "react-icons/ai";
import iconEnergy from "assets/images/icon-energy.png";
import iconDone from "assets/images/icon-done.png";
import iconDoneExtend from "assets/images/icon-done-extend.png";
import iconDoneBuyMore from "assets/images/icon-done-buy-more.png";
import iconClose from "assets/images/icon-close-red.png";
import iconClose2 from "assets/images/icon-close-hover.png";
import { useWidthScreen } from "helpers/hooks/useWidthScreen";
import { convertSecondToTimer } from "helpers/ultilities/convertSecondToTimer";
import { notifyToastify } from "helpers/ultilities/notifyToastify";
import { MdOutlineCancel } from "react-icons/md";
import ModalTransactionList from "./ModalTransactionList";
import ModalCancel from "./ModalCancel";

interface IPositionTableItem {
  data: OrderItem;
  refreshData: () => void;
}

const remain5m = 60 * 1000 * 5;
const remainCancel = Number(process.env.REACT_APP_REMAIN_CANCEL);
// const TIME_MS = 1000;

// const remain5m = 60

const MyOrdersItem = ({
  data,
  refreshData,
}: IPositionTableItem) => {
  const { t } = useTranslation();
  const { width } = useWidthScreen();
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [fullfilled, setFullfilled] = useState<number>(0);
  // const [timeRemain, setTimeRemain] = useState<number>(0);
  useEffect(() => {
    setFullfilled(data.fulfilled || 0);
  }, [data]);

  const onCancel = (e: any) => {
    e.stopPropagation();
    let now = Date.now();
    let createAt = new Date(data.create_at || 0).getTime();
    let timeRemain = now - createAt;
    if (timeRemain > (data.fulfilled ? remainCancel : remain5m)) {
      setShowCancel(true);
    } else {
      // console.log((data.fulfilled ? remainCancel : remain5m) - timeRemain)
      const { minutes, second } = convertSecondToTimer(
        ((data.fulfilled ? remainCancel : remain5m) - timeRemain) / 1000
      );
      notifyToastify({
        type: "error",
        text: t("breakCancelOrder", { minutes, second }),
      });
      return;
    }
  };
  const iconOrderDone = () => {
    switch (data.type) {
      case "EXTEND":
        return iconDoneExtend;
      case "EXTRA_BUY":
        return iconDoneBuyMore;
      default:
        return iconDone;
    }
  };

  return (
    <>
      <Wrap
        className={``}
        onClick={() => {
          // if (width < 1200)
          setShowDetail(true);
        }}
      >
        {width >= 1200 && (
          <td className="td-info">
            <div>
              <div
                className="cursor-pt"
                onClick={() => {
                  setShowDetail(true);
                }}
              >
                <AiOutlineEye size={24} color="#FFFF" />
              </div>
            </div>
          </td>
        )}
        {!(width < 1200) && (
          <td className="td-time">
            <div>
              <span className={`size-21 color-white`}>
                {data.create_at
                  ? dayjs(new Date(data.create_at).getTime()).format(
                    "MM/DD/YYYY HH:mm"
                  )
                  : "--"}
              </span>
            </div>
          </td>
        )}
        <td className="td-resource td-right">
          <div>
            <span className={`size-2 color-white`}>
              {data.resource_amount !== null
                ? numeral(data.resource_amount).format(
                  width < 1200 ? "0,0a" : "0,0"
                )
                : "--"}
              <img src={iconEnergy} alt="" className="img-energy" />
            </span>
            {data.is_lock && (
              <span className={`size-21 color-white`}>
                /{" "}
                {width < 1200
                  ? calDuration2(data.duration_sec)
                  : `${calDuration(data.duration_sec).value} ${t(
                    calDuration(data.duration_sec).unit
                  )}`}
              </span>
            )}
          </div>
        </td>
        <td className="td-amount td-right">
          <div>
            <span className={`size-21 color-white`}>
              {data.price !== null
                ? numeral(data.price).format("0,0.[00]")
                : "--"}{" "}
              SUN
            </span>
          </div>
        </td>
        <td className="td-amount td-right">
          <div>
            <span className={`size-2 color-white`}>
              {data.payout !== null
                ? numeral(
                  new BigNumber(data.payout || 0).div(1e6).valueOf()
                ).format(width < 1200 ? "0,0a.[00]" : "0,0.[00]")
                : "--"}
              &nbsp;
              <img src={iconTrx} alt="" className="img-trx" />
            </span>
          </div>
        </td>
        {width < 1200 && (
          <td className="td-time">
            <div>
              <span className={`size-21 color-white`}>
                {data.create_at
                  ? dayjs(new Date(data.create_at).getTime()).format(
                    "MM/DD/YYYY HH:mm"
                  )
                  : "--"}
              </span>
            </div>
          </td>
        )}
        {width >= 1200 && <td className="td-amount td-right td-ff">
          <div className="">
            <div className="range-ff">
              <span className={`size-0 color-white`}>
                {Math.floor(data.fulfilled || 0)}%
              </span>
              <div className="">
                <div className="" style={{ width: `${fullfilled}%` }}></div>
              </div>
            </div>
          </div>
        </td>}
        <td className="td-amount td-right td-sticky">
          <div>
            {data.fulfilled && data.fulfilled >= 100 ? (
              <>
                {(width < 1200) && <span className={`size-0 color-white`}>
                  {Math.floor(data.fulfilled || 0)}%
                </span>}
                <div className="img-done">
                  <img src={iconOrderDone()} alt="done" />
                </div>
              </>

            ) : data.cancel_at ? (
              <>
                {(width < 1200) && <span className={`size-0 color-white`}>
                  {Math.floor(data.fulfilled || 0)}%
                </span>}
                <span className="img-done">
                  <MdOutlineCancel color={`${configColor.colorRed}`} size={30} />
                </span>
              </>
            ) : (
              <span
                className="size-2 color-red bt-sell"
                onClick={(e) => onCancel(e)}
              >
                <span>
                  {t("cancel")}
                </span>
                <div className="" style={{
                  width: `${data.fulfilled || 0}%`
                }}></div>
              </span>
            )}
          </div>
        </td>
      </Wrap>
      {/* Modal Copy */}
      {/* //TODO set percent sharing */}
      {showCancel && (
        <ModalCancel
          onClose={() => {
            setShowCancel(false);
          }}
          orderID={data._id}
          refreshData={refreshData}
        />
      )}
      {showDetail && (
        <ModalTransactionList
          onClose={() => {
            setShowDetail(false);
          }}
          data={data.transaction_info}
          orderId={data._id}
          target={data.target || ""}
        />
      )}
    </>
  );
};

export default MyOrdersItem;

const Wrap = styled.tr`
  cursor: pointer;
  > td {
    height: 56px;
    vertical-align: bottom;
    border-bottom: 1px solid #ffffff0d;
    > div {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      > span {
        display: flex;
        align-items: center;
        > img {
          width: 12px;
          height: 12px;
        }
        .img-trx {
          width: 20px;
          height: 20px;
          margin-left: 6px;
        }
        .img-energy {
          width: 24px;
          height: 24px;
          margin-left: 4px;
          ${breakpointsMedias.max1199} {
            margin-left: 0;
          }
        }
      }
    }
    .range-ff {
      width: fit-content;
      margin-left: auto;
      border-radius: 4px;
      display: flex;
      align-items: center;
      > div {
        position: relative;
        border-radius: 20px;
        background-color: rgba(193, 255, 210, 0.2);
        width: 80px;
        height: 8px;
        margin-left: 4px;
        > div {
          border-radius: 20px;
          position: absolute;
          height: 100%;
          top: 0;
          left: 0;
          background-color: ${configColor.green};
          transition: 1s;
        }
      }
      > span {
        margin-right: auto;
        text-align: left;
        min-width: 30px;
      }
    }
  }

  .td-time {
    > div {
      max-width: unset;
    }
  }
  .td-right {
    text-align: right;
    > div {
      padding-left: 10px;
      align-items: flex-end;
    }
  }
  .td-resource {
    > div {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      text-align: left;
      padding-left: 0;
      > span:last-child {
        margin-left: 4px;
      }
    }
  }
  .bt-sell {
    cursor: pointer;
    padding: 3px 11px;
    border: 1px solid ${configColor.colorRed};
    border-radius: 4px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85px;
    transition: 0.3s;
    color: #ffffff;
    position: relative;
    > span {
      z-index: 1;
    }
    > div {
      position: absolute;
      width: calc(100% - 2px);
      left: 1px;
      height: calc(100% - 2px);
      top: 1px;
      background-color: ${configColor.colorRed};
      z-index: 0;
    }
    &:hover {
      border: 1px solid #ad1f1f;
      background-color: #ad1f1f;
      /* background-color: ${configColor.colorRed};
      color: #ffffff;
      > img {
        content: url(${iconClose2});
      } */
    }
  }
  .td-info {
    background-color: #222722;
    > div {
      align-items: flex-start;
      justify-content: center;
      display: flex;
      padding: 0 24px;
      > div {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: #444a46;
        &:hover {
          background-color: ${configColor.green2};
        }
      }
    }
  }
  > td:last-child {
    > div {
      padding-right: 24px;
      .img-done {
        width: 32px;
        height: 32px;
      }
    }
  }
  &:hover {
    background-color: #333834;
    > td:first-child {
      background-color: #333834;
    }
    .td-info {
      > div {
        > div {
          background-color: ${configColor.green2};
        }
      }
    }
  }
  ${breakpointsMedias.max1199} {
    cursor: pointer;
    > td {
      height: 52px;
      .range-ff {
        > div {
          display: none;
        }
        > span {
          margin-right: auto;
          text-align: left;
          min-width: unset;
        }
      }
    }
    .td-time {
      > div {
        align-items: flex-end;
        text-align: right;
      }
    }
    .td-resource {
      > div {
        padding-left: 11px;
        padding-right: 0;
        justify-content: flex-start;
      }
    }
    .td-info {
      > div {
        padding-right: 0;
      }
    }
    .td-ff {
      /* background-color: #222722; */
      > div {
        background-color: #222722;
      }
    }
    > td:last-child {
      background-color: #222722;
      > div {
        padding-right: 11px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        .bt-sell {
          min-width: 75px;
          padding: 3px 8px 3px 4px;
          ${breakpointsMedias.max480} {
            padding: 3px 8px;
            min-width: 60px;
          }
        }
      }
    }
    &:hover {
      > td:last-child {
        background-color: #333834;
      }
      .td-ff {
        > div {
          background-color: #333834;
        }
      }
    }
  }
  ${breakpointsMedias.max810} {
    .td-time {
      > div {
        /* max-width: 100px; */
        text-align: right;
        align-items: flex-end;
        margin-right: unset;
        margin-left: auto;
      }
    }
  }
`;
