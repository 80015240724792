import iconEnergy from "assets/images/icon-energy.png";
import WrapInput from "components/core/WrapInput";
import { formatCeil } from "helpers/formatNumber";
import { forwardRef, useId } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { NumericFormat } from "react-number-format";
import styled from "styled-components";
interface QuickInputFormProps {
  onChange: any;
  value: number | string;
  type?: "energy" | "sun" | "duration";
  maxWidth?: string;
  minValue?: number;
  maxValue?: number;
}
const QuickInputForm = forwardRef(
  (
    {
      onChange,
      value,
      type = "energy",
      maxWidth = "100%",
      maxValue,
      minValue,
      ...rest
    }: QuickInputFormProps,
    ref
  ) => {
    const { t } = useTranslation();
    const onMinus = () => {
      onChange((prev: any) => (+prev > 1 ? +prev - 1 : 1));
    };
    const onPlus = () => {
      onChange((prev: any) => +prev + 1);
    };
    const id = useId();
    const isInvalidMax = maxValue ? +value > maxValue : false;
    const isInvalidMaxAction = maxValue ? +value >= maxValue : false;
    const isInvalidMin = minValue && value !== 0 ? +value < minValue : false;
    return (
      <Wrap>
        <WrapInput
          className="qif-wrap"
          aria-invalid={isInvalidMax || isInvalidMin ? "true" : "false"}
        >
          <div className="qif-input">
            <NumericFormat
              getInputRef={ref}
              id={id}
              {...rest}
              value={value}
              placeholder={t("0")}
              thousandSeparator
              inputMode="numeric"
              onValueChange={(values, sourceInfo) => {
                onChange(values.floatValue ?? "");
              }}
              allowNegative={false}
              decimalScale={0}
              className="size-21 color-white"
              maxLength={12}
              style={{ maxWidth }}
              isAllowed={(values) => {
                if (values.floatValue && maxValue !== undefined) {
                  return +values.floatValue <= maxValue;
                }
                return true;
              }}
            />
          </div>
          <div className="qif-prefix">
            {type === "energy" ? <img src={iconEnergy} alt="" /> : null}
            {type === "sun" ? (
              <p className="size-1 color-white">{t("SUN")}</p>
            ) : null}

            {type === "duration" ? (
              <WrapEvent>
                <p className="size-1 color-white">{t("days")}</p>
                <div className="event-item event-minus" onClick={onMinus}>
                  <AiOutlineMinus />
                </div>
                <div
                  className={`event-item event-plus ${
                    isInvalidMaxAction ? "event-item--disabled" : ""
                  }`}
                  onClick={isInvalidMaxAction ? undefined : onPlus}
                >
                  <AiOutlinePlus />
                </div>
              </WrapEvent>
            ) : null}
          </div>
        </WrapInput>
        {type === "energy" ? (
          <p className="size-0 color-gray qif-energy-max">
            {t("max")}: {formatCeil(maxValue)}
          </p>
        ) : null}
      </Wrap>
    );
  }
);
export default QuickInputForm;
const WrapEvent = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  .event-item {
    cursor: pointer;
    background-color: var(--secondary-button-bg-secondary);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    svg {
      color: var(--white);
      font-size: 1rem;
    }
    &:hover {
      background-color: var(--primary-green-400);
    }
    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;
const Wrap = styled.div`
  /* display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid var(--grey-600);
  padding: 8px 16px;
  border-radius: 8px; */
  position: relative;
  .qif-energy-max {
    margin-top: 5px;
    position: absolute;
    bottom: -25px;
    right: 0;
  }
  .qif-wrap {
    &[aria-invalid="true"] {
      border: 1px solid var(--red);
    }
  }
  .qif-input {
    flex: 1;
    input {
      width: 100%;
    }
  }
  .qif-prefix {
    img {
      width: 20px;
    }
  }
`;
