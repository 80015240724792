import { breakpointsMedias } from "configs/constants/breakpointMedias";
import styled from "styled-components";
import ExtendDuration from "./ExtendDuration";
import ExtendInfo from "../ExtendInfo";
import ExtendMode from "./ExtendMode";
import TableExtend from "./TableExtend";
import NextStep from "components/core/NextStep";
import { useSystemExtendTeleContext } from "../../../contexts/SystemExtendTeleContext";

const StepExtend = () => {
  const { modeGlobal, onNextStep, onReset, totalPayout, isLoading } =
    useSystemExtendTeleContext();
  return (
    <Wrap>
      <div className="step-content">
        <div className="layout-step1">
          <div className="sec-mode">
            <ExtendMode />
          </div>
          {modeGlobal.includes("sameDuration") && (
            <div className="sec-duration">
              <ExtendDuration />
            </div>
          )}
          <div className="sec-table">
            <TableExtend />
          </div>
          <div className="sec-payout">
            <ExtendInfo />
          </div>
          <div className="sec-event">
            <div>
              <NextStep
                typeStep="border"
                text="back"
                onClick={() => {
                  onNextStep(1);
                  onReset();
                }}
              />
            </div>
            <div>
              <NextStep
                text="continue"
                onClick={() => onNextStep(3)}
                disabled={totalPayout === "" || isLoading}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrap>
  );
};

export default StepExtend;
const Wrap = styled.div`
  border-top: 1px solid var(--grey-600);
  padding-top: 10px;
  .sec-duration {
    margin-bottom: 40px;
  }
  .sec-table {
    /* background-color: var(--bg-background); */
    /* background-color: #222722; */
    background-color: var(--secondary-surface-path, #303531);
    padding: 16px 0 16px 24px;
    border-radius: 8px;
  }
  .sec-payout {
    margin-top: 24px;
    padding: 12px 48px;
  }
  .sec-event {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-top: 40px;
  }
  ${breakpointsMedias.max1024} {
    .sec-duration {
      margin-bottom: 20px;
    }
    .sec-table {
      padding: 16px 6px;
      margin: 0 -16px;
    }
    .sec-payout {
      margin-top: 12px;
      padding: 12px 16px;
    }
    .sec-event {
      gap: 20px;
      margin-top: 20px;
      > div,
      button {
        width: 100%;
      }
    }
  }
  ${breakpointsMedias.max767} {
    .sec-duration {
      margin-bottom: 30px;
    }
    .sec-table {
      padding: 16px 10px;
      margin: 0 -16px;
    }
    .sec-payout {
      margin-top: 12px;
      padding: 12px 16px;
    }
  }
  ${breakpointsMedias.max667} {
    .sec-duration {
      margin-bottom: 30px;
    }
    .sec-table {
    }
    .sec-payout {
      margin-top: 12px;
      padding: 12px 16px;
    }
  }
`;
