export const errorCode = {
  "RLS:408 INVALID_PARAMS string must be in [3,16]": "error408.5",
  "RLS:427 REF_CODE_INVALID ": "error427",
  "RLS:224 REF_CODE_ALREADY_EXIST ": "error224",
  "RLS:230 SPONSOR_CODE_NOT_FOUND ": "error230",
  "RLS:231 CAN_NOT_REF_YOURSELF ": "error231",
  "RLS:000 UNEXPECTED_ERROR": "error000",
  "RLS:001 SUCCESS": "error001",
  "RLS:101 AUTHORIZATION_REQUIRED": "error101",
  "RLS:102 HEADER_ADDRESS_REQUIRED": "error102",
  "RLS:104 SIGNATURE_OUTDATE": "error104",
  "RLS:105 NOT_SUPPORT": "error105",
  "RLS:106 API_KEY_REQUIRED": "error106",
  "RLS:107 INVALID_API_KEY": "error107",
  "RLS:200 SIGNATURE_INVALID": "error200",
  "RLS:201 DOMAIN_NOT_EXIST": "error201",
  "RLS:202 MUST_APPROVE_FIRST": "error202",
  "RLS:203 INSUFFICIENT_WTRX": "error203",
  "RLS:205 INSUFFICIENT_BALANCE": "error205",
  "RLS:206 INVALID_PAYMENT_METHOD": "error206",
  "RLS:212 ADDRESS_INVALID": "error212",
  "RLS:213 DURATION_INVALID": "error213",
  "RLS:214 AlREADY_MINT_WTRX": "error214",
  "RLS:215 USER_HAS_SET_PERMIT": "error215",
  "RLS:216 PERMISSION_INVALID": "error216",
  "RLS:217 USER_PERMISSION_NOT_EXIST": "error217",
  "RLS:218 TRANSACTION_ID_ALREADY_EXISTS": "error218",
  "RLS:219 NON_OWNER_TRANSACTION": "error219",
  "RLS:220 ORDER_REQUEST_NOT_EXISTS": "error220",
  "RLS:221 FEE_LIMIT_TOO_HIGH": "error221",
  "RLS:222 ORDER_REQUEST_CAN_NOT_CANCEL": "error222",
  "RLS:223 PARAMS_NOT_MATCH_TRANSACTION": "error223",
  "RLS:224 REF_CODE_ALREADY_EXIST": "error224",
  "RLS:225 ORDER_BUY_ENERGY_CAN_NOT_CREATE": "error225",
  "RLS:226 ORDER_ALREADY_CANCEL": "error226",
  "RLS:227 ORDER_ACTIVE_TIME_TOO_SHORT_TO_CANCEL": "error227",
  "RLS:228 ORDER_ACTIVE_TIME_TOO_SHORT_TO_UPDATE": "error228",
  "RLS:229 ORDER_CAN_NOT_UPDATE": "error229",
  "RLS:230 SPONSOR_CODE_NOT_FOUND": "error230",
  "RLS:231 CAN_NOT_REF_YOURSELF": "error231",
  "RLS:232 ORDER_BUY_ENERGY_AMOUNT_TOO_SMALL": "error232",
  "RLS:233 USER_TELE_NOT_FOUND": "error233",
  "RLS:234 INVALID_CACHE_ORDER_IN_TELE": "error234",
  "RLS:235 INVALID_CACHE_USER_STORAGE_IN_TELE": "error235",
  "RLS:236 USER_REF_NOT_FOUND": "error236",
  "RLS:237 MISSING_PERMISSIONS": "error237",
  "RLS:400 MISSING_PARAMS": "error400",
  "RLS:401 INVALID_PAGE": "error401",
  "RLS:402 INVALID_PAGESIZE": "error402",
  "RLS:403 INVALID_VALID_UNTIL_TIME": "error403",
  "RLS:405 INVALID_VALUE": "error405",
  "RLS:406 USER_NOT_EXIST": "error406",
  "RLS:408 INVALID_PARAMS": "error408",
  "RLS:409 PROJECT_NOT_EXISTS": "error409",
  "RLS:410 PROJECT_NAME_EXISTS": "error410",
  "RLS:412 TRANSACTION_IS_NOT_VALID_TRANSFER_TRX": "error412",
  "RLS:414 MIN_TRANSFER_AMOUNT": "error414",
  "RLS:415 ABI_INVALID": "error415",
  "RLS:416 RECIPIENT_CONTRACT_NOT_EXISTS": "error416",
  "RLS:417 RECIPIENT_CONTRACT_ALREADY_EXISTS": "error417",
  "RLS:418 RECIPIENT_CONTRACT_INVALID": "error418",
  "RLS:419 METHOD_CALLING_IS_NOT_SUPPORT": "error419",
  "RLS:420 TRANSACTION_IS_NOT_DELEGATE_RESOURCE": "error420",
  "RLS:421 TRANSACTION_IS_NOT_VALID_DELEGATE_RESOURCE": "error421",
  "RLS:422 NOT_SUPPORT_PARTIAL_ORDER": "error422",
  "RLS:423 ORDER_IS_FULLED": "error423",
  "RLS:424 DELEGATED_FOR_USER_NOT_AVAILABLE": "error424",
  "RLS:425 FAST_REQUEST": "error425",
  "RLS:426 SINGEDTX_EXPIRED": "error426",
  "RLS:427 REF_CODE_INVALID": "error427",
  "RLS:428 USER_NOT_ENOUGH_COMMISSION": "error428",
  "RLS:429 USER_ALREADY_EXISTS": "error429",
  "RLS:430 TRANSACTION_IS_NOT_VALID_TRANSFER_TRC10": "error430",
  "RLS:431 DELEGATE_RESOURCE_AMOUNT_OUT_OF_RANGE": "error431",
  "RLS:432 DELEGATE_RESOURCE_LOCK_PERIOD_NOT_MATCHED": "error432",
  "RLS:433 CANNOT_SET_PARTIAL_FULFILLED": "error433",
  "RLS:434 TRANSFER_BALANCE_NOT_CORRECT": "error434",
  "RLS:435 SIGNED_TX_REQUIRED": "error435",
  "RLS:436 INVALID_EXTEND_TIME": "error436",
  "RLS:437 SOME_MATCHED_ORDER_CANNOT_EXTEND": "error437",
  "RLS:438 NRG_TX_NOT_CORRECT": "error438",
  "RLS:439 INTERNAL_ACCOUNT_NOT_FOUND": "error439",
  "RLS:440 INTERNAL_BALANCE_ACCOUNT_NOT_ENOUGH": "error440",
  "RLS:441 UNIT_PRICE_TOO_LOW": "error441",
  "RLS:501 SERVER_MAINTAINED": "error501",
  "RLS:502 BOT_INSUFFICIENT_BALANCE": "error502",
  "RLS:600 RATE_LIMIT": "error600",
} as any;

export const formatError = (e: any) => {
  return {
    code: errorCode[`${e.split(" ")[0]} ${e.split(" ")[1]}`],
    sub: e.split(" ")[2],
  };
};
