import NextStep from "components/core/NextStep";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import TableDetail from "./detail/TableDetail";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { TDelegateDetail } from "types";
type ModalEnergyProps = {
  onClose: () => void;
  delegateDetails: TDelegateDetail[];
};
const ModalExtendDetail = ({ onClose, delegateDetails }: ModalEnergyProps) => {
  const { t } = useTranslation();
  return (
    <Wrap>
      <WrapContent>
        <div className="med-heading">
          <p className="size-5 color-white center">{t("detailDelegate")}</p>
        </div>
        <WrapTable>
          <TableDetail delegateDetails={delegateDetails} />
        </WrapTable>
        <div className="med-event">
          <NextStep text="confirm" typeStep="border" onClick={onClose} />
        </div>
      </WrapContent>
      <div className="med-close" onClick={onClose}>
        <MdClose size={30} />
      </div>
    </Wrap>
  );
};

export default ModalExtendDetail;

const Wrap = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 100%;
  :before {
    content: "";
    width: 100vw;
    height: 100vh;
    min-height: 1400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }
  .med-close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 3;
    color: var(--white);
    cursor: pointer;
    :hover {
      color: var(--primary-green-400);
    }
  }
`;
const WrapContent = styled.div`
  position: relative;
  z-index: 2;
  background-color: var(--secondary-bg);
  padding: 40px 72px 40px;
  border-radius: 16px;
  .med-heading {
    margin-bottom: 30px;
  }
  .med-event {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${breakpointsMedias.max1024} {
    padding: 24px 16px 24px;
    .med-heading {
      margin-bottom: 20px;
    }
  }
`;
const WrapTable = styled.div``;
