import LoadingSpinner from "components/core/LoadingSpinner";
import styled from "styled-components";

const SelectionSkeleton = () => {
  return (
    <Wrap>
      <LoadingSpinner />
    </Wrap>
  );
};

export default SelectionSkeleton;
const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
