import { configColor } from "configs/constants/configColor";
import React from "react";
import styled from "styled-components";
type TSize = "tiny" | "normal" | "big";
type DurationSliderProps = {
  arrList: number[];
  textSize?: TSize;
  disabled?: boolean;
  onChooseValue: (e: number) => void;
  rangeSize?: "tiny" | "normal" | "big";
} & React.InputHTMLAttributes<HTMLInputElement>;
const DurationSlider = ({
  arrList,
  textSize = "normal",
  disabled = false,
  onChooseValue,
  rangeSize = "normal",
  ...props
}: DurationSliderProps) => {
  const classSize: Record<TSize, string> = {
    tiny: "size-0",
    normal: "size-1",
    big: "size-2",
  };
  const value = props.value ? +props.value : 1;
  const max = props.max ? +props.max : 1;
  const min = props.min ? +props.min : 1;
  const numerator = value - min;
  const width = (numerator / (max - min || 1)) * 100;
  return (
    <Wrap className={rangeSize}>
      <input {...props} type="range" disabled={disabled} />
      <ShadowDuration
        className={`shadow-duration ${
          disabled ? "shadow-duration--disabled" : ""
        }`}
        width={width > 100 ? 100 : width}
      ></ShadowDuration>
      <div className={`range-list ${disabled ? "range-list--disabled" : ""}`}>
        <>
          {arrList.map((item, index) => (
            <div
              className={`dsc-item ${
                +value >= item - 1 ? "dsc-item--active" : ""
              }`}
              key={index}
              onClick={() => {
                !disabled && onChooseValue(item);
              }}
            >
              <span className={` ${classSize[textSize]} color-white`}>
                {item}
              </span>
            </div>
          ))}
        </>
      </div>
    </Wrap>
  );
};

export default DurationSlider;
type ShadowProps = {
  width: number;
};
const ShadowDuration = styled.div<ShadowProps>`
  width: ${(props) => props.width}%;
  height: 4px;
  background-color: ${configColor.green};
  /* background-image: linear-gradient(${configColor.green}, ${configColor.green}); */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  border-radius: 5px;
  &.shadow-duration {
    &--disabled {
      opacity: 0.5;
    }
  }
`;
const Wrap = styled.div`
  /* width: 50%; */
  display: flex;
  position: relative;
  height: fit-content;
  .shadow-duration {
  }
  input:disabled {
    opacity: 0.7;
  }
  input[type="range"] {
    cursor: pointer;
    -webkit-appearance: none;
    /* margin-right: 15px; */
    width: 100%;
    height: 4px;
    background: ${configColor.gray3};
    border-radius: 5px;
    background-repeat: no-repeat;
  }
  /* Input Thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    background: ${configColor.green};
    /* background-image: url(${""});
            background-size: 100% 100%;
            background-repeat: no-repeat; */
  }
  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    background: ${configColor.green};
    /* background-image: url(${""});
            background-size: 100% 100%;
            background-repeat: no-repeat; */
  }
  input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    transition: background 0.3s ease-in-out;
    background: ${configColor.green};
    /* background-image: url(${""});
            background-size: 100% 100%;
            background-repeat: no-repeat; */
  }
  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  input[type="range"]::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  input[type="range"]::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  .range-list {
    position: absolute;
    bottom: -10px;
    left: -8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &--disabled {
      opacity: 0.5;
      .dsc-item {
        cursor: not-allowed !important;
        :before {
          opacity: 0.5;
        }
      }
    }
    .dsc-item {
      width: 0px;
      position: relative;
      cursor: pointer;
      > span {
        position: absolute;
        /* top: 0; */
        top: -5px;
        left: 0;
        transform: translateX(-50%);
        width: fit-content;
      }
      :before {
        content: "";
        width: 3px;
        height: 10px;
        background-color: ${configColor.gray3};
        position: absolute;
        top: -19px;
        left: 0;
      }
      &--active {
        :before {
          background: ${configColor.green};
        }
      }
      &:last-child {
        transform: translateX(3px);
      }
      &:first-child {
        transform: translateX(8px);
      }
    }
  }
  &.tiny {
    .range-list {
      left: -2px;
      .dsc-item {
        cursor: pointer;
        > span {
          left: 2px;
        }
        :before {
        }
        &:last-child {
          transform: translateX(-3px);
        }
        &:first-child {
          transform: translateX(4px);
        }
      }
    }
  }
`;
