import styled from "styled-components";
import { useLockBodyScroll } from "helpers/hooks/useLockBodyScroll";
import ModalWrap from "components/core/ModalWrap";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useTranslation } from "react-i18next";
import { configColor } from "configs/constants/configColor";
import { useRef } from "react";
import QRCode from 'react-qr-code'
import { useWidthScreen } from "helpers/hooks/useWidthScreen";
import WrapInput from "components/core/WrapInput";
import { BiCopy } from "react-icons/bi";
import CopyToClipboard from "react-copy-to-clipboard";
import Button from "components/core/Button";
import * as htmlToImage from 'html-to-image';

interface IModalSuccess {
    onClose: () => void;
    address: string
}

const ModalQr = ({ onClose, address }: IModalSuccess) => {
    useLockBodyScroll();
    const { t } = useTranslation();
    const { width } = useWidthScreen();
    const copyRef = useRef<HTMLSpanElement>(null);

    const downloadImage = async () => {
        let mainImg = document.getElementById("qr_address") as any;
        let cv = await htmlToImage.toCanvas(mainImg)
        cv = await htmlToImage.toCanvas(mainImg)
        cv = await htmlToImage.toCanvas(mainImg)
        // cv.id = "main-img"
        let link = await cv.toDataURL();
        var a = document.createElement("a");
        a.href = link;
        a.download = "qr_address" + ".png";
        a.click();
    }

    return (
        <ModalWrap onClose={onClose} showCloseBt={true} clickOutsideToClose={true}>
            <Wrap>
                <div className="modal-title">
                    <span className="size-5 color-white">{t("internalAddressQrCode")}</span>
                </div>
                <div className="modal-body">
                    <div className="qr-img" id="qr_address">
                        <QRCode value={address} className="" size={width <= 767 ? 160 : width <= 1199 ? 200 : 250} />
                    </div>
                    <div className="mb-address">
                        <span className="size-21 color-white">{t("internalAddress")}</span>
                        <WrapInput className="mba-value">
                            <span className="size-2 color-white">{address}</span>
                            <CopyToClipboard text={address} onCopy={() => {
                                if (copyRef.current) {
                                    copyRef.current.style.display = "flex";
                                    setTimeout(() => {
                                        if (copyRef.current) { copyRef.current.style.display = "none"; }
                                    }, 2000)
                                }
                            }}>
                                <div className="ri-ref-bt">
                                    <span className='size-1 color-black-2'>
                                        <BiCopy size={24} color="#FFF" />
                                    </span>
                                    <span ref={copyRef} className="size-0 color-white copied" > Copied!</span >
                                </div>
                            </CopyToClipboard>
                        </WrapInput>
                    </div>
                    <Button text="saveQrCode" className="mb-bt" onClick={downloadImage} />
                </div>
            </Wrap>
        </ModalWrap>
    );
};

export default ModalQr;

const Wrap = styled.div`
    width: calc(100vw - 80px);
    max-width: 500px;
    display: flex;
    flex-direction: column;
    z-index: 0;
    padding: 31px 48px;
    .modal-title {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 32px;
        > span {
            text-align: center;
        }
        > span:first-child {
            margin-bottom: 6px;
        }
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .qr-img {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            border-radius: 4px;
            background-color: ${configColor.colorWhite};
        }
        .mb-address {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 20px;
            gap: 12px;
            .mba-value {
                > span {
                    flex: 1;
                    margin-right: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .ri-ref-bt {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    position: relative;
                    > span {
                        display: flex;
                        align-items: center;
                    }
                    > .copied {
                        position: absolute;
                        width: fit-content;
                        padding: 5px 10px;
                        border-radius: 5px;
                        background-color: ${configColor.colorGray};
                        left: 0;
                        top: 100%;
                        display: none;
                        z-index: 1;
                    }

                }
            }
        }
        .mb-bt {
            margin-top: 20px;
            width: 100%;
        }
    }
    ${breakpointsMedias.max1199} {
        width: calc(100vw - 32px);
        padding: 31px 15px;
        max-width: 400px;
        .modal-title {
            margin-bottom: 24px;
        }
    }
`;
