import { toast } from 'react-toastify';
type NotifyToastifyProps = {
    type: "success" | "error" | "info",
    text: string;
    options?: any
}
export const notifyToastify = ({ type, text, options }: NotifyToastifyProps) => {
    switch (type) {
        case "success": {
            toast.success(text, options);
            break;
        }
        case "error": {
            toast.error(text, options);
            break;
        }
        default: {
            toast.info(text, options);
            break;
        }
    }
    return null;
}

