import { memo, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { breakpointsMedias } from 'configs/constants/breakpointMedias'
import { configColor } from 'configs/constants/configColor'
import { BiLogoTelegram, BiNotification } from 'react-icons/bi'
import NotifySetting from './NotifySetting'
import ClickOutSideComp from 'components/core/clickOutside'
import { useSearchParams } from 'react-router-dom'
import iconTele3 from 'assets/images/icon-telegram-3.png'
import { MdNotificationsActive } from 'react-icons/md'
const Notify = () => {
    const [showTele, setShowTele] = useState(false);
    const [showSetting, setShowSetting] = useState<boolean>(false);
    const listRef = useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams();
    const verifyCode = searchParams.get("verifyCode")

    useEffect(() => {
        if (verifyCode) {
            setShowTele(true)
        }
    }, [])

    return (
        <ClickOutSideComp handleClickOutSide={() => { setShowTele(false) }}>
            <PK ref={listRef} tabIndex={0}>
                <div className="picked" onClick={(e) => {
                    e.stopPropagation();
                    setShowTele(!showTele);
                }}>
                    <img src={iconTele3} alt="" />
                    <MdNotificationsActive color={configColor.colorWhite} size={28} />
                </div>
                <div className={showTele ? "pick_list active" : "pick_list"} >
                    <NotifySetting showTele={showTele}/>
                </div>
            </PK >
        </ClickOutSideComp>
    )
}
export default memo(Notify);

const PK = memo(styled.div`
    width: 50px;
    height: 50px;
    z-index: 1;
    position: relative;
    margin-left: 12px;
    .picked {
        border: 1px solid ${configColor.green};
        width: 50px;
        height: 50px;
        cursor: pointer;
        display: flex;
        background-color: #252a26;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        > img {
            position: absolute;
            top: 8px;
            right: 8px;
            width: 18px;
            height: auto;
        }
        > svg {
           
        }
    }
        .pick_list {
            border-radius: 4px;
            border: 1px solid #808080;
            background-color: #252a26;
            position: absolute;
            top: 60px;
            right: 0;
            flex-direction: column;
            display: none;
            height: fit-content;
            overflow: hidden;
        }
        .active {
            display: flex;
            width: 300px;
            min-height: 350px;
        }
    ${breakpointsMedias.max1199} {
       
    }
`)
