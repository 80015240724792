import styled from "styled-components";
import { useLockBodyScroll } from "helpers/hooks/useLockBodyScroll";
import ModalWrap from "components/core/ModalWrap";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";

interface IModalSuccess {
    onClose: () => void;
    onRevoke: () => void,
    isLoading: boolean
}

const ModalRevoke = ({ onClose, onRevoke, isLoading }: IModalSuccess) => {
    useLockBodyScroll();
    const { t } = useTranslation();

    return (
        <ModalWrap onClose={onClose} showCloseBt={true} clickOutsideToClose={true}>
            <Wrap>
                <div className="modal-title">
                    <span className="size-5 color-white">{t("revokeAPIKey")}</span>
                </div>
                <div className="modal-body">
                    <span className="size-2 color-white">{t("confirmRevoke")}</span>
                    <div className="mb-bt">
                        <Button typeButton={2} text="cancel" onClick={onClose} />
                        <Button text="confirm" onClick={onRevoke} isLoading={isLoading} />
                    </div>

                </div>
            </Wrap>
        </ModalWrap>
    );
};

export default ModalRevoke;

const Wrap = styled.div`
    width: calc(100vw - 80px);
    max-width: 500px;
    display: flex;
    flex-direction: column;
    z-index: 0;
    padding: 31px 48px;
    .modal-title {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 32px;
        > span {
            text-align: center;
        }
        > span:first-child {
            margin-bottom: 6px;
        }
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        > span {
            text-align: center;
            white-space: pre-line;
        }
        .mb-bt {
            display: flex;
            align-items: center;
            gap: 20px;
            margin-top: 20px;
            width: 100%;
            justify-content: space-evenly;
        }
    }
    ${breakpointsMedias.max1199} {
        width: calc(100vw - 32px);
        padding: 31px 15px;
        max-width: 400px;
        .modal-title {
            margin-bottom: 16px;
        }
    }
`;
