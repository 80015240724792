// import JsonError from "./JsonError";
export interface IErrorRLS {
  [key: string]: string;
}
export const splitError = (errorMessage: string): string => {
  if (!errorMessage) {
    return errorMessage;
  }
  const splitMessage = errorMessage.toString().split("RLS:")?.[1] || "";
  const numberError = splitMessage.slice(0, 3) ?? "";
  if (numberError === "454") {
    return "error454";
  }
  return `error${numberError}`;
  //   return JsonError[`RLS${numberError}`];
};
