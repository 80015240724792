import { memo, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import iconEng from 'assets/images/icon-eng.png'
import iconChi from 'assets/images/icon-chi.png'
import iconRus from 'assets/images/icon-rus.png'
import iconInd from 'assets/images/icon-id.png'
import { breakpointsMedias } from 'configs/constants/breakpointMedias'
import { configColor } from 'configs/constants/configColor'

export const listLang = [{
    icon: iconEng,
    lang: "en",
    text: "English"
}, {
    icon: iconChi,
    lang: "zh",
    text: "Chinese"
}, {
    icon: iconRus,
    lang: "ru",
    text: "Russian"
}, {
    icon: iconInd,
    lang: "id",
    text: "Indonesian"
}]

const PickLang = () => {
    const { t, i18n } = useTranslation()
    const [showPicker, setShowPicker] = useState(false);
    const [picked, setPicked] = useState<number>(0);
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (showPicker) {
            if (listRef.current) {
                listRef.current.focus();
                listRef.current.onblur = () => { setShowPicker(false) };
            }
        }
    }, [showPicker])

    useEffect(() => {
        localStorage.setItem("lang", i18n.language);
        let findLang = listLang.findIndex(item => item.lang === i18n.language)
        if (findLang !== -1) {
            setPicked(findLang)
        } else {
            setPicked(0)
        }
    }, [i18n.language])

    const onPick = (e: string) => {
        // setPicked(e)
        i18n.changeLanguage(e)
    }

    return (
        <PK ref={listRef} tabIndex={0}>
            <div className="picked" onClick={() => {
                setShowPicker(!showPicker);
            }}>
                <img src={listLang[picked].icon} />
            </div>
            <div className={showPicker ? "pick_list active" : "pick_list"} >
                {listLang.map((item, index) => <div key={index} className={`pick_item ${index === picked && "pick-item-active"}`} onClick={() => { onPick(item.lang); setShowPicker(false) }}>
                    <span className={`size-21 color-white`}>{t(item.text)}</span>
                    <img src={item.icon} alt="" />
                </div>)}
            </div>
        </PK >
    )
}
export default memo(PickLang);

const PK = memo(styled.div`
    width: 50px;
    height: 50px;
    z-index: 1;
    position: relative;
    margin-left: 12px;
    .picked {
        border: 1px solid ${configColor.green};
        width: 50px;
        height: 50px;
        cursor: pointer;
        display: flex;
        background-color: #252a26;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        > img {
            width: 28px;
            height: 28px;
        }
    }
        .pick_list {
            border-radius: 4px;
            border: 1px solid #808080;
            background-color: #252a26;
            position: absolute;
            top: 60px;
            right: 0;
            flex-direction: column;
            display: none;
            width: 150px;
            height: fit-content;
            overflow: hidden;
            .pick_item {
                display: flex;
                cursor: pointer;
                align-items: center;
                padding: 10px;
                height: 42px;
                border-bottom: 1px solid #FFFFFF0D;
                justify-content: space-between;
                &:hover {
                    background: #303531;
                }
                > img {
                    width: 24px;
                    height: 24px;
                }
            }
            .pick-item-active {
                background: #303531;
            }
        }
        .active {
            display: flex;
        }
    ${breakpointsMedias.max1199} {
       
    }
`)
