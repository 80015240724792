import styled from "styled-components";
import logo from "assets/images/logo.png";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { configColor } from "configs/constants/configColor";
import { useWidthScreen } from "helpers/hooks/useWidthScreen";
import { useContext } from "react";
import { RefContext } from "contexts/RefContext";

const LINK_TESTNET_DEMO = process.env.REACT_APP_LINK_TESTNET_DEMO ?? "";
const LINK_DASHBOARD = process.env.REACT_APP_LINK_DASHBOARD ?? "";
const LINK_TELEBOT = process.env.REACT_APP_TELEBOT ?? "";

const Footer = () => {
  const { sponsor, pending_ref } = useContext(RefContext)

  const dataDocs = [
    {
      text: "telebot",
      link: LINK_TELEBOT + `?start=${sponsor ? sponsor : (pending_ref || "")}`,
    },
    // {
    //   text: "demo",
    //   link: LINK_TESTNET_DEMO,
    //   target: LINK_TESTNET_DEMO === "/demo" ? false : true,
    // },
    // {
    //   text: "partner",
    //   link: LINK_DASHBOARD,
    //   target: true,
    // },
    {
      text: "contactUs",
      link: "https://t.me/wantingtrx",
    },
    {
      text: "buyAPI",
      link: "https://www.postman.com/tronsave/workspace/tronsave/collection/29749953-5c53496c-8e42-49db-b061-3fc40ffa1009"
    }
  ];
  const dataSocials = [
    {
      text: "telegram",
      link: "https://t.me/+khQXTuasq683NzVl",
    },
    {
      text: "twitter",
      link: "https://twitter.com/tronsave_io",
    },
    {
      text: "Youtube",
      link: "https://www.youtube.com/@TronSaveOfficial",
    },
  ];
  //self link when mobile
  const { isMobile } = useWidthScreen();
  return (
    <Wrap className="">
      <div className="container">
        <div className="footer-top">
          <Link to={""} className="logo">
            <img src={logo} alt="Tron Save" />
          </Link>

          <div className="footer-links">
            <span className="size-3 color-white">{t("business")}</span>
            <div className="socials">
              {dataDocs.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  target={isMobile ? "_self" : "_blank"}
                  rel="noreferrer"
                >
                  <span className={`size-21 color-gray`}>{t(item.text)}</span>
                </a>
              ))}
            </div>
          </div>
          <div className="footer-links">
            <span className="size-3 color-white">{t("socials")}</span>
            <div className="socials">
              {dataSocials.map((item, index) => (
                <a
                  key={index}
                  href={item.link}
                  target={isMobile ? "_self" : "_blank"}
                  rel="noreferrer"
                >
                  <span className={`size-21 color-gray`}>{t(item.text)}</span>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="credit">
          <span className="size-21 color-gray">{t("credit")}</span>
        </div>
      </div>
    </Wrap>
  );
};

export default Footer;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  background-color: #1a211c;
  margin-top: auto;
  .footer-top {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    justify-content: space-between;
    .logo {
      width: 216px;
      height: auto;
      margin-right: 20px;
    }
    .footer-links {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-left: auto;
      &:last-child {
        margin-left: 100px;
      }
      > span {
        position: relative;
        width: fit-content;
        padding-left: 16px;
        &::after {
          content: "";
          position: absolute;
          height: 100%;
          left: 0;
          width: 4px;
          background-color: ${configColor.green};
          top: 0;
        }
      }
      .socials {
        display: flex;
        align-items: center;
        > a {
          margin-right: 24px;
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            span {
              color: ${configColor.green};
            }
          }
        }
      }
    }
  }
  .credit {
    color: #a6a6a6;
    padding-top: 24px;
    text-align: center;
  }

  ${breakpointsMedias.max1199} {
    padding-top: 24px;
    padding-bottom: 24px;
    .footer-top {
      padding-bottom: 4px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      .logo {
        width: 185px;
      }
      .footer-links {
        gap: 12px;
        margin-bottom: 12px;
        &:last-child {
          margin-left: 80px;
        }
        > span {
          padding-left: 16px;
        }
        .socials {
          > a {
            margin-right: 24px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .credit {
      padding-top: 12px;
      text-align: center;
    }
  }
  ${breakpointsMedias.max991} {
    .footer-top {
      padding-bottom: 20px;
      .footer-links {
        width: 20%;
        margin-bottom: auto;
        .socials {
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
        }
      }
    }
  }
  ${breakpointsMedias.max767} {
    .footer-top {
      flex-wrap: wrap;
      .logo {
        margin-bottom: 16px;
        > img {
          width: 185px;
        }
      }
      .footer-links {
        width: 20%;
        margin-bottom: auto;
        &:last-child {
          margin-left: 5%;
        }
      }
    }
  }
  ${breakpointsMedias.max667} {
    .footer-top {
      .logo {
        margin-bottom: 24px;
        width: 100%;
      }
      .footer-links {
        width: 40%;
        margin-bottom: auto;
        margin-left: unset;
        &:last-child {
          margin-left: 10%;
        }
      }
    }
  }
`;
