import LoadingSpinner from "components/core/LoadingSpinner";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EStatusPermission } from "types";

export interface StepSellPermissionProps {
  step: number;
  textStep: string;
  status?: EStatusPermission;
  isLoading?: boolean;
}
const StepSellPermission = ({
  step = 1,
  textStep,
  status = EStatusPermission.NEXT,
  isLoading = false,
}: StepSellPermissionProps) => {
  const { t } = useTranslation();
  return (
    <Wrap className={`${status}`}>
      <div className="step">
        {status === EStatusPermission.PASS ? (
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 12.2939L9 18.2939L21 6.29395"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ) : isLoading ? (
          <LoadingSpinner size="small" />
        ) : (
          <span className="size-22">{step}</span>
        )}
      </div>
      <p className="size-23 text-step">{t(textStep)}</p>
    </Wrap>
  );
};

export default StepSellPermission;
const Wrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .step {
    padding: 10px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    border: 1px solid var(--grey-600);
    background: rgba(255, 255, 255, 0.15);
    span {
      color: var(--grey-400);
    }
  }
  .text-step {
    color: var(--grey-500);
  }
  &:is(.pass, .active) {
    .step {
      border-color: var(--primary-green-400);
      background: var(--primary-green-400);
      span {
        color: var(--neutral-white);
      }
    }
    .text-step {
      color: var(--neutral-white);
    }
  }
  &.pass {
    .step {
      padding: 3px;
    }
  }
  ${breakpointsMedias.max1199} {
  }
  ${breakpointsMedias.max767} {
    .step {
      width: 24px;
      height: 24px;
    }
    .text-step {
      display: none;
    }
    &.active {
      .text-step {
        display: block;
      }
    }
  }
`;
