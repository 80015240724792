import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";
import ReactPaginate from "react-paginate";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import LoadingSpinner from "components/core/LoadingSpinner";
import { GET_MY_ORDERS } from "configs/apolloSchema";
import TextToolTipRename from "components/core/TextTooltipRename";
import { EResource } from "types";
import MyOrdersItem from "./MyOrdersItem";
import NoData from "components/core/NoData";
import { useWidthScreen } from "helpers/hooks/useWidthScreen";
import { PageData, TxInfo } from "pages/energy/right/ActiveOrders";
import { useTeleContext } from "../contexts/TeleContext";

export type OrderItem = {
  _id: string;
  resource_type: EResource;
  resource_amount: number | null;
  price: number | null;
  fulfilled: number | null;
  create_at: Date | null;
  is_lock: boolean;
  target: string | null;
  requester: string | null;
  transaction_info: TxInfo[];
  payout: number | null;
  duration_sec: number;
  cancel_at: Date | null;
  type: string;
};

const tooltipList = [
  "date",
  "resource",
  "price",
  "payout",
  "fullfilled",
] as string[];

const MyOrders = () => {
  const { requester: address } = useTeleContext();
  const { t } = useTranslation();
  const client = useApolloClient();
  const { width } = useWidthScreen();
  const colList =
    width >= 1200
      ? ["", "date", "resource", "price", "payout", "fullfilled", " "]
      : ["resource", "price", "payout", "date", " "];
  const [data, setData] = useState<OrderItem[]>([]);
  const pageSize = 8;
  const [loading, setLoading] = useState<boolean>(false);

  const [pageData, setPageData] = useState<PageData>({
    currentPage: 0,
    total: 0,
    isSuccess: true,
  });

  const getBoard = (page: number) => {
    let success = false;
    try {
      setLoading(true);
      const query = client.watchQuery({
        query: GET_MY_ORDERS,
        variables: {
          page: page,
          pageSize: pageSize,
          address: address,
          sortFilterBy: {
            fieldType: "create_at",
            arrangeType: "DESC",
          },
        },
        fetchPolicy: "no-cache",
      });
      const querySubscribe = query.subscribe(
        ({ data: { rls_user_order_history } }) => {
          if (rls_user_order_history.data) {
            success = true;
            setPageData({
              currentPage: page,
              total: rls_user_order_history.total,
              isSuccess: true,
            });
            setData(rls_user_order_history.data);
          }
          setLoading(false);
        },
        (error) => {
          console.log("Get my orders error", error);
        }
      );
      return querySubscribe;
    } catch (error) {
      setLoading(false);
      console.log("Get my orders active err", error);
    }
    if (!success) {
      setPageData({
        ...pageData,
        currentPage: page,
        isSuccess: false,
      });
    }
  };

  useEffect(() => {
    if (address) {
      const query = getBoard(0);
      return () => {
        query && query.unsubscribe();
      };
    }
  }, [address]);

  const refreshData = () => {
    getBoard(pageData.currentPage);
  };

  return (
    <Wrap>
      <div className="ld-wrap">
        <div className="ld-table bg-gray scrollbar">
          <table className="ldt">
            <thead>
              <tr>
                {colList.map((item, index) => (
                  <th key={index} className={`col-name`}>
                    <div className="">
                      {tooltipList.includes(item) ? (
                        <TextToolTipRename
                          text={item}
                          tooltipContent={t(`tt${item}`)}
                          colorType="gray"
                        />
                      ) : (
                        <span className={`size-21 color-gray `}>{t(item)}</span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            {!loading && (
              <tbody>
                {data.map((item, index) => (
                  <MyOrdersItem
                    key={index}
                    data={item}
                    refreshData={refreshData}
                  />
                ))}
              </tbody>
            )}
          </table>
          {loading ? (
            <span className="size-2 text-center">
              <LoadingSpinner />
            </span>
          ) : !pageData.isSuccess ? (
            <span className="size-2 text-center color-white">
              Get data error
            </span>
          ) : data.length === 0 ? (
            <NoData />
          ) : null}
        </div>
        <ReactPaginate
          pageCount={Math.ceil(pageData.total / pageSize) || 1}
          pageRangeDisplayed={width < 768 ? 2 : 5}
          // initialPage={pageData.currentPage}
          forcePage={pageData.currentPage}
          marginPagesDisplayed={1}
          onPageChange={({ selected }) => {
            getBoard(selected);
          }}
          previousLabel={
            <div
              onClick={(e) => {
                e.stopPropagation();
                pageData.currentPage !== 0 && getBoard(0);
              }}
            >
              <AiOutlineDoubleLeft />
            </div>
          }
          previousClassName="pc-prev color-gray"
          nextLabel={
            <div
              onClick={(e) => {
                e.stopPropagation();
                pageData.currentPage !==
                  Math.ceil(pageData.total / pageSize) - 1 &&
                  Math.ceil(pageData.total / pageSize) > 1 &&
                  getBoard(Math.ceil(pageData.total / pageSize) - 1);
              }}
            >
              <AiOutlineDoubleRight />
            </div>
          }
          nextClassName="pc-next color-gray"
          containerClassName="page-container"
          pageClassName="pc-item size-1 color-gray"
          activeClassName="pc-item-active size-1"
        />
      </div>
    </Wrap>
  );
};

export default MyOrders;

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .ld-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    /* z-index: 0; */
    height: 100%;
    padding-bottom: 23px;
  }
  .ld-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    flex: 1;
    min-height: 500px;
    height: 100%;
    > span {
      margin: auto;
      text-align: center;
    }
  }
  table {
    min-width: 600px;
    width: 100%;
    th {
      height: 32px;
      text-align: left;
      width: 18%;
      background: #303531;
      padding: 8px 0;
      > div {
        display: flex;
        align-items: center;
        width: fit-content;
      }
      &:last-child {
        text-align: right;
        > div {
          margin-left: auto;
        }
      }
      &:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: #303531;
        min-width: 88px;
        padding-left: 0;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        text-align: right;
        > div {
          margin-left: auto;
        }
      }
      &:nth-child(1) {
        width: 70px;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:last-child {
        width: 10%;
      }
    }
    td {
      &:first-child {
        position: sticky;
        left: 0;
        z-index: 0;
      }
    }
  }
  .page-container {
    max-width: 500px;
    margin-top: 10px;
    ${breakpointsMedias.max767} {
      max-width: 300px;
    }
  }
  ${breakpointsMedias.max1199} {
    .ldt {
      min-width: 550px;
      ${breakpointsMedias.max480} {
        min-width: 520px;
      }
      th {
        height: 36px;
        padding: 8px 0;
        &:first-child {
          min-width: 64px;
          left: unset;
          z-index: 2;
        }
        &:last-child {
          min-width: 100px;
          ${breakpointsMedias.max480} {
            min-width: 60px;
          }
          position: sticky;
          right: 0;
          z-index: 3;
          background-color: #303531;
        }
        &:nth-child(5) {
          text-align: right;
          > div {
            margin-left: auto;
          }
        }
        &:nth-child(1) {
          width: 20%;
          ${breakpointsMedias.max810} {
            width: 17%;
          }
          > div {
            padding-left: 11px;
          }
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          width: 20%;
          text-align: right;
          > div {
            margin-left: auto;
            margin-right: unset;
          }
        }
        &:nth-child(2) {
          width: 13%;
          ${breakpointsMedias.max810} {
            width: 12%;
          }
        }
        &:nth-child(3) {
          ${breakpointsMedias.max810} {
            width: 15%;
          }
        }
        &:nth-child(4) {
          width: 20%;
        }
        &:nth-child(5) {
          width: 15%;
          padding-left: 12px;
          min-width: unset;
          ${breakpointsMedias.max480} {
            width: 12%;
          }
        }
      }
      td {
        &:first-child {
          position: unset;
          left: unset;
          z-index: 1;
        }
        &:last-child {
          position: sticky;
          right: 0;
          z-index: 0;
          ${breakpointsMedias.max480} {
            > div {
              padding-left: 0;
            }
          }
        }
      }
    }
    .ld-table {
      min-height: 470px;
    }
  }
`;
