const formatTimer = (timer:number)=>{
  return String(timer).padStart(2,"0")
}
export function convertSecondToTimer(seconds: number) {
  const days = Math.floor(seconds / (3600 * 24)); 
  const remainingHour = seconds % (3600 * 24); 
  const hours = Math.floor(remainingHour / 3600); 
  const minutes = Math.floor((seconds% 3600) / 60); 
  const second = Math.floor((seconds% 60)); 

  return { days:formatTimer(days), hours:formatTimer(hours),minutes:formatTimer(minutes),second:formatTimer(second) }
}
