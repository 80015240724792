import StepSellPermission, {
  StepSellPermissionProps,
} from "components/ui/energy/StepSellPermission";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { configColor } from "configs/constants/configColor";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip as ReactTooltip } from "react-tooltip";
import styled from "styled-components";
import { EStatusPermission } from "types";
import StepEnd from "./step/StepEnd";
import StepSelect from "./step/StepSelect";
import SystemExtendSuccessfully from "./step/stepSuccess/SystemExtendSuccessfully";
import StepSelection from "./step/StepSelection";
import { useSystemExtendTeleContext } from "../contexts/SystemExtendTeleContext";
interface IMSE {
  onGoOrder: () => void
}

const ModalSystemExtend = ({ onGoOrder }: IMSE) => {
  const { t, i18n } = useTranslation();
  const { step, isLoading, onReset } = useSystemExtendTeleContext();

  const getProgressStep = (e: number): EStatusPermission => {
    if (e === step) {
      return EStatusPermission.ACTIVE;
    }
    if (e < step) {
      return EStatusPermission.PASS;
    }
    return EStatusPermission.NEXT;
  };

  const listStep: StepSellPermissionProps[] = [
    {
      step: 1,
      textStep: "extendStep1",
      status: getProgressStep(1),
    },
    {
      step: 2,
      textStep: "extendStep2",
      status: getProgressStep(2),
    },
    {
      step: 3,
      textStep: "extendStep3",
      status: getProgressStep(3),
    },
    {
      step: 4,
      textStep: "extendStep4",
      status: getProgressStep(4),
    },
  ];
  const onCloseAndResetForm = () => {
    onReset();
  };
  return (
    <Wrap>
      <div className="mbe-title">
        <p className="size-3 color-white">{t("extendOrder")}</p>
      </div>
      <WrapLayout>
        <StepHeading>
          <StepList className="mbe-list-step">
            {listStep.map((itemStep, index) => (
              <Fragment key={index}>
                <div
                  className={`step-icon ${itemStep.step <= step ? "step-active" : ""
                    }`}
                ></div>
                <div className={`step-item `}>
                  <StepSellPermission {...itemStep} />
                </div>
              </Fragment>
            ))}
          </StepList>
          <StepSubheading>
            {i18n.exists(`extendStep.${step}`) ? (
              <div>
                <p
                  className="size-1 subtitle"
                  style={{ color: configColor.gray4 }}
                >
                  {t(`extendStep.${step}`)}
                </p>
                {/* {i18n.exists(`extendStep.${step}1`) ? (
                    <p
                      className="size-1 subtitle"
                      style={{ color: configColor.gray4 }}
                    >
                      {t(`extendStep.${step}1`)}
                    </p>
                  ) : null} */}
              </div>
            ) : null}
          </StepSubheading>
        </StepHeading>

        <StepContent>
          <div className="mbe-content">
            {/* <ELStepContent step={step} /> */}
            {step === 1 && (
              <div className={`mbe-step ${step === 1 ? "active-step" : ""}`}>
                <StepSelect />
              </div>
            )}
            {step === 2 && (
              <div className={`mbe-step ${step === 2 ? "active-step" : ""}`}>
                <div className="step-content">
                  <StepSelection />
                </div>
              </div>
            )}
            {step === 3 && (
              <div className={`mbe-step ${step === 3 ? "active-step" : ""}`}>
                <div className="step-content">
                  <StepEnd />
                </div>
              </div>
            )}
            {step === 4 && (
              <div className={`mbe-step ${step === 4 ? "active-step" : ""}`}>
                <div className="step-content">
                  <SystemExtendSuccessfully onGoOrder={onGoOrder}/>
                </div>
              </div>
            )}
          </div>
        </StepContent>
      </WrapLayout>

      <ReactTooltip id="id-pay-address" />
    </Wrap>
  );
};

export default ModalSystemExtend;
const WrapLayout = styled.div`
  display: flex;
  flex-direction: column;
`;
const StepHeading = styled.div`
  margin-bottom: 24px;
  .subtitle {
    text-align: center;
  }
  .profit-share {
    color: var(--primary-green-400);
  }
  ${breakpointsMedias.max767} {
    margin-bottom: 10px;
  }
`;
const StepSubheading = styled.div`
  max-width: 80%;
  margin: 0 auto;
  ${breakpointsMedias.max767} {
    max-width: 100%;
  }
`;
const StepContent = styled.div``;
const StepList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  .step-icon {
    width: 40px;
    height: 1px;
    background-color: var(--grey-500);
    display: inline-block;
    &:first-child {
      display: none;
    }
    &.step-active {
      background-color: var(--primary-green-400);
    }
  }
  .step-item {
    position: relative;
  }
  ${breakpointsMedias.max767} {
    justify-content: center;
    gap: 4px;
    .step-icon {
      width: 12px;
    }
  }
`;
const Wrap = styled.div`
  padding: 16px 16px 16px;
  width: calc(100vw);
  border-radius: 12px;
  background: var(--secondary-bg-modal);
  /* height: 680px; */
  /* backdrop-filter: blur(20px); */
  .mbe-title {
    margin-bottom: 12px;
    text-align: center;
  }
  .layout-step {
    background: var(--secondary-surface-path, #303531);
    padding: 16px 12px;
    border-radius: 8px;
    height: 400px;
    /* height: 480px; */
    scrollbar-width: thin;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
      margin-top: 0;
    }
    &::-webkit-scrollbar-track {
      background: transparent !important;
      border-radius: 6px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: ${configColor.gray2} !important;
      border-radius: 6px !important;
    }
    &--auto {
      height: auto;
    }
  }
  .button-step {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: 24px;
    ${breakpointsMedias.max767} {
      button {
        min-width: 120px;
      }
    }
  }
  /* ${breakpointsMedias.min481}{
        min-width: 400px;
    } */
  ${breakpointsMedias.min768} {
    width: calc(100vw - 144px);
  }
  ${breakpointsMedias.min1024} {
  }
  ${breakpointsMedias.min1200} {
    width: 1070px;
    padding: 24px 72px;
    height: 100%;
    .mbe-title {
      margin-bottom: 24px;
    }
    .layout-step {
      padding: 16px 24px;
      height: 500px;
      &::-webkit-scrollbar {
        width: 6px !important;
        height: 6px !important;
        margin-top: 10px;
      }
    }
    .button-step {
      gap: 24px;
      margin-top: 32px;
    }
  }
  .mbe-content {
    .mbe-step {
      :not(.active-step, &:last-child) {
        position: relative;
        :before {
          content: "";
          width: 2px;
          height: 20px;
          background-color: #ccc;
          position: absolute;
          left: 10px;
          top: 30px;
        }
      }
      ${breakpointsMedias.min640} {
      }
    }
  }
`;
