import { formatCustom } from "helpers/formatNumber";
import { memo } from "react";
export type TOrderBookItem = {
  price: number | string;
  energy: number;
  status: "active" | "prev" | "next";
};
const listStatus = {
  active: "color-green size-22",
  prev: "",
  next: "color-gray",
};
const OrderBookItem = ({ price, energy, status }: TOrderBookItem) => {
  return (
    <tr>
      <td>
        <span className={`size-21 color-white ${listStatus[status]}`}>
          {price}
        </span>
      </td>
      <td>
        <span className={`size-21 color-white ${listStatus[status]}`}>
          {formatCustom(energy)}
        </span>
      </td>
    </tr>
  );
};

export default memo(OrderBookItem);
