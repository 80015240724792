import { useLazyQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import TextToolTipRename from "components/core/TextTooltipRename";
import {
  GET_ESTIMATE_EXTEND,
  GET_EXTENDABLE_DELEGATE_QUICK,
} from "configs/apolloSchema";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import {
  TDelegatesData,
  useQuickExtendContext,
} from "contexts/energy/QuickExtendContext";
import { formatCeil } from "helpers/formatNumber";
import { formatToken } from "helpers/formatToken";
import { useDebounce } from "helpers/hooks/useDebounce";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TDelegateDetail, TExtendAndBuyInput } from "types";
import {
  TTransactionType,
  useSystemExtendTeleContext,
} from "../../../contexts/SystemExtendTeleContext";
import { useTeleContext } from "../../../contexts/TeleContext";
import QuickInputExtendAmount from "./QuickInputExtendAmount";
import QuickInputForm from "./QuickInputForm";
const TIME_ONE_DAY = 24 * 60 * 60 * 1000;
const MAX_DAYS_DELEGATE = Number(process.env.REACT_APP_MAX_DAYS_DELEGATE ?? 0);
// const DURATION_EXTEND_DEFAULT = 1;
const ENERGY_EXTEND_DEFAULT = 0;
const MIN_ADDITIONAL_AMOUNT = 100000;
const QuickOptions = () => {
  const { t } = useTranslation();
  const { requester: address } = useTeleContext();
  const {
    onAddDelegates,
    counterExtend: initCounterExtend,
    delegatesAdditional,
  } = useQuickExtendContext();
  const {
    maxPrice,
    quickDuration,
    receiverAddress,
    onUpdateFieldExtendEnergy,
    transactions,
    onAddTransactionAll,
    onUpdateTransactionField,
    onLoadingStep,
    selectionMode,
    onResetTransactions,
  } = useSystemExtendTeleContext();
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState(maxPrice || "");
  const [duration, setDuration] = useState(quickDuration || "");
  const [amount, setAmount] = useState(0);
  const [counterExtend, setCounterExtend] = useState(initCounterExtend || 1);
  useEffect(() => {
    if (counterExtend !== initCounterExtend) {
      setCounterExtend(initCounterExtend);
    }
  }, [initCounterExtend]);
  const priceDebounce = useDebounce(price, 300);
  const durationDebounce = useDebounce(duration, 300);
  const amountDebounce = useDebounce(+amount, 300);
  const counterExtendDebounce = useDebounce(+counterExtend, 400);

  const [getExtendDelegate, { data, loading }] = useLazyQuery(
    GET_EXTENDABLE_DELEGATE_QUICK
  );

  useEffect(() => {
    if (
      +priceDebounce !== 0 &&
      +durationDebounce > 0 &&
      (+amountDebounce === 0 || +amountDebounce >= MIN_ADDITIONAL_AMOUNT)
    ) {
      const timeout = setTimeout(() => {
        getExtendDelegate({
          variables: {
            requester: address,
            receiver: receiverAddress,
            resourceType: "ENERGY",
            suggestData: {
              max_price: +price,
              extend_to:
                new Date().getTime() + +durationDebounce * TIME_ONE_DAY,
              extra_buy_amount: +amountDebounce,
              extend_amount: 0,
            },
          },
        });
      }, 200);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [durationDebounce, priceDebounce, amountDebounce]);

  const {
    extendDelegates,
    // maxExtendAmount,
    maxBuyAmount,
  }: {
    extendDelegates: TDelegatesData[];
    maxExtendAmount: number;
    maxBuyAmount: number;
  } = useMemo(() => {
    if (data?.rls_get_extendable_delegates && durationDebounce) {
      const {
        data: delegates,
        total_available_extra_buy_amount: maxBuyAmount,
      } = data?.rls_get_extendable_delegates;
      const maxExtendAmount = [...delegates].reduce(
        (acc: number, item: any) => acc + item.amount,
        0
      );
      const timeNow = new Date().getTime();
      const delegatesData: TDelegatesData[] = [...delegates]
        // .sort((a, b) => b.available_energy - a.available_energy)
        // .sort((a, b) => a.price - b.price)
        .filter(
          (item) =>
            item.extend_min_time_to <
              timeNow + +durationDebounce * TIME_ONE_DAY &&
            item.available_extend_at < timeNow &&
            !!item.is_extendable
        )
        .reduce((acc: TDelegatesData[], item: any) => {
          const {
            extend_price: extendPrice,
            address,
            amount,
            is_extendable: isExtendable,
            available_energy: availableEnergy,
            available_extend_at: unlockAt,
            suggest_data: {
              min_buy_more_price: minBuyMorePrice,
              max_buy_more_price: maxBuyMorePrice,
              suggest_extend: isSuggestExtend,
              suggest_buy_more_amount: suggestBuyMoreAmount,
            },
          } = item;
          if (maxBuyMorePrice <= +priceDebounce) {
            return [
              ...acc,
              {
                id: address,
                address,
                isExtendable,
                price: extendPrice,
                amount,
                availableEnergy,
                unlockAt,
                minBuyMorePrice,
                maxBuyMorePrice,
                isSuggestExtend,
                suggestBuyMoreAmount,
              },
            ];
          }
          return [...acc];
        }, []);

      return {
        extendDelegates: delegatesData.sort(
          (a, b) => a.maxBuyMorePrice - b.maxBuyMorePrice
        ),
        maxExtendAmount,
        maxBuyAmount,
      };
    }
    return {
      extendDelegates: [],
      maxExtendAmount: 0,
      maxBuyAmount: 0,
    };
  }, [data]);

  //set data reducer delegates quick extend
  useEffect(() => {
    onAddDelegates(extendDelegates, amountDebounce);
  }, [extendDelegates]);
  // update transaction then go to step 3
  useEffect(() => {
    if (extendDelegates.length) {
      const arrExtendAvailable = [...extendDelegates].filter(
        (item) => !!item.isSuggestExtend
      );
      //filter extend delegates buy max price buy more
      const arrExtendNoAvailable = [...extendDelegates]
        .filter((item) => !item.isSuggestExtend)
        .sort((a, b) => a.maxBuyMorePrice - b.maxBuyMorePrice);
      //merger filter extend delegates
      const dataMapping: TTransactionType[] = [
        ...arrExtendAvailable,
        ...arrExtendNoAvailable,
      ]
        .slice(0, counterExtendDebounce)
        .map((item, index) => {
          const { unlockAt, address, amount, suggestBuyMoreAmount } = item;
          return {
            id: address,
            address: address,
            energy: amount,
            energyExtended: suggestBuyMoreAmount || ENERGY_EXTEND_DEFAULT,
            unlockAt,
            duration: +durationDebounce,
            mode: suggestBuyMoreAmount ? "extendAndBuy" : "extendOnly",
            newPrice: null,
            payout: null,
            isExtend: true,
            isBlockMode: false,
            infoTransaction: [],
            extraBuyFaildureReason: [],
            extendFaildureReason: [],
          };
        });
      onAddTransactionAll([...dataMapping]);
    } else {
      onResetTransactions();
    }
  }, [
    extendDelegates,
    amountDebounce,
    counterExtendDebounce,
    initCounterExtend,
    delegatesAdditional,
  ]);
  const { totalExtendAmount } = useMemo(() => {
    if (extendDelegates.length === 0 || counterExtendDebounce.length === 0) {
      return {
        totalExtendAmount: 0,
        maxAdditionalAmount: 0,
      };
    }
    const arrExtendAvailable = [...extendDelegates].filter(
      (item) => !!item.isSuggestExtend
    );
    //filter extend delegates > MIN_ADDITIONAL_AMOUNT
    const arrExtendNoAvailable = [...extendDelegates]
      .filter((item) => !item.isSuggestExtend)
      .sort((a, b) => a.maxBuyMorePrice - b.maxBuyMorePrice);
    const { sumExtendAmount, sumBuyAmount } = [
      ...arrExtendAvailable,
      ...arrExtendNoAvailable,
    ]
      .slice(0, counterExtendDebounce)
      .reduce(
        (acc, item: any) => {
          return {
            sumExtendAmount: acc.sumExtendAmount + item?.amount || 0,
            sumBuyAmount: acc.sumExtendAmount + item?.availableEnergy || 0,
          };
        },
        { sumExtendAmount: 0, sumBuyAmount: 0 }
      );
    return {
      totalExtendAmount: sumExtendAmount,
      maxAdditionalAmount: sumBuyAmount,
    };
  }, [extendDelegates, counterExtendDebounce]);
  //create extends transactions

  //estimate payout
  const [arrExtendData, setArrExtendData] = useState<TExtendAndBuyInput[]>([]);
  useEffect(() => {
    if (+amountDebounce === 0 || +amountDebounce >= MIN_ADDITIONAL_AMOUNT) {
      setIsLoading(true);
      const dataMapping: TExtendAndBuyInput[] = [...transactions].map(
        (item) => {
          const { address, energyExtended, duration } = item;
          let is_extend = true;
          const extend_to = new Date().getTime() + duration * TIME_ONE_DAY;
          return {
            delegator: address,
            is_extend,
            extra_amount: energyExtended,
            extend_to,
          };
        }
      );
      const timerDelay = setTimeout(() => {
        setArrExtendData(dataMapping);
      }, 200);
      return () => {
        clearInterval(timerDelay);
      };
    }
  }, [transactions.length, amountDebounce, data]);
  const [getEstimateExtend, { data: dataEstimate, loading: loadingEstimate }] =
    useLazyQuery(GET_ESTIMATE_EXTEND);
  useEffect(() => {
    if (!!address && !!receiverAddress && arrExtendData.length > 0) {
      const timer = setTimeout(() => {
        getEstimateExtend({
          variables: {
            requester: address,
            resourceType: "ENERGY",
            receiver: receiverAddress,
            extendData: arrExtendData,
          },
          fetchPolicy: "no-cache",
        });
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setIsLoading(false);
    }
  }, [address, receiverAddress, arrExtendData]);
  const transactionFilterById = (id: string) => {
    return [...transactions].filter((item) => item.id === id)?.[0] || {};
  };
  useEffect(() => {
    if (transactions.length === 0) {
      setIsLoading(false);
    }
    if (dataEstimate?.rls_estimate_extend_and_buy_payout) {
      const {
        total_estimate_trx: totalPayout,
        total_extend_amount: totalExtendAmount,
        total_extra_buy_amount: totalExtraBuyAmount,
        estimate_data,
      } = dataEstimate.rls_estimate_extend_and_buy_payout;
      //update total payout
      onUpdateFieldExtendEnergy({
        totalPayout,
        totalExtendAmount,
        totalExtraBuyAmount,
      });
      //duration transaction extend
      const durationTransaction = +duration || 1;
      //check change all data item when new data
      estimate_data.length > 0 &&
        [...estimate_data].forEach((estimateItem) => {
          if (Object.keys(estimateItem).length > 0) {
            const {
              address: idItem,
              extra_buy_failure_reasons: extraBuyFaildureReason,
              extend_failure_reasons: extendFaildureReason,
              extra_buy_price: price,
              estimate_extend_trx: estimateTRXExtend,
              estimate_extra_buy_trx: estimateTrxBuyMore,
              cal_extend_duration: durationExtend,
              cal_extra_buy_duration: durationExtraBuy,
              extra_buy_price: newPrice,
              extend_amount: extendAmount,
              extra_buy_amount: energyBuyMore,
            } = estimateItem;
            const transactionActiveItem = transactionFilterById(idItem);

            //detail delegate show modal
            const dateNow = new Date().getTime();
            let delegateExtend: TDelegateDetail[] = [];
            // let delegateDetails: TDelegateDetail[] = [];
            let totalPayoutTransaction = 0;

            if (+estimateTRXExtend > 0) {
              delegateExtend.push({
                id: "1",
                type: "extend",
                energy: extendAmount,
                timeFrom: dateNow,
                timeTo: dateNow + durationTransaction * TIME_ONE_DAY,
                price,
                trxPaid: formatToken({
                  type: "devided",
                  value: estimateTRXExtend,
                }),
                duration: durationExtend,
              });
            }
            let delegateBuyMoreEnergy: TDelegateDetail[] = [];
            if (+estimateTrxBuyMore > 0) {
              let timeTo = dateNow + durationTransaction * TIME_ONE_DAY;
              delegateBuyMoreEnergy.push({
                id: "2",
                type: "buyMoreEnergy",
                energy: energyBuyMore,
                timeFrom: dateNow,
                timeTo,
                price,
                trxPaid: formatToken({
                  type: "devided",
                  value: estimateTrxBuyMore,
                }),
                duration: durationExtraBuy,
              });
            } else {
              delegateBuyMoreEnergy = [];
            }
            totalPayoutTransaction = new BigNumber(estimateTRXExtend)
              .plus(estimateTrxBuyMore)
              .toNumber();
            const delegateDetails = [
              ...delegateExtend,
              ...delegateBuyMoreEnergy,
            ];

            let isExtend = false;
            switch (true) {
              case (transactionActiveItem.mode === "extendOnly" ||
                transactionActiveItem.mode === "extendAndBuy") &&
                extendFaildureReason.length > 0: {
                isExtend = false;
                break;
              }
              case (transactionActiveItem.mode === "buyMoreOnly" ||
                transactionActiveItem.mode === "extendAndBuy") &&
                extraBuyFaildureReason.length > 0: {
                isExtend = false;
                break;
              }
              default:
                isExtend = true;
                break;
            }
            //udpate transaction when data change
            onUpdateTransactionField(idItem, {
              infoTransaction: [...delegateDetails],
              newPrice,
              extendFaildureReason,
              extraBuyFaildureReason,
              payout: formatToken({
                type: "devided",
                value: totalPayoutTransaction,
              }),
              isExtend,
            });
            //change new price item table when price change
          }
        });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [transactions.length, dataEstimate]);
  useEffect(() => {
    if (loading || isLoading || loadingEstimate) {
      onLoadingStep(true);
    } else {
      const timeout = setTimeout(() => {
        onLoadingStep(false);
      }, 1100);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [loading, isLoading, loadingEstimate]);
  //when change tab then update data advance
  useEffect(() => {
    if (selectionMode === "quick" && priceDebounce && durationDebounce) {
      onUpdateFieldExtendEnergy({
        maxPrice: +priceDebounce,
        // additionalAmount: amount,
        quickDuration: +durationDebounce,
      });
    }
  }, [selectionMode, priceDebounce, durationDebounce]);
  const { isDisabledForm, errorForm } = useMemo(() => {
    if (+priceDebounce === 0 || +durationDebounce === 0) {
      if (transactions.length > 0) {
        onAddTransactionAll([]);
      }
      return { isDisabledForm: true, errorForm: t("youMustEnterFullill") };
    }
    if (
      +priceDebounce > 0 &&
      +durationDebounce > 0 &&
      extendDelegates.length === 0 &&
      !loading
    ) {
      if (transactions.length > 0) {
        onAddTransactionAll([]);
      }
      return { isDisabledForm: true, errorForm: t("noDelegateFound") };
    }
    return { isDisabledForm: false, errorForm: "" };
  }, [
    durationDebounce,
    priceDebounce,
    extendDelegates.length,
    loading,
    transactions.length,
  ]);
  return (
    <Wrap>
      <div className="qoc-item">
        <div className="qoc-item__heading">
          <TextToolTipRename
            sizeText={1}
            text="maxPrice"
            tooltipContent="maxPriceTooltip"
          />
        </div>
        <div className="qoc-item__content">
          <QuickInputForm
            value={price}
            onChange={setPrice}
            type="sun"
            maxWidth="50px"
          />
        </div>
      </div>
      <div className="qoc-item">
        <div className="qoc-item__heading">
          <TextToolTipRename
            sizeText={1}
            text="extendDurations"
            tooltipContent="extendDurationsTooltip"
          />
        </div>
        <div className="qoc-item__content">
          <QuickInputForm
            value={duration}
            onChange={setDuration}
            maxWidth="30px"
            type="duration"
            maxValue={MAX_DAYS_DELEGATE}
          />
        </div>
      </div>

      <div className={`qoc-item ${isDisabledForm ? "qoc-item--disabled" : ""}`}>
        <div className="qoc-item__heading">
          <TextToolTipRename
            sizeText={1}
            text="additionalAmount"
            tooltipContent="quick.additionalAmountTooltip"
          />
        </div>
        <div className="qoc-item__content">
          <QuickInputForm
            value={amount}
            onChange={setAmount}
            maxWidth="100px"
            minValue={MIN_ADDITIONAL_AMOUNT}
            maxValue={maxBuyAmount}
            // maxValue={maxAdditionalAmount}
          />
        </div>
      </div>
      <div
        className={`qoc-item qoc-item--extend-amount ${
          isDisabledForm ? "qoc-item--disabled" : ""
        }`}
      >
        <div className="qoc-item__heading">
          <TextToolTipRename
            sizeText={1}
            text="extendAmount"
            tooltipContent="extendAmountTooltip"
          />
          <p className="size-1 color-white">
            ({formatCeil(totalExtendAmount, "0,0")} {t("energy")})
          </p>
        </div>
        <div className="qoc-item__content">
          <QuickInputExtendAmount
            min={initCounterExtend}
            max={extendDelegates.length}
            value={counterExtend}
            onChange={setCounterExtend}
          />
        </div>
      </div>

      {/* { isDisabledForm && (
        <div className="qoc-error">
          <p className="size-2 color-red">{errorForm}</p>
        </div>
      )} */}

      {isDisabledForm && (
        <div className="qoc-error">
          <p className="size-2 color-red">{errorForm}</p>
        </div>
      )}
    </Wrap>
  );
};

export default QuickOptions;

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 30px;
  align-items: start;
  margin-bottom: 40px;
  position: relative;
  .qoc-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    position: relative;
    &--disabled {
      :before {
        content: "";
        width: calc(100% + 6px);
        height: calc(100% + 30px);
        /* background-color: rgba(255, 255, 255, 0.5); */
        /* background-blend-mode: overlay; */
        position: absolute;
        left: -3px;
        top: 0;
        z-index: 2;
        backdrop-filter: blur(9px);
      }
    }
    &__heading {
    }
    &__content {
    }
    &--extend-amount {
      flex-direction: column;
      justify-content: start;
      align-items: start;
      width: 100%;
      .qoc-item__heading {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .qoc-item__content {
        width: 100%;
      }
    }
  }
  .qoc-error {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    width: 100%;
    text-align: center;
  }
  ${breakpointsMedias.max991} {
    gap: 20px 15px;
    margin-bottom: 24px;
    /* .qoc-item {
      &--extend-amount {
        margin-top: 20px;
      }
    } */
  }
  ${breakpointsMedias.max767} {
    grid-template-columns: 1fr;
    gap: 8px;
    margin-bottom: 20px;
    .qoc-item {
      gap: 5px;
      &--extend-amount {
        margin-top: 20px;
      }
    }
  }
`;
