import NextStep from "components/core/NextStep";
import ResourceValueType from "components/core/ResourceValueType";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
type SelectionPayoutProps = {
  totalPayout: number;
  loading: boolean;
  onShowDetail: () => void;
};
const SelectionPayout = ({
  totalPayout,
  loading,
  onShowDetail,
}: SelectionPayoutProps) => {
  const { t } = useTranslation();
  return (
    <Wrap>
      <p className="size-1 color-white dropdown-title">{t("totalPayout")}</p>
      <WrapPayout>
        <ResourceValueType value={totalPayout} type="TRX" />
        <div className="tei-detail">
          <NextStep
            typeStep="border"
            text="detail"
            size="small"
            onClick={onShowDetail}
            disabled={loading}
          />
        </div>
      </WrapPayout>
    </Wrap>
  );
};

export default SelectionPayout;
const Wrap = styled.div`
  .dropdown-title {
    margin-bottom: 10px;
    text-align: center;
  }
  ${breakpointsMedias.max1024} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .dropdown-title {
      margin-bottom: 0;
    }
  }
`;
const WrapPayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  ${breakpointsMedias.max1024} {
    flex-direction: row;
    align-items: center;
  }
  ${breakpointsMedias.max767} {
  }
`;
