import { useLockBodyScroll } from "helpers/hooks/useLockBodyScroll";
import ModalWrap from "components/core/ModalWrap";
import NotifySetting from "./NotifySetting";

interface IModalSuccess {
    onClose: () => void;
}

const ModalTeleNotify = ({ onClose }: IModalSuccess) => {
    useLockBodyScroll();

    return (
        <ModalWrap onClose={onClose} showCloseBt={true} clickOutsideToClose={true}>
            <NotifySetting showTele={true} />
        </ModalWrap>
    );
};

export default ModalTeleNotify;