import styled from "styled-components";
import { useLockBodyScroll } from "helpers/hooks/useLockBodyScroll";
import ModalWrap from "components/core/ModalWrap";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import WrapInput from "components/core/WrapInput";
import { useEffect, useMemo, useState } from "react";
import CheckBox from "components/core/CheckBox";
import { configColor } from "configs/constants/configColor";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useApolloClient } from "@apollo/client";
import { GET_CONTACT_TELE, SET_TELE_NOTIFY, VERIFY_TELE_NOTIFY } from "configs/apolloSchema";
import { notifyToastify } from "helpers/ultilities/notifyToastify";
import { errorCode } from "helpers/errorCode";
import useSignToken from "helpers/energy/useSignToken";
import { useSearchParams } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import TextToolTipRename from "components/core/TextTooltipRename";

type DataSet = {
    id: string,
    alertThreshold: string,
    active: boolean,
    verifyCode: string,
}

type DataGet = {
    id: string,
    alertThreshold: number,
    active: boolean,
    verify: boolean,
}

enum EContactType {
    telegram
}

const MILISEC_PER_HOUR = 60 * 60 * 1000
const TELEBOT_NOTIFY = process.env.REACT_APP_BOT_TELEGRAM_NOTIFY || ""

interface INotifySetting {
    showTele: boolean
}

const NotifySetting = ({ showTele }: INotifySetting) => {
    const { t } = useTranslation();
    const client = useApolloClient();
    const { address, connected, wallet, signMessage } = useWallet();
    const [searchParams] = useSearchParams();
    const verifyCode = searchParams.get("verifyCode");
    const { onLogin, isSigned, error: signError } = useSignToken();
    const [firstGet, setFirstGet] = useState(false)

    const [data, setData] = useState<DataSet>({
        id: "",
        alertThreshold: "",
        active: true,
        verifyCode: "",
    })
    const [originData, setOriginData] = useState<DataGet>({
        id: "",
        alertThreshold: 0,
        active: true,
        verify: true,
    })
    const [loadingGet, setLoadingGet] = useState(false)
    const [loadingSet, setLoadingSet] = useState(false)
    const [loadingVerify, setLoadingVerify] = useState(false)

    const onSetTele = () => {
        if (address && connected) {
            // setLoadingSet(true);
            try {
                const query = client.mutate({
                    mutation: SET_TELE_NOTIFY,
                    variables: {
                        type: EContactType[0],
                        is_active: data.active,
                        value: data.id,
                        alert_threshold: Number(data.alertThreshold) * MILISEC_PER_HOUR
                    },
                    fetchPolicy: "no-cache"
                }).then(({ data: { rls_user_set_contact } }) => {
                    if (rls_user_set_contact) {
                        getInfoTele()
                        setLoadingSet(false);
                    }
                }, (error) => {
                    setLoadingSet(false);
                    notifyToastify({ type: "error", text: t(errorCode[error.message || ""] || error.message) })
                    console.log("Set tele info", error);
                })
            } catch (error: any) {
                setLoadingSet(false);
                notifyToastify({ type: "error", text: t(error?.message || "somethingsError") })
                console.log("Set tele err", error);
            }
        } else {
            setLoadingSet(false);
        }
    }

    const onVerify = () => {
        if (address && connected) {
            try {
                const query = client.mutate({
                    mutation: VERIFY_TELE_NOTIFY,
                    variables: {
                        contact_type: EContactType[0],
                        code_verify: data.verifyCode,
                    },
                    fetchPolicy: "no-cache"
                }).then(({ data: { rls_user_verify_contact } }) => {
                    if (rls_user_verify_contact) {
                        notifyToastify({ type: "success", text: t("verifyTeleSuccess") })
                        getInfoTele()
                        setLoadingVerify(false);
                    }
                }, (error) => {
                    setLoadingVerify(false);
                    notifyToastify({ type: "error", text: t(errorCode[error.message || ""] || error.message) })
                    console.log("Set tele info", error);
                })
            } catch (error: any) {
                setLoadingVerify(false);
                notifyToastify({ type: "error", text: t(error?.message || "somethingsError") })
                console.log("Set tele err", error);
            }
        } else {
            setLoadingVerify(false);
        }
    }

    const getInfoTele = () => {
        if (address && connected) {
            setLoadingGet(true);
            try {
                const query = client.watchQuery({
                    query: GET_CONTACT_TELE,
                    variables: {
                        address: address,
                        contact_type: EContactType[0]
                    },
                    fetchPolicy: "no-cache"
                })
                const querySubscribe = query.subscribe(({ data: { rls_user_contact_info } }) => {
                    if (rls_user_contact_info) {
                        setOriginData({
                            id: rls_user_contact_info?.value || "",
                            alertThreshold: (rls_user_contact_info?.alert_threshold || MILISEC_PER_HOUR) / MILISEC_PER_HOUR,
                            active: rls_user_contact_info?.is_active === false ? false : true,
                            verify: rls_user_contact_info?.is_verify || false,
                        });
                    }
                    setLoadingGet(false);
                }, (error) => {
                    setLoadingGet(false);
                    notifyToastify({ type: "error", text: t(errorCode[error.message || ""] || error.message) })
                    console.log("Get tele infor error", error);
                })
                return querySubscribe
            } catch (error: any) {
                setLoadingGet(false);
                notifyToastify({ type: "error", text: t(error?.message || "somethingsError") })
                console.log("Get tele infor err", error);
            }
        } else {
            setLoadingGet(false);
        }
    }


    const needUpdate = useMemo(() => {
        if (data.active !== originData.active) {
            return true
        }
        if (data.id !== originData.id) {
            return true
        }
        if (Number(data.alertThreshold) !== Number(originData.alertThreshold)) {
            return true
        }
        return false
    }, [data, originData])

    const onSignMessage = async (isVerify?: boolean) => {
        try {
            const TIME_EXPIRE = 24 * 60 * 60;
            if (connected && wallet?.adapter) {
                if (isVerify) {
                    setLoadingVerify(true)
                } else {
                    setLoadingSet(true)
                }

                const time = new Date().getTime()
                let signature = await signMessage(`rls_tronsave_${time}`);
                if (signature) {
                    await onLogin({
                        address: address || "",
                        timestamp: time,
                        signature,
                        duration_sec: TIME_EXPIRE
                    })
                } else {
                    if (isVerify) {
                        setLoadingVerify(false)
                    } else {
                        setLoadingSet(false)
                    }
                }
            }
        } catch (error) {
            console.log("ERROR SIGN MESSAGE", error)
            if (isVerify) {
                setLoadingVerify(false)
            } else {
                setLoadingSet(false)
            }
        }
    }

    const beforeSetTele = () => {
        if (isSigned) {
            setLoadingSet(true)
        } else {
            onSignMessage()
        }
    }

    const beforeVerifyTele = () => {
        if (isSigned) {
            setLoadingVerify(true)
        } else {
            onSignMessage()
        }
    }

    useEffect(() => {
        if (isSigned && loadingSet) {
            onSetTele()
        }
        if (isSigned && loadingVerify) {
            onVerify()
        }
    }, [loadingSet, isSigned, loadingVerify])

    useEffect(() => {
        if (signError) {
            setLoadingSet(false)
        }
    }, [signError])

    useEffect(() => {
        if (verifyCode) {
            setData({ ...data, verifyCode: verifyCode })
        }
    }, [])

    useEffect(() => {
        if (originData.id) {
            setData({
                ...data,
                id: originData.id,
                active: originData.active,
                alertThreshold: originData.alertThreshold.toString()
            })
        }
    }, [originData])

    useEffect(() => {
        if (address && connected && firstGet) {
            getInfoTele()
        }
    }, [address, connected, firstGet])

    useEffect(() => {
        if (showTele && !firstGet) {
            setFirstGet(true)
        }
    }, [showTele])

    return (
        <Wrap>
            <span className="size-2 color-green ns-title" >{t("teleNotifySetting")}</span>
            <span className="size-0 color-gray">{t("teleNotifySettingDesc")}</span>
            <div className="ns-action">
                <CheckBox checked={data.active} onCheck={() => {
                    setData({ ...data, active: !data.active })
                }} />
                <span className="size-1 color-white">{t("enableNotifyTelegram")}</span>
            </div>
            <div className="ns-item">
                <span className="size-1 color-white">{t("yourTeleId")}<span className="size-1 color-green"> *</span>:</span>
                <WrapInput >
                    <input
                        type="text"
                        placeholder={"@username"}
                        onChange={(e) => { setData({ ...data, id: e.target.value }) }}
                        value={data.id}
                        className="size-1 color-white"
                    />
                </WrapInput>
            </div>
            <div className="ns-item">
                <TextToolTipRename text={t("reclaimAlertThreshold")} tooltipContent="ttReclaimAlertThreshold" sizeText={1} />
                <WrapInput className="nsi-input">
                    <NumericFormat
                        inputMode="decimal"
                        value={data.alertThreshold}
                        onValueChange={(values: any) => {
                            setData({ ...data, alertThreshold: values.value })
                        }}
                        thousandSeparator={true}
                        placeholder={"0"}
                        className="size-1 color-white"
                        allowNegative={false}
                        decimalScale={0}
                    />
                    <span className="size-1 color-white">{t("hours")}</span>
                </WrapInput>
            </div>
            {(!originData.verify && !needUpdate) && <div className="ns-item ns-verify">
                <span className="size-1 color-white">{t("verifyCode")}:</span>
                <WrapInput >
                    <input
                        type="text"
                        placeholder={"..."}
                        onChange={(e) => { setData({ ...data, verifyCode: e.target.value }) }}
                        value={data.verifyCode}
                        className="size-1 color-white"
                    />
                </WrapInput>
                <a href={TELEBOT_NOTIFY} className="size-1 color-green tele-link" target="_blank" rel="noreferrer">
                    {t("getCodeAtHere")}
                </a>
            </div>}
            {needUpdate ? <div className="ns-bt">
                <Button
                    text={originData.id ? "update" : "confirm"}
                    onClick={beforeSetTele}
                    disable={!data.id || loadingGet || loadingSet || loadingVerify}
                />
            </div> : !originData.verify ? <div className="ns-bt">
                <Button
                    text="verify"
                    onClick={beforeVerifyTele}
                    disable={!data.verifyCode || loadingGet || loadingSet || loadingVerify}
                />
            </div> : null}
        </Wrap>
    );
};

export default NotifySetting;

const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: 100%;
    gap: 16px;
    .ns-title {
        margin-bottom: -10px;
    }
    .ns-action {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    .ns-item {
        display: flex;
        flex-direction: column;
        gap: 6px;
        .nsi-input {
            > input {
                flex: 1;
                margin-right: 12px;
            }
        }
    }
    .ns-verify {
        border-top: 1px solid ${configColor.gray3};
        padding-top: 10px;
        a {
            text-decoration: underline;
        }
    }
    .ns-bt {
        display: flex;
        max-width: 100px;
        margin-left: auto;
        > button {
            height: 40px;
        }
    }
    ${breakpointsMedias.max1199} {
        max-width: calc(100vw - 32px);
    }
`;
