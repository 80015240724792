import NextStep from "components/core/NextStep";
import TextToolTipRename from "components/core/TextTooltipRename";
import WrapInput from "components/core/WrapInput";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { configColor } from "configs/constants/configColor";
import { notifyToastify } from "helpers/ultilities/notifyToastify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Tronweb from "tronweb";
import { useSystemExtendTeleContext } from "../../contexts/SystemExtendTeleContext";
import { useTeleContext } from "../../contexts/TeleContext";
import TableSelect from "./stepSelect/TableSelect";

const StepSelect = () => {
  //check param target address receive from link
  const [searchParams] = useSearchParams();
  const { requester, getApiKey } = useTeleContext();
  const addressReceiverParams = searchParams.get("receiver_address");
  //wallet
  const { onNextStep, onAddressReceiver, receiverAddress } =
    useSystemExtendTeleContext();
  const { t } = useTranslation();
  const initAddress = addressReceiverParams || "";

  const [targetAddress, setTargetAddress] = useState(initAddress);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTargetAddress(e.target.value);
  };
  const ElErrorInput = () => {
    if (targetAddress !== "" && !Tronweb.isAddress(targetAddress)) {
      return <p className="color-red size-0">{t("addressIncorrect")}</p>;
    }
    return null;
  };
  const onNextExtend = (e: string) => {
    onAddressReceiver(e);
    onNextStep(2);
  };
  useEffect(() => {
    if (targetAddress === "" && receiverAddress) {
      setTargetAddress(receiverAddress);
    }
  }, [receiverAddress]);
  const onConnectWallet = () => {
    try {
      getApiKey((window as any).Telegram?.WebApp?.initData);
    } catch (error: any) {
      notifyToastify({ type: "error", text: error.message });
    }
  };
  return (
    <Wrap>
      <div className="step-content">
        <div className="layout-step">
          <StepForm className="ssc-form">
            <div className="ssc-form__title">
              <TextToolTipRename
                text="otherReceiver"
                tooltipContent="otherReceiverTooltip"
              />
            </div>
            <div className="ssc-input">
              <WrapInput>
                <input
                  value={targetAddress}
                  onChange={onChange}
                  placeholder={t("placeholderTargetAddress") ?? ""}
                  className="color-white size-2"
                />
              </WrapInput>
              <div className="ssc-input__warning">
                <ElErrorInput />

                {/* <p className="size-0 color-gray ">
                  *{t("extendStep1.warningTargetAddress")}
                </p> */}
              </div>
            </div>
            <div className="ssc-event">
              {requester ? (
                <NextStep
                  text="next"
                  onClick={() => onNextExtend(targetAddress)}
                  disabled={
                    targetAddress === "" ||
                    (targetAddress !== "" && !Tronweb.isAddress(targetAddress))
                  }
                />
              ) : (
                <NextStep text="connectWallet" onClick={onConnectWallet} />
              )}
            </div>
          </StepForm>
          <WrapTable>
            <TableSelect onSelect={onNextExtend} />
          </WrapTable>
        </div>
      </div>
    </Wrap>
  );
};

export default StepSelect;
const Wrap = styled.div`
  .step-content {
    position: relative;
    .step1-balance {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: center;
      padding: 4px 0;
      margin-bottom: 24px;
      .scb-icon-stake {
        padding: 5px 15px;
        background-color: ${configColor.green};
        border-radius: 5px;
        cursor: pointer;
        :hover {
          color: ${configColor.colorWhite};
        }
        ${breakpointsMedias.max1024} {
          padding: 3px 15px;
        }
      }
      &__title {
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
    }
    &__balance {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
    }
    &__progress {
      background: var(--secondary-progress-bar-bg);
      width: 100%;
      height: 12px;
      border-radius: 8px;
      overflow: hidden;
      > p {
        background: var(--primary-green-200);
        height: 100%;
        transition: width 2s ease;
        border-radius: 8px;
      }
    }
    &__warning {
      margin-top: 20px;
    }
  }
  ${breakpointsMedias.max767} {
    .step-content {
      .step1-balance {
        gap: 20px 10px;
        flex-wrap: wrap;
      }
      &__balance {
        margin-bottom: 8px;
      }
      &__warning {
        margin-top: 16px;
      }
    }
  }
`;

const StepForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 30px;
  .ssc-input {
    position: relative;
    flex: 1;
    input {
      width: 100%;
    }
    &__warning {
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
    }
  }
  ${breakpointsMedias.max1024} {
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    .ssc-form {
      &__title {
        flex-basis: 100%;
      }
    }
    .ssc-input {
      flex-basis: 100%;
    }
    .ssc-event {
      flex-basis: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;
const WrapTable = styled.div`
  padding: 16px 0;
  /* background-color: var(--secondary-bg-modal); */
  border-radius: 8px;
  ${breakpointsMedias.max1024} {
    padding: 0;
    background-color: transparent;
  }
`;
