import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { configColor } from "configs/constants/configColor";
import { useTranslation } from "react-i18next";
import imgMenuActive from "assets/images/menu-item-shadow.png";
import { useWidthScreen } from "helpers/hooks/useWidthScreen";
import PickLangMobile from "components/core/PickLangMobile";
import NotifySetting from "./NotifySetting";
import { FiSettings } from "react-icons/fi";
import { useState } from "react";
import ModalTeleNotify from "./ModalTeleNotify";

interface IMenu {
  onClose: () => void;
}
const LINK_DOCS = process.env.REACT_APP_DOCS ?? "";
const LINK_DOCS_CHINESE = process.env.REACT_APP_DOCS_CHINESE ?? "";

const IS_HIDDEN_AIRDROP =
  process.env.REACT_APP_IS_HIDDEN_AIRDROP === "true" ? true : false;
export const MENU_LIST = [
  // {
  //   text: "wallet",
  //   link: "https://savewallet.io/",
  // },
  {
    text: "market",
    link: "/market",
  },
  {
    text: "tools",
    link: "https://tools.tronsave.io/",
    target: true,
  },
  {
    text: "airdrop",
    link: "/airdrop",
  },
  {
    text: "document",
    link: "https://docs.tronsave.io/introduction/what-is-tronsave",
    target: true,
  },
  // {
  //   text: "demo",
  //   link: LINK_TESTNET_DEMO,
  //   target: LINK_TESTNET_DEMO === "/demo" ? false : true,
  // },
  // {
  //   text: "partner",
  //   link: LINK_DASHBOARD,
  //   target: true,
  // },
];

const Menu = ({ onClose }: IMenu) => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const { width } = useWidthScreen();
  const [showTeleSetting, setShowTeleSetting] = useState(false);

  return (
    <Wrap>
      <div className={`menu-list`}>
        <a
          href={"https://savewallet.io/"}
          target={"_blank"}
          rel="noreferrer"
          className={`menu-item`}
          onClick={() => {
            onClose();
          }}
        >
          <span className={`size-2 color-light glow`}>{t("wallet")}</span>
        </a>
        {MENU_LIST.filter(
          (item) => !(IS_HIDDEN_AIRDROP && item.text === "airdrop")
        ).map((item, index) => (
          <a
            href={
              item.text === "document"
                ? i18n.language === "zh"
                  ? LINK_DOCS_CHINESE
                  : LINK_DOCS
                : item.link
            }
            key={index}
            target={item?.target ? "_blank" : "_self"}
            rel="noreferrer"
            className={`menu-item ${pathname.split("/")[1] === item.text ||
                (item.text === "home" && pathname.split("/")[1] === "")
                ? "menu-item-active"
                : ""
              }`}
            onClick={() => {
              onClose();
            }}
          >
            <span className={`size-2 color-light`}>{t(item.text)}</span>
          </a>
        ))}
        {width < 1200 && (
          <>
            <PickLangMobile />
            <div
              className="tele-setting"
              onClick={(e) => {
                e.stopPropagation();
                setShowTeleSetting(!showTeleSetting);
              }}
            >
              <span className={`size-2 color-green`}>
                {t("teleNotifySetting")}
                <FiSettings />
              </span>
            </div>
          </>
        )}
      </div>
      {showTeleSetting && (
        <ModalTeleNotify
          onClose={() => {
            setShowTeleSetting(false);
          }}
        />
      )}
    </Wrap>
  );
};

export default Menu;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
  background-color: #1a1a1a;
  position: relative;
  padding: 77px 16px 4px 16px;
  .menu-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 4px;
    z-index: 2;
    border-top: 1px solid #444944;
    padding-top: 20px;
    .menu-item {
      /* margin-bottom: 16px; */
      cursor: pointer;
      color: ${configColor.colorWhite};
      display: flex;
      align-items: center;
      text-transform: uppercase;
      width: fit-content;
      margin-bottom: 16px;
      padding: 12px 24px;
      position: relative;
      > span {
        display: flex;
        align-items: center;
        transition: 0.3s;
      }
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0px;
        height: 4px;
        border-radius: 4px;
        transform: translateX(-50%);
        transition: 0.3s;
        background-color: #ffff;
      }
      &:hover {
        > span {
          color: ${configColor.colorWhite};
        }
        &::after {
          width: 20px;
        }
      }
    }
    .menu-item-active {
      background-image: url(${imgMenuActive});
      background-size: 100% 100%;
      > span {
        color: ${configColor.colorWhite};
      }
      &::after {
        width: 22px;
      }
    }
    .tele-setting {
      cursor: pointer;
      margin-bottom: 20px;
      > span {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  ${breakpointsMedias.min1200} {
    flex-direction: row;
    background-color: transparent;
    max-width: unset;
    background: none;
    padding: 0;
    .menu-list {
      border: none;
      flex-direction: row;
      align-items: center;
      padding-top: 0;
      padding: 0;
      justify-content: center;
      .menu-item {
        margin: 0;
        padding: 8px 16px;
        ${breakpointsMedias.min1360} {
          padding: 12px 24px;
        }
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0px;
          height: 4px;
          border-radius: 4px;
          transform: translateX(-50%);
          transition: 0.3s;
          background-color: #ffff;
        }
      }
      .menu-item-active {
        &::after {
          width: 22px;
        }
      }
    }
  }
`;
