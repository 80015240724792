import BigNumber from "bignumber.js";
import ResourceValueType from "components/core/ResourceValueType";
import dayjs from "dayjs";
import { formatCustom } from "helpers/formatNumber";
import { subStringAddress } from "helpers/subStringAddress";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TTransactionDetail } from "types";
const HOUR_PER_DAY = 24;
const TableEndItem = ({
  id,
  address,
  amount,
  duration,
  unlockAt,
  payout,
  price,
}: TTransactionDetail) => {
  const { t } = useTranslation();
  const timeSplit = duration.toString().split(".");
  const hours = Math.ceil(
    new BigNumber(timeSplit?.[1]?.slice(0, 2) || 0)
      .div(100)
      .multipliedBy(HOUR_PER_DAY)
      .toNumber()
  );
  return (
    <Wrap>
      <td>
        <p className="size-1 color-white">
          {subStringAddress({ text: address, length: 5 })}
        </p>
      </td>
      <td>
        <p className="size-1 color-white">{formatCustom(amount, "0,0")}</p>
      </td>
      <td>
        <p className="size-1 color-white nowrap">{formatCustom(price)} SUN</p>
      </td>
      <td>
        <p className="size-1 color-white nowrap">
          {formatCustom(Math.max(duration, 1), "0,0") +
            " " +
            t(duration > 1 ? "days" : "day")}
          &nbsp;{hours > 0 && `${formatCustom(hours, "0,0")} hours`}
        </p>
      </td>
      <td>
        <p className="size-1 color-white nowrap">
          {dayjs(unlockAt).format("YYYY-MM-DD HH:mm")}
        </p>
      </td>
      <td>
        <ResourceValueType value={payout} type="TRX" />
      </td>
    </Wrap>
  );
};

export default TableEndItem;
const Wrap = styled.tr`
  position: relative;
  &.show {
    height: 230px;
  }
  .tei-check {
    width: 20px;
    height: 20px;
    border: 1px solid var(--grey-400);
    cursor: pointer;
    position: relative;
    border-radius: 2px;
    &--checked {
      border: 1px solid var(--primary-green-400);
      background-color: var(--primary-green-400);
    }
    &__icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      svg {
        display: inline-flex;
      }
    }
  }
  .tei-detail {
    /* max-width: 60px; */
    button {
      min-width: 120px;
      padding: 4px 12px;
    }
  }
  .tei-price {
    display: flex;
    align-items: center;
    gap: 10px;
    .underline {
      text-decoration: line-through;
      color: var(--grey-600);
    }
  }
`;
