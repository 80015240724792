import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  InMemoryCacheConfig,
  NormalizedCacheObject,
  split,
} from "@apollo/client";

import { setContext } from "apollo-link-context";
import LoadingScreen from "components/core/LoadingScreen";
import { useContext, useEffect, useRef, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { TronContexts } from "./TronContexts";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";

const HTTP_LINK = process.env.REACT_APP_LINK_HTTP ?? "";
const WS_LINK = process.env.REACT_APP_LINK_WS ?? "";
const configApollo = {
  graphql: HTTP_LINK,
  wsGraphql: WS_LINK,
};

const Apollo = ({ children }: any) => {
  const { address, connected } = useWallet();
  const [cookies, setCookie] = useCookies([`token-${address}`]);
  const isInit = useRef(true);
  let initClient: ApolloClient<NormalizedCacheObject> | undefined = undefined;
  const [client, setClient] = useState(initClient);
  const { changeWsReconnected, wsReconnected } = useContext(TronContexts);
  const setupApollo = () => {
    const cache = new InMemoryCache();
    const httpLink = new HttpLink({
      uri: configApollo.graphql,
      credentials: "same-origin",
    });

    const wsClient = createClient({
      url: configApollo.wsGraphql,
      retryAttempts: 4500,
    });
    //
    wsClient.on("connected", () => {
      changeWsReconnected(true);
    });
    wsClient.on("closed", () => {
      changeWsReconnected(false);
    });
    //
    const wsLink = new GraphQLWsLink(wsClient);
    const authLink = setContext((_, { headers }) => {
      const Cookie = new Cookies();
      const token = Cookie.get(`token-${address}`);
      let header = headers;
      if (address && !!connected) {
        header = {
          headers: {
            ...headers,
            authorization: headers?.Authorization || token,
          },
        };
      } else {
        header = headers;
      }
      // console.log({ header, token })
      return header;
    });
    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink as any,
      authLink.concat(httpLink as any) as any
      // httpLink as any
    );
    // const link = authLink.concat(httpLink as any) as any
    setClient(new ApolloClient({ cache, link }) as any);
  };
  const initConfig = async () => {
    setupApollo();
    isInit.current = false;
  };
  useEffect(() => {
    initConfig();
  }, [cookies, address]);
  return !!client ? (
    <ApolloProvider client={client as any}>{children}</ApolloProvider>
  ) : (
    <LoadingScreen />
  );
};
export default Apollo;
