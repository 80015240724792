import { useQuery } from "@apollo/client";
import LoadingSpinner from "components/core/LoadingSpinner";
import { GET_ACTIVE_RECEIVERS } from "configs/apolloSchema";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useTeleContext } from "../../../contexts/TeleContext";
import TableSelectItem from "./TableSelectItem";
export type TListSelectItem = {
  address: string;
  lastOrder: number;
};
type TableSelectProps = {
  onSelect: (address: string) => void;
};

const TableSelect = ({ onSelect }: TableSelectProps) => {
  const { requester: address } = useTeleContext();
  const { t } = useTranslation();
  const initOwnerRecievers: TListSelectItem[] = [
    {
      address: address || "",
      lastOrder: -1,
    },
  ];
  const { data, loading } = useQuery(GET_ACTIVE_RECEIVERS, {
    variables: { address, resourceType: "ENERGY" },
    skip: !address,
    fetchPolicy: "no-cache",
  });
  const receivers: TListSelectItem[] = useMemo(() => {
    if (!address) {
      return [];
    }
    if (data?.rls_get_active_receivers.length) {
      const dataMapping: TListSelectItem[] = data?.rls_get_active_receivers.map(
        (item: any) => {
          return {
            address: item.address,
            lastOrder: dayjs(item.expired_at).valueOf(),
          };
        }
      );
      //check duplicate owner address when server return
      const findIndex = [...dataMapping].findIndex(
        (item) => item.address === initOwnerRecievers[0].address
      );
      if (findIndex === -1) {
        return [...initOwnerRecievers, ...dataMapping];
      } else {
        const ownerData = [...dataMapping][findIndex];
        return [ownerData, ...dataMapping.filter((i) => i.address !== address)];
      }
    }
    return [...initOwnerRecievers];
  }, [data, address]);
  return (
    <Wrap
      className={`scrollbar ${loading || receivers.length === 0 ? "show" : ""}`}
    >
      <table>
        <thead>
          <tr>
            <th>
              <p className="size-1 color-white">{t("address")}</p>
            </th>
            <th>
              <p className="size-1 color-white">{t("lastOrder")}</p>
            </th>
          </tr>
        </thead>
        <tbody className="relative">
          {(loading || receivers.length === 0) && (
            <div className="tsc-status">
              {loading && (
                <div className="tsc-status__item">
                  <LoadingSpinner />
                </div>
              )}
              {!loading && receivers.length === 0 && (
                <div className="tsc-status__item">
                  <p>{t("noData")}</p>
                </div>
              )}
            </div>
          )}
          {receivers.length > 0 &&
            receivers.map((item, index) => (
              <TableSelectItem
                key={index}
                {...item}
                isCurrent={address === item.address}
                onClick={() => onSelect(item.address)}
              />
            ))}
        </tbody>
      </table>
    </Wrap>
  );
};

export default TableSelect;
const Wrap = styled.div`
  display: block;
  max-height: 340px;
  height: 340px;
  overflow: auto;
  table {
    width: 100%;
    display: table;
    thead {
      position: sticky;
      top: 0;
      background-color: #252a26;
      z-index: 3;
      /* border-bottom: 1px solid var(--grey-600); */

      th {
        padding: 10px 10px;
        background-color: #252a26;
      }
    }
    tbody {
    }
    tr {
      td {
        padding: 10px 10px;
      }
      &:first-child {
        text-align: left;
      }
    }
  }
  &.show {
    table {
      tbody {
        position: relative;
        height: 200px;
        .tsc-status {
          display: flex;
          justify-content: center;
          width: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  ${breakpointsMedias.max1024} {
    max-height: 360px;
    height: 360px;
  }
  ${breakpointsMedias.max767} {
  }
`;
