import { useQuery } from "@apollo/client";
import BigNumber from "bignumber.js";
import LoadingSpinner from "components/core/LoadingSpinner";
import NoData from "components/core/NoData";
import { GET_EXTENDS_DELEGATE } from "configs/apolloSchema";
import { breakpointsMedias } from "configs/constants/breakpointMedias";

import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCaretDown } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import styled from "styled-components";
import { TFieldSortExtend, TTypeSort } from "types";
import {
  TTransactionType,
  useSystemExtendTeleContext,
} from "../../../contexts/SystemExtendTeleContext";
import { useTeleContext } from "../../../contexts/TeleContext";
import TableExtendItem from "./TableExtendItem";
import OptionsSort from "./tableSelection/OptionsSort";
type TSortExtend = {
  field: TFieldSortExtend;
  type: TTypeSort;
};
type TElThead = {
  id: string;
  idSort?: TFieldSortExtend;
  title: string;
  onHandleSort?: () => void;
  isShort?: boolean;
};
export type TExtendDelegate = {
  id: string;
  address: string;
  resourceAmount: number;
  unlockAt: number;
  price: number;
  newPrice: number;
  minExtendAmount: number;
  maxExtendAmount: number;
  amount: number;
  availableExtendAt: number;
  extendMinTimeTo: number;
  disabled?: boolean;
};

const DURATION_EXTEND_DEFAULT = 1;
const ENERGY_EXTEND_DEFAULT = 0;
const MAX_DAYS_DELEGATE = Number(process.env.REACT_APP_MAX_DAYS_DELEGATE);
const TIME_ONE_DAY = 24 * 60 * 60 * 1000;

const TableExtend = () => {
  const { requester: address } = useTeleContext();
  const {
    modeGlobal,
    transactions,
    durations,
    onAddTransaction,
    onDeleteTransaction,
    receiverAddress,
    onAddTransactionAll,
    onResetTransactions,
    maxPrice,
  } = useSystemExtendTeleContext();
  const [sort, setIsSort] = useState<TSortExtend>({
    field: "amount",
    type: "asc",
  });
  //sort
  const onSort = ({ field = "amount", type = "asc" }: Partial<TSortExtend>) => {
    if (field === sort.field) {
      type = sort.type === "asc" ? "desc" : "asc";
      type = sort.type === "desc" ? "asc" : "desc";
      setIsSort({ field, type });
      return;
    }
    setIsSort({ field, type });
  };
  const theadData: TElThead[] = [
    {
      id: "delegate",
      title: "delegator",
    },
    {
      id: "amount",
      title: "resourceAmount",
      isShort: true,
      onHandleSort: () => onSort({ field: "amount" }),
      idSort: "amount",
    },
    {
      id: "exprire",
      title: "expiredAt",
      isShort: true,
      onHandleSort: () => onSort({ field: "exprire" }),
      idSort: "exprire",
    },
    {
      id: "price",
      title: "extend.price",
      isShort: true,
      onHandleSort: () => onSort({ field: "price" }),
      idSort: "price",
    },
    {
      id: "selection",
      title: "selection",
    },
  ];
  //get extends delegate
  const { data, loading } = useQuery(GET_EXTENDS_DELEGATE, {
    variables: {
      requester: address,
      resourceType: "ENERGY",
      receiver: receiverAddress,
    },
    skip: !receiverAddress || !address,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });
  const [delegates, setDelegates] = useState<TExtendDelegate[]>([]);
  const onChangeDelegatePrice = (id: string, price: number) => {
    const prevDelegates = [...delegates];
    const index = prevDelegates.findIndex((item) => {
      return item.id === id;
    });
    if (index !== -1) {
      prevDelegates[index].newPrice = price;
      setDelegates([...prevDelegates]);
    }
  };
  useEffect(() => {
    if (data?.rls_get_extendable_delegates?.data.length) {
      const timeNow = new Date().getTime();
      const dataMapping = data?.rls_get_extendable_delegates?.data.map(
        (item: any) => {
          const {
            address,
            available_energy,
            reclaim_time,
            amount,
            extend_price,
            available_extend_at: availableExtendAt,
            extend_min_time_to: extendMinTimeTo,
          } = item;
          const dayExtendLimit = new BigNumber(extendMinTimeTo - timeNow)
            .div(TIME_ONE_DAY)
            .toFixed(2);

          const transactionIndex = [...transactions].findIndex(
            (transaction) => transaction.id === address
          );
          let disabled = false;
          switch (true) {
            case +dayExtendLimit >= MAX_DAYS_DELEGATE: {
              disabled = true;
              break;
            }
            case !!maxPrice: {
              if (transactionIndex !== -1) {
                const newPrice =
                  [...transactions]?.[transactionIndex]?.newPrice ?? 0;
                if (newPrice > maxPrice) {
                  disabled = true;
                  break;
                }
              } else {
                if (extend_price > maxPrice) {
                  disabled = true;
                  break;
                }
              }
              disabled = false;
              break;
            }
            default:
              disabled = false;
              break;
          }
          return {
            id: address,
            address: address,
            resourceAmount: amount,
            unlockAt: reclaim_time,
            price: extend_price,
            newPrice:
              transactionIndex === -1
                ? extend_price
                : [...transactions][transactionIndex].newPrice,
            minExtendAmount: 100000,
            maxExtendAmount: available_energy,
            amount: amount,
            availableExtendAt,
            extendMinTimeTo,
            disabled,
          } as TExtendDelegate;
        }
      );
      setDelegates(dataMapping);
    }
  }, [data, transactions, maxPrice]);
  const { t } = useTranslation();
  const onCheckChange = ({
    id,
    address,
    amount,
    unlockAt,
    price,
  }: Pick<
    TExtendDelegate,
    "id" | "address" | "amount" | "unlockAt" | "price"
  >) => {
    const index = [...transactions].findIndex((item) => {
      return item.id === id;
    });
    if (index === -1) {
      const transaction: TTransactionType = {
        id,
        address: address,
        energy: amount,
        energyExtended: ENERGY_EXTEND_DEFAULT,
        unlockAt,
        duration: modeGlobal.includes("sameDuration")
          ? durations
          : DURATION_EXTEND_DEFAULT,
        mode: "extendOnly",
        newPrice: null,
        payout: null,
        isExtend: true,
        isBlockMode: false,
        infoTransaction: [],
        extraBuyFaildureReason: [],
        extendFaildureReason: [],
      };
      onAddTransaction(transaction);
    } else {
      onDeleteTransaction(id);
    }
  };
  const idTransactions: string[] = useMemo(() => {
    if (transactions.length) {
      return [...transactions].reduce((arr: string[], item) => {
        return [...arr, item.id];
      }, []);
    }
    return [];
  }, [transactions]);

  // get data when transaction change
  const { dataFilter, delegatesEnabled } = useMemo(() => {
    const delegatesDisabled = [...delegates].filter((item) => !!item.disabled);
    const delegatesEnabled = [...delegates].filter((item) => !item.disabled);
    let newDelegate: TExtendDelegate[] = [];
    if (delegatesEnabled.length >= 1) {
      switch (sort.field) {
        case "amount": {
          newDelegate = [...delegatesEnabled].sort((a, b) =>
            sort.type === "asc"
              ? a.resourceAmount - b.resourceAmount
              : b.resourceAmount - a.resourceAmount
          );
          break;
        }
        case "exprire": {
          newDelegate = [...delegatesEnabled].sort((a, b) =>
            sort.type === "asc"
              ? a.unlockAt - b.unlockAt
              : b.unlockAt - a.unlockAt
          );
          break;
        }
        case "price": {
          newDelegate = [...delegatesEnabled].sort((a, b) =>
            sort.type === "asc"
              ? a.newPrice - b.newPrice
              : b.newPrice - a.newPrice
          );
          break;
        }
        default:
          newDelegate = [...delegatesEnabled];
          break;
      }
    }

    return {
      dataFilter: [...newDelegate, ...delegatesDisabled],
      delegatesEnabled: [...newDelegate],
    };
  }, [sort.field, sort.type, delegates.length]);
  // console.log({ delegatesEnabled });
  const isCheckAll =
    transactions.length > 0 && transactions.length === delegatesEnabled.length;
  const onCheckAllTransaction = () => {
    if (!!isCheckAll) {
      onResetTransactions();
    } else {
      let newArr: TExtendDelegate[] = [...delegatesEnabled];
      [...delegatesEnabled].forEach((itemDelegate) => {
        [...transactions].forEach((i) => {
          if (itemDelegate.id === i.id) {
            newArr = [...newArr].filter((item) => item.id !== i.id);
          }
        });
      }, []);
      if (newArr.length > 0) {
        const dataAdd = newArr.map((item) => {
          const { id, address, amount, unlockAt } = item;
          const transactionItem: TTransactionType = {
            id,
            address: address,
            energy: amount,
            energyExtended: ENERGY_EXTEND_DEFAULT,
            unlockAt,
            duration: modeGlobal.includes("sameDuration")
              ? durations
              : DURATION_EXTEND_DEFAULT,
            mode: "extendOnly",
            newPrice: null,
            payout: null,
            isExtend: true,
            isBlockMode: false,
            infoTransaction: [],
            extraBuyFaildureReason: [],
            extendFaildureReason: [],
          };
          return transactionItem;
        });
        onAddTransactionAll([...dataAdd, ...transactions]);
      }
    }
  };
  return (
    <Wrap
      className={`scrollbar2 ${
        loading || delegates.length === 0 ? "show" : ""
      }`}
    >
      <OptionsSort
        isChecked={isCheckAll}
        onChangeOption={onCheckAllTransaction}
      />
      <table className="tec-table">
        <thead className="tec-thead">
          <tr>
            {theadData.map(
              (
                { id, title, isShort, onHandleSort, idSort }: TElThead,
                index
              ) => (
                <td key={index}>
                  <div className="tec-thead-item">
                    <p className="size-1 color-white">{t(title)}</p>
                    {isShort && (
                      <AiOutlineCaretDown
                        className={`color-white cursor-pointer ${
                          idSort === sort.field ? "color-green" : ""
                        } ${
                          idSort === sort.field && sort.type === "desc"
                            ? "rotate-icon"
                            : ""
                        }`}
                        onClick={onHandleSort}
                      />
                    )}
                    {id === "selection" && (
                      <SelectionCheck className="tec-selection">
                        {/* <p className="size-1 color-white">
                          <span className="color-green"> |</span> {t("all")}
                        </p> */}
                        <div
                          onClick={onCheckAllTransaction}
                          className={`tec-check ${
                            isCheckAll ? "tec-check--checked" : ""
                          }`}
                        >
                          {isCheckAll && (
                            <span className="tec-check__icon">
                              <BsCheckLg color="#fff" />
                            </span>
                          )}
                        </div>
                      </SelectionCheck>
                    )}
                  </div>
                </td>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {(loading || dataFilter.length === 0) && (
            <div className="tsc-status">
              {loading && (
                <div className="tsc-status__item">
                  <LoadingSpinner />
                </div>
              )}
              {!loading && dataFilter.length === 0 && (
                <div className="tsc-status__item">
                  <NoData subText="null" size="tiny" />
                </div>
              )}
            </div>
          )}
          {dataFilter.length > 0 &&
            dataFilter.map((item, index) => (
              <TableExtendItem
                key={item.id + index}
                {...item}
                onCheckChange={() => {
                  if (!item.disabled) {
                    onCheckChange({ ...item });
                  }
                }}
                isChecked={idTransactions.includes(item.id)}
                onChangeDelegatePrice={onChangeDelegatePrice}
              />
            ))}
        </tbody>
      </table>
    </Wrap>
  );
};

export default TableExtend;
const SelectionCheck = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;
  .tec-check {
    width: 20px;
    height: 20px;
    border: 1px solid var(--grey-400);
    cursor: pointer;
    position: relative;
    border-radius: 2px;
    &--checked {
      border: 1px solid var(--primary-green-400);
      background-color: var(--primary-green-400);
    }
    &__icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      svg {
        display: inline-flex;
      }
    }
  }
`;
const Wrap = styled.div`
  max-height: 500px;
  overflow: auto;
  display: block;
  padding-right: 16px;
  ${breakpointsMedias.max1024} {
    padding-right: 6px;
  }
  table.tec-table {
    display: table;
    width: 100%;
    thead {
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: #222722;
      &.tec-thead {
        color: white;
        .tec-thead-item {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }
      }
      tr {
        td {
          &:last-child {
            .tec-thead-item {
              justify-content: flex-end;
            }
          }
          &:first-child {
            .tec-thead-item {
              justify-content: flex-start;
            }
          }
        }
      }
    }
    tr {
      border-bottom: 1px solid var(--grey-700);
      /* border-bottom: 1px solid #383838; */
      &:last-child {
        border-bottom: 0;
      }
      td {
        padding: 10px 15px;
        &:nth-child(2),
        &:nth-child(4) {
          /* text-align: right;
          display: flex;
          justify-content: flex-end; */
        }
      }
    }
  }
  &.show {
    table.tec-table {
      tbody {
        position: relative;
        height: 200px;
        .tsc-status {
          display: flex;
          justify-content: center;
          width: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  ${breakpointsMedias.max1024} {
    table.tec-table {
      tr {
        td {
          min-width: 100px;
        }
      }
    }
  }
  ${breakpointsMedias.max667} {
    table.tec-table {
      thead {
        display: none;
      }
      tr {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px 7px;
        &:is(.hidden):last-child {
          margin-bottom: 0 !important;
        }
        td {
          min-width: 100px;
          padding: 0;
        }
      }
    }
    &.show {
      table.tec-table {
        tbody {
          height: 200px;
          .tsc-status {
            top: 50px;
          }
        }
      }
    }
  }
`;
