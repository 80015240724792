import React, { useContext, useEffect, useState } from "react";
import { i18n } from "../../../languages";
import { useTranslation } from "react-i18next";
import { errorCode } from "../../../helpers/errorCode";
const LINK_RESTFUL_API = process.env.REACT_APP_RESTFUL_API || "";

export const TeleContext = React.createContext({
  apiKey: "",
  requester: "",
  balance: 0,
  getApiKey: (init_data: string) => {},
});
const TeleContextWrap = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const [infoAccount, setInfoAccount] = useState({
    apiKey: "",
    requester: "",
    balance: 0,
  });
  const getApiKey = async (init_data: string) => {
    if (init_data) {
      await fetch(`${LINK_RESTFUL_API}v0/user-telegram-info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ init_data }),
      })
        .then(async (res: any) => {
          if (res.status === 200) {
            const result = await res.json();
            setInfoAccount({
              apiKey: result.api_key,
              requester: result.requester,
              balance: result.balance,
            });
            i18n.changeLanguage(result.language_code || "en");
          } else {
            const err = await res.json();
            throw t(errorCode[err.message] || "");
          }
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  };
  const { apiKey, requester, balance } = infoAccount;
  const waitTele = () => {
    return new Promise((res) => {
      let attempts = 0,
        maxAttempts = 20;
      const checkTele = () => {
        if ((window as any).Telegram?.WebApp?.initData) {
          (window as any).Telegram?.WebApp?.ready();
          res(true);
          return;
        }
        attempts++;
        if (attempts >= maxAttempts) {
          res(false);
          return;
        }
        setTimeout(checkTele, 100);
      };
      checkTele();
    });
  };
  const initTele = async () => {
    let teledata = await waitTele();
    if (teledata) {
      (window as any).Telegram?.WebApp?.expand();
      getApiKey((window as any).Telegram?.WebApp?.initData);
    }
  };

  useEffect(() => {
    initTele();
  }, []);
  console.log({ apiKey, requester, balance });
  return (
    <TeleContext.Provider value={{ apiKey, requester, balance, getApiKey }}>
      {children}
    </TeleContext.Provider>
  );
};

export default TeleContextWrap;
export const useTeleContext = () => useContext(TeleContext);
