import { memo } from 'react'
import styled, { css } from 'styled-components'

type LoadingProps = {
    relative?: boolean,
    size?: "normal" | "small" | "tiny",
    color?: "primary" | "white"
    centering?: boolean
}
const Loading = ({ relative, size = "normal", color = "primary", centering }: LoadingProps) => {
    if (relative) {
        return (
            <LoadingElement centering
                className={`relative ${size} loading-element`}
                role={color}
            />
        )
    }
    return (
        <LoadingElement className={`${size} loading-element`} />
    )
}

export default memo(Loading)
type LoadingElementProps = {
    centering: boolean;
}
const LoadingElement = memo(styled.div`
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    width:40px;
    height:40px;
    border:4px solid transparent;
    border-left-color:#fff;
    border-right-color:#fff;
    border-top-color:transparent;
    border-radius:999px;
    &.relative{
        position:relative;
        margin-right: auto;
        margin-left: auto;
    }
    &.small{
        width:30px;
        height:30px;
        border-width:2px;
    }
    &.tiny{
        width:20px;
        height:20px;
        border-width:3px;
        &[role="white"]{
            border-left-color:#fff;
            border-right-color:#fff;
            border-top-color:#fff;
        }
    }
    ${({ centering }: LoadingElementProps) => centering ? css`animation: rotate-center .7s linear infinite both;` :
        css`animation: rotate-center-1 .7s linear infinite both;`}
    @keyframes rotate-center {
        0% {transform: rotate(0);}
        100% {transform: rotate(360deg);}
    }
    @keyframes rotate-center-1 {
        0% {transform: translate(-50%,-50%) rotate(0);}
        100% {transform: translate(-50%,-50%) rotate(360deg);}
    }
`)