import React, { ReactNode, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { GET_REF_INFOR, SET_PENDING_REF } from "configs/apolloSchema";
import { notifyToastify } from "helpers/ultilities/notifyToastify";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";

interface IProps {
    children: ReactNode;
}

const RefContext = React.createContext({
    sponsor: null as null | string,
    pending_ref: null as null | string,
    ref_code: null as null | string,
    success: false,
    onChangeRefData: (data: RefData) => { }
})

type RefData = {
    sponsor: string | null,
    pending_ref: string | null,
    ref_code: string | null,
    success: boolean
}

const RefContainer = ({ children }: IProps) => {
    const client = useApolloClient();
    const { address, connected } = useWallet();
    const [refData, setRefData] = useState<RefData>({
        sponsor: null,
        pending_ref: null,
        ref_code: null,
        success: false
    })
    const [searchParams] = useSearchParams();
    const refCodeFromLink = searchParams.get("ref")
    // AdminKey
    const adminKey = searchParams.get("adminKey");

    useEffect(() => {
        if (adminKey) {
            localStorage.setItem("adminKey", adminKey)
        }
    }, [adminKey]);

    const onChangeRefData = (data: RefData) => {
        setRefData(data)
    }

    const getDataSponsor = async () => {
        if (!address) {
            return;
        }
        try {
            const query = client.watchQuery({
                query: GET_REF_INFOR,
                variables: {
                    address: address
                },
                fetchPolicy: "no-cache"
            })
            const querySubscribe = query.subscribe(({ data: { rls_user_ref_info } }) => {
                if (rls_user_ref_info) {
                    setRefData({
                        sponsor: rls_user_ref_info.sponsor,
                        pending_ref: rls_user_ref_info.pending_ref_code,
                        ref_code: rls_user_ref_info.user_ref_code,
                        success: true
                    })
                    if (rls_user_ref_info.sponsor) {
                        localStorage.setItem(`ref-${address}`, rls_user_ref_info.sponsor);
                    } else {
                        localStorage.removeItem(`ref-${address}`);
                    }
                    // console.log("ssadasd", dex_user_info, ref)
                }
            }, (error) => {
                console.log("Get ref infor error", error);
            })
            return querySubscribe
        } catch (error) {
            console.log("Get ref infor err", error);
        }
    }

    const setRef = async (val: string) => {
        if (address && connected) {
            let loadSponsor = localStorage.getItem(`ref-${address}`);
            if (!refData.sponsor && !loadSponsor) {
                try {
                    const query = client.mutate({
                        mutation: SET_PENDING_REF,
                        variables: {
                            ref_code: val,
                            address: address
                        },
                        fetchPolicy: "no-cache"
                    }).then(({ data: { rls_user_set_pending_ref } }) => {
                        if (rls_user_set_pending_ref.alert) {
                            notifyToastify({ type: "error", text: rls_user_set_pending_ref.alert });
                            localStorage.removeItem("ref-link");
                        }
                        if (rls_user_set_pending_ref.sponsor || rls_user_set_pending_ref.pending_ref) {
                            getDataSponsor();
                            // if (rls_user_set_pending_ref.sponsor) {
                            //     notifyToastify({ type: "success", text: t("setSponsorSuccessfully") })
                            // }
                            // if (rls_user_set_pending_ref.pending_ref === val) {
                            //     notifyToastify({ type: "success", text: t("setPendingRefSuccessfully") })
                            // }
                        }
                    }, (error) => {
                        localStorage.removeItem("ref-link");
                        console.log("Set ref  error", error);
                    })
                } catch (error) {
                    localStorage.removeItem("ref-link");
                    console.log("Set ref err", error);
                }
            }
        }
    }

    useEffect(() => {
        if (refCodeFromLink) {
            localStorage.setItem("ref-link", refCodeFromLink)
        }
        if (connected && address) {
            getDataSponsor();
            // beforeSetRef()
            if (refCodeFromLink) {
                setRef(refCodeFromLink)
            } else {
                let getSavedRef = localStorage.getItem("ref-link");
                if (getSavedRef) {
                    setRef(getSavedRef)
                }
            }
        }
    }, [connected, address, refCodeFromLink]);

    return (
        <RefContext.Provider
            value={{
                ...refData,
                onChangeRefData
            }}
        >{children}</RefContext.Provider>
    );
};
export default RefContainer
export { RefContext };
