import { useQuery } from "@apollo/client";
import LoadingSpinner from "components/core/LoadingSpinner";
import OrderBookItem, {
  TOrderBookItem,
} from "components/ui/energy/OrderBookItem";
import { QUERY_ORDER_BOOKS } from "configs/apolloSchema";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
type OrderBookProps = {
  value: string;
  targetAddress: string;
  minDelegate: number | null,
  durationSec: number
};
const OrderBook = ({ value, targetAddress, minDelegate, durationSec }: OrderBookProps) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(QUERY_ORDER_BOOKS, {
    variables: {
      address: targetAddress,
      min_delegate_amount: minDelegate || 0,
      duration_sec: durationSec
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-and-network",
  });
  const orders: TOrderBookItem[] = useMemo(() => {
    if (data?.rls_order_book) {
      let arrSum = [] as number[];
      return data?.rls_order_book.map((item: any) => {
        const { min: price, value: energy } = item;
        arrSum = [...arrSum, energy];
        const sum = arrSum.reduce((acc: number, itemSum: any) => {
          return acc + itemSum;
        }, 0);
        return {
          price,
          energy: sum,
        };
      });
    }

    return [];
  }, [data, value]);
  const findLastIndex = (array: any[], comparitor: any) => {
    for (let i = array.length - 1; i >= 0; i--)
      if (comparitor(array[i])) return i;

    return -1;
  };

  const itemActive = findLastIndex(
    [...orders],
    (item: TOrderBookItem) => +item.price <= +value
  );
  const getStatus = (index: number): "active" | "prev" | "next" => {
    switch (true) {
      case itemActive === -1: {
        return "prev";
      }
      case itemActive === index:
        return "active";
      case itemActive > index: {
        return "prev";
      }
      case itemActive < index: {
        return "next";
      }
      default:
        return "prev";
    }
  };
  return (
    <Table>
      <thead>
        <tr>
          <td>
            <p className="color-white size-0">{t("price")}(SUN)</p>
          </td>
          <td>
            <p className="color-white size-0">{t("energyAvailable")}</p>
          </td>
        </tr>
      </thead>
      <tbody className="scrollbar">
        {loading ? (
          <tr>
            {/* <td className="size-11 color-white text-center">Loading...</td> */}
            <td className="size-11 color-white text-center">
              <div className="obc-loading">
                <LoadingSpinner />
              </div>
            </td>
          </tr>
        ) : (
          orders &&
          [...orders].map(({ price, energy }: TOrderBookItem, index) => (
            <OrderBookItem
              key={index}
              status={getStatus(index)}
              price={
                price === -1
                  ? "<" + (orders?.[index + 1]?.price || price)
                  : price
              }
              energy={energy}
            />
          ))
        )}
      </tbody>
    </Table>
  );
};

export default OrderBook;
const Table = styled.table`
  width: 100%;
  tr {
    td {
      padding: 2px 0;
    }
  }
  thead {
    display: block;
    tr {
      display: grid;
      grid-template-columns: 1fr 1fr;

      td {
        padding: 7px 0;
      }
    }
  }
  tbody {
    overflow: auto;
    display: block;
    max-height: 200px;
    overflow-y: scroll;
    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      :nth-child(even) {
        /* opacity: 0.5; */
      }
      .obc-loading {
        display: flex;
        justify-content: center;
      }
    }
  }
`;
